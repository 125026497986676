<template>
    <DataTable 
        responsiveLayout="scroll"
        scrollDirection="both"
        :scrollable="true"
        :value="data"
        v-model:selection="pedidoSelecionado"
        selectionMode="single"
        showGridlines
        @sort="sort($event)">
        
        <template #header>
            <div class="flex align-items-center" v-if="tela=='pedido'">
                <Button
                    class="p-button-info mr-2"
                    icon="pi pi-plus"
                    aria-label="Incluir"
                    label="Incluir"
                    @click="incluirPedido"
                    :disabled="bloqueiaInclusao"
                ></Button>

                <Button
                    v-if="filter.situacao == 'ATIVO'"
                    type="button"
                    icon="pi pi-ban"
                    label="Listar inativos"
                    class="p-button-outlined p-button-danger mr-2"
                    @click="filter.situacao = 'INATIVO'; carregarDados()"
                ></Button>

                <Button
                    v-if="filter.situacao == 'INATIVO'"
                    type="button"
                    icon="pi pi-check-circle"
                    label="Listar ativos"
                    class="p-button-outlined mr-2"
                    @click="filter.situacao = 'ATIVO'; carregarDados()"
                ></Button>

            </div>

        </template>

        <template #empty>
            Nenhum pedido encontrado.
        </template>
        <Column field="id" header="ID" style="width:5rem;" frozen sortable/>
        <Column field="codEstabelecimentoOrigem" style="width:6rem; font-weight: bold;" header="Origem" frozen sortable/>
        <Column header="Cliente" style="width:5rem;" frozen>
            <template #body="{ data }">
                {{ data.cliente.codigo }}
            </template>
        </Column>
        <Column header="Nome Cliente" style="width:18rem;">
            <template #body="{ data }">
                {{ data.cliente.nome }}
            </template>
        </Column>
        <Column header="CNPJ Cliente" style="width:11rem;">
            <template #body="{ data }">
                {{ data.cliente.cnpj }}
            </template>
        </Column>
        <Column field="autorizadoPor" header="Autorizado Por" style="width:15rem;"/>
        <Column header="Inicio" style="width:6rem;" sortable>
            <template #body="{ data }">
                {{ formatDateCustom(data.dataAutotizacao, 'DD/MM/YYYY') }}
            </template>
        </Column>
        <Column field="codigoEntrega" header="Cód. Entrega" style="width:7rem;" sortable/>
        <Column field="nomeAbrevRemessa" header="Nome Abrev. Remessa" style="width:9rem;"/>
        <Column field="codigoEntregaRemessa" header="Cód. Entr. Remessa" style="width:8rem;"/>
        <Column field="codNaturezaOperacao" header="Natureza Operação" style="width:7rem;"/>
        <Column field="descCondicaoPagto" header="Condição Pagto" style="width:10rem;"/>
        <Column field="nroPedidoCompraCliente" header="Nº Ped. Compra Cliente" style="width:8rem;"/>
        <Column field="parcela" header="Parcela" style="width:4rem;"/>
        <Column field="destinoMercadoria" header="Destino" style="width:11rem;">
            <template #body="{ data }">
                {{ data.destinoMercadoria?.descricao }}
            </template>
        </Column>
      <Column field="modalidadePrecificacao" header="Modalidade Precificação" style="width:10rem;"/>
      <Column field="precoUnitarioCcv" header="Preço Unitário CCV" style="width:8rem;">
        <template #body="{ data }">
          {{ formatDecimal(data.precoUnitarioCcv, 2, 6) }}
        </template>
      </Column>

      <Column field="valorFixado" header="Qt. Fixada" style="width:8rem;" v-if = "gestao.modalidadePrecificacao!='FIXO'">
        <template #body="{ data }">
          {{ formatDecimal(data.qtdFixada, 2, 6) }} {{gestao.siglaUnidadeMedida}}
        </template>
      </Column>
      <Column field="valorAFixar" header="Qt. à Fixar" style="width:8rem;" v-if = "gestao.modalidadePrecificacao!='FIXO'">
        <template #body="{ data }">
          {{ formatDecimal(data.qtdAFixar, 2, 6) }} {{gestao.siglaUnidadeMedida}}
        </template>
      </Column>

      <Column field="saldoUtilizado" header="Pedidos Gerados" style="width:9rem; font-weight: bold; background-color: rgb(229, 241, 247)" frozen alignFrozen="right">
            <template #body="{ data }">
                {{ formatDecimal(data.saldoUtilizado, 2, 2) }} {{gestao.siglaUnidadeMedida}}
            </template>
        </Column>
        <Column field="totalFaturado" header="Embarcado" style="width:9rem; background-color: rgb(229, 241, 247)" frozen alignFrozen="right">
            <template #body="{ data }">
                {{ formatDecimal(data.totalFaturado, 2, 2) }} {{gestao.siglaUnidadeMedida}}
            </template>
        </Column>
        <Column field="saldoFaturamento" header="Saldo à embarcar" style="width:9rem; background-color: rgb(229, 241, 247);" frozen alignFrozen="right">
          <template #body="{ data }">
            {{ formatDecimal(data.saldoFaturamento, 2, 2) }} {{gestao.siglaUnidadeMedida}}
          </template>
        </Column>

        <Column v-if="tela=='pedido'" header="Ações" style="width:14rem" class="centralizar-titulo-tabela" frozen alignFrozen="right">
            <template #body="{ data, index }">
                <div class="flex align-items-center justify-content-center">


                    <Button 
                        v-if="false"
                        class="p-button-rounded p-button-success flex-none mr-2" 
                        title="faturar"  
                        icon="pi pi-file"
                        :disabled="data.qtdFixada==0 && gestao.modalidadePrecificacao=='A FIXAR - ANTECIPADA'
                        || (data.pedidoBloqueado && isLogistica && data.bloqueioCCV)"
                        @click="faturar(data, index)"/>
                    <Button 
                        v-if="data.ativo"
                        class="p-button-rounded p-button-gray flex-none mr-2" 
                        title="Clonar Pedido"
                        icon="pi pi-copy"
                        :disabled="data.pedidoBloqueado && isLogistica && data.bloqueioCCV"
                        @click="clonarPedido(data)"/>

                    <Button 
                        class="p-button-rounded p-button-warning flex-none mr-2" 
                        title="Visualizar Histórico"  
                        icon="pi pi-list" 
                        v-if="data.id"
                        @click="visualizarHistorico(data)"/>

                    <Button
                        v-if="data.ativo"
                        class="p-button-rounded p-button-info flex-none mr-2"
                        icon="pi pi-pencil"
                        aria-label="Novo"
                        title="Editar"
                        :disabled="data.pedidoBloqueado && isLogistica && data.bloqueioCCV"
                        @click="editarPedido(data.id)"
                    ></Button>

                    <Button 
                        v-if="data.ativo"
                        title="Inativar"
                        class="p-button-rounded flex-none p-button-danger"
                        icon="pi pi-ban"
                        :disabled="data.pedidoBloqueado && isLogistica && data.bloqueioCCV"
                        @click="confirmarAtivarOuInativar(data)"
                    ></Button>

                    <Button 
                        v-if="!data.ativo"
                        title="Ativar"
                        class="p-button-rounded flex-none p-button-info"
                        icon="pi pi-check-circle"
                        :disabled="data.pedidoBloqueado && isLogistica && data.bloqueioCCV"
                        @click="confirmarAtivarOuInativar(data)"
                    ></Button>
                </div>
            </template>
        </Column>

      <Column v-if="tela=='embarque'" header="Ações" style="width:4rem" class="centralizar-titulo-tabela" frozen alignFrozen="right">
        <template #body="{ data }">
          <div class="flex align-items-center justify-content-center">
            <Button
                v-if="data.ativo"
                class="p-button-rounded p-button-success flex-none mr-2"
                title="Solicitar embarque"
                icon="pi pi-file"
                :disabled="data.saldoEmbarque==0"
                @click="incluirSolicitacaoEmbarque(data)"/>
          </div>
        </template>
      </Column>

    </DataTable>

    <Dialog v-model:visible="ativarOuInativarPedidoDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>Tem certeza que deseja <strong>{{ pedidoSelecionado.ativo ? 'inativar' : 'ativar' }}</strong> o pedido: <strong>{{ pedidoSelecionado.id }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarPedidoDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
        </template>
    </Dialog>

    <Paginator
        v-model:first="primeiraLinha"
        :rows="qtdRegistroPorPagina"
        :total-records="totalRegistro"
        :rowsPerPageOptions="[5, 10, 20, 30]"
        @page="onPage" />

    <ModalFaturamentoForm
        :siglaUnidadeMedida="'KG'"
        :visivel="faturamentoDialog"
        :pedido="pedido"
        :modalidadePrecificacao="faturamento.modalidadePrecificacao"
        :precoUnitarioCcv="faturamento.precoUnitarioCcv"
        :qtdFixada="faturamento.qtdFixada"
        :faturamento="faturamento"
        :contratoId="contratoId"
        :saldoPedido="saldoFaturamentoModal"
        :isEdicao="isEdicaoFaturamento"
        @fechar="faturamentoFechar"
    />

    <ModalPedidoForm
        :pedidoId="pedidoId"
        :gestaoPF="gestao"
        :contratoId="contratoId"
        :visivel="pedidoDialog"
        :acao="acao"
        :totalPedidos="totalPedidos"
        :codEstabelecimentosOrigem="gestao.codEstabelecimentosOrigem"
        @fechar="pedidoFechar"
    />

    <ModalHistoricoGenerico
        codigoPagina="PEDIDO_VG"
        :id="pedidoSelecionado.id"
        :descricao="descricaoHistoricoPedidoDialog"
        :visivel="historicoPedidoDialog"
        @fechar="ocultarHistorico()"
    />

  <ModalNovaSolicitacaoEmbarque
      :visivel="solicitacaoEmbarqueDialog"
      :dados="solicitacaoEmbarque"
      :gestao="gestao"
      :saldoEmbarque="saldoEmbarqueModal"
      :isEdicao="isEdicaoSolicitacaoEmbarque"
      :siglaUnidadeMedida="gestao.siglaUnidadeMedida"
      @fechar="solicitacaoEmbarqueFechar"
      @salvar="solicitacaoEmbarqueSalvar"
  />

</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import ModalPedidoForm from './ModalPedidoForm.vue';
import ModalFaturamentoForm from './ModalFaturamentoForm.vue';
import ModalHistoricoGenerico from '../../ModalHistoricoGenerico.vue';
import UnidadeMedidaService from '../contrato/service/UnidadeMedidaService.js';
import CondicaoPagamentoService from '../../../service/CondicaoPagamentoService';
import PedidoService from '../../../service/pedido/PedidoService';
import ModalNovaSolicitacaoEmbarque from "@/components/cadastro/embarque/ModalNovaSolicitacaoEmbarque.vue";
import EmbarqueTabelaOrigem from "@/components/cadastro/embarque/EmbarqueTabelaOrigem.vue";
import GestaoFaturamentoPedidoService from "@/service/pedido/GestaoFaturamentoPedidoService";

export default {
    components: {
      EmbarqueTabelaOrigem,
      ModalNovaSolicitacaoEmbarque,
      ModalPedidoForm,
      ModalFaturamentoForm,
      ModalHistoricoGenerico
    },
    emits: ["atualizarGestao"],
    props: {
        gestao: {
            type: Object,
            required: true
        },

        embarque: {
          type: Object,
          required: false
        },

        tela: {
            type: String,
            required: true
        },

        refreshPedidoCount: {
            type: Number,
            default: 0
        }
    },

    watch: {
        refreshPedidoCount:function() {
            if(this.tela=='pedido') {
              this.pedidoSelecionado = {};
              this.carregarDados();
            }
        }
    },
    
    data() {
        return {
            faturamentoDialog: false,
            isEdicaoFaturamento: false,
            pedidoDialog: false,
            manutencaoValorFreteDialog: false,
            pedidoSelecionado: {},
            faturamento: {},
            pedido: {},
            data: [],
            faturamentos: [],
            saldoPedidoModal: 0.0,
            saldoFaturamentoModal: 0.0,
            totalizadorQuantidade: 0,
            totalizadorCotas: 0,
            expandedRows: [],
            filter: {},

            tela: this.tela,
            solicitacaoEmbarqueDialogHistorico: false,
            isEdicaoSolicitacaoEmbarque: false,
            solicitacaoEmbarqueDialog: false,
            solicitacaoEmbarqueTransportadora: {},
            solicitacaoEmbarque: {},
            saldoEmbarqueModal: 0,

            historicoPedidoDialog: false,
            ativarOuInativarPedidoDialog: false,
            descricaoHistoricoPedidoDialog: null,

            pagina: 0,
            qtdRegistroPorPagina: 10,
            ordenar: 'id,desc',
            totalRegistros: 0,
            pedidoId: null,
            contratoId: null,
            acao: "",
            isAdministracao: false,
            isLogistica: false,
            isOperacao: false,
            isComercial: false,
            totalPedidos: 0,
            bloqueiaInclusao: false

        }
    },

    created(){
        this.isAdministracao = this.$store.state.auth.user.isAdministracao;
        this.isLogistica = this.$store.state.auth.user.isLogistica;
        this.isOperacao = this.$store.state.auth.user.isOperacao;
        this.isComercial = this.$store.state.auth.user.isComercial;

        this.carregarDados();
        this.filter["situacao"] = "ATIVO";

    },

  computed: {
      currentUser() {
        return this.$store.state.auth.user;
      }
    },

    methods: {

        carregarDados(){
            this.filter["page"] = this.pagina;
            this.filter["size"] = this.qtdRegistroPorPagina;
            this.filter["sort"] = this.ordenar;
            this.filter["idGestaoFaturamentoPedido"] = this.gestao.id;

            PedidoService.buscarComPaginacao(this.filter)
                .then(response => {
                  this.data = response.data.content;
                    this.gestao["pedido"] = this.data;
                    this.totalRegistros = response.data.totalElements;
                    this.carregarSaldo();
                })
                .catch(error => {
                    this.$message({
                        severity: 'error',
                        summary: 'Erro',
                        detail: error.response.data.message
                    });
                });
            this.contratoId = this.gestao.contratoCompraVendaId;

        },

      carregarSaldo() {
        GestaoFaturamentoPedidoService.buscarSaldoPedidoGestao(this.gestao.id)
            .then(({ data }) => {
              this.totalPedidos = data;
              this.bloqueiaInclusao = this.gestao.saldoPedido == 0 || (this.gestao.saldoLiberadoBloqueio <= this.totalPedidos
                  && this.isLogistica && this.gestao.bloqueioCCV)
            });

      },

        incluirPedido(){
            this.acao = "Novo"
            this.pedidoId = null;
            this.pedidoDialog = true;
        },

        editarPedido(pedidoId) {
            this.acao = "Edição";
            this.pedidoId = pedidoId;
            this.pedidoDialog = true;
        },

        clonarPedido(data){
            this.acao = "Clonar";
            this.pedidoId = data.id;
            this.pedidoDialog = true;
        },

        preencherFaturamento(data) {
            const faturamento = {};
            faturamento["id"] = null;
            faturamento["versao"] = 0;
            faturamento["pedidoId"] = data.id;
            faturamento["grupoProdutoId"] = this.gestao.grupoProdutoId;
            faturamento["grupoProdutoCodigo"] = this.gestao.grupoProdutoCodigo;
            faturamento["codEstabelecimentoOrigem"] = data.codEstabelecimentoOrigem;
            faturamento["itCodigo"] = null;
            faturamento["nrDocPes"] = null;
            faturamento["dataNrDocPes"] = new Date();
            faturamento["placa"] = null;
            faturamento["motorista"] = null;
            faturamento["quantidade"] = null;
            faturamento["codNaturezaOperacao"] = data.codNaturezaOperacao;
            faturamento["destinoMercadoria"] = data.destinoMercadoria;
            faturamento["descNaturezaOperacao"] = data.descNaturezaOperacao;
            faturamento["condicaoPagamento"] = CondicaoPagamentoService.buscarMockPorCodigo(data.codCondicaoPagto);
            faturamento["codCondicaoPagto"] = data.codCondicaoPagto;
            faturamento["descCondicaoPagto"] = data.descCondicaoPagto;
            faturamento["nroPedidoCompraCliente"] = data.nroPedidoCompraCliente;
            faturamento["codigoEntrega"] = data.codigoEntrega;
            faturamento["codigoEntregaRemessa"] = data.codigoEntregaRemessa;
            faturamento["nomeAbrevRemessa"] = data.nomeAbrevRemessa;
            faturamento["rco"] = data.rco;
            faturamento["parcela"] = data.parcela;
            faturamento["saldoUtilizado"] = data.saldoUtilizado;
            faturamento["versao"] = 0;
            faturamento["modalidadePrecificacao"] = data.modalidadePrecificacao;
            faturamento["precoUnitarioCcv"] = data.precoUnitarioCcv;
            faturamento["nrNotaFiscal"] = data.nrNotaFiscal;
            faturamento["serieNotaFiscal"] = data.serieNotaFiscal;
            let uni = UnidadeMedidaService.buscarPorCodigo(this.gestao.unidade);

            faturamento["qtdFixada"] = (data.qtdFixada * uni.fatorConversao).toFixed(2);

            const cliente = {};
            cliente["id"] = data.cliente.id;
            cliente["codProdutor"] = data.cliente.codigo;
            cliente["nome"] = data.cliente.nome;
            cliente["cpfCnpj"] = data.cliente.cnpj;  
            cliente["inscricaoEstadual"] = data.cliente.inscricaoEstadual;  
            cliente["endereco"] = data.cliente.endereco;  
            cliente["numeroEndereco"] = data.cliente.numeroEndereco;  
            cliente["bairro"] = data.cliente.bairro;  
            cliente["cidade"] = data.cliente.cidade;  
            cliente["unidadeFederativa"] = data.cliente.unidadeFederativa;   
            cliente["cep"] = data.cliente.cep;
            cliente["representante"] = data.cliente.representante;
            faturamento["cliente"] = cliente;

            const naturezaOperacao = {};
            naturezaOperacao["codigo"] = data.codNaturezaOperacao;
            naturezaOperacao["descricao"] = data.descNaturezaOperacao;
            naturezaOperacao["label"] = data.codNaturezaOperacao + " - " + data.descNaturezaOperacao;
            faturamento["naturezaOperacao"] = naturezaOperacao;

            const unidade = UnidadeMedidaService.buscarPorCodigo(this.gestao.unidade);
            faturamento["saldoFaturamentoPedidoEmKG"] = (data.saldoFaturamento * unidade.fatorConversao).toFixed(2);

            return faturamento;
        },

        confirmarAtivarOuInativar(pedido) {
            this.pedidoSelecionado = pedido;

            if(this.pedidoSelecionado.ativo && pedido.totalFaturado > 0) {
                this.$toast.add({
                    severity:'warn',
                    summary: 'Atenção!',
                    detail:`O pedido ${pedido.id} não pode ser inativado, pois o mesmo já possui faturamento.`, 
                    life: 7500
                });
                return;
            }

            this.ativarOuInativarPedidoDialog = true;
        },

        ativarOuInativar() {
            if(this.pedidoSelecionado.ativo) {
                PedidoService.inativar(this.pedidoSelecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Inativado',
                            detail:`O pedido foi inativado com sucesso.`, 
                            life: 7500
                        });

                        this.carregarDados();
                        this.$emit("atualizarGestao");
                        this.ativarOuInativarPedidoDialog = false;
                        this.pedidoSelecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao inativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                PedidoService.ativar(this.pedidoSelecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Ativada',
                            detail:`O pedido foi ativado com sucesso.`, 
                            life: 7500
                        });
                        
                        this.carregarDados();
                        this.$emit("atualizarGestao");
                        this.ativarOuInativarPedidoDialog = false;
                        this.pedidoSelecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao ativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }

            this.ativarOuInativarPedidoDialog = false;            
        },

        pedidoFechar() {
            this.pedidoDialog = false;
            this.pedido = {};

            this.$emit("atualizarGestao");
            this.carregarDados();
        },

        faturamentoFechar() {
            this.faturamentoDialog = false;
            this.faturamento = {};

            this.$emit("atualizarGestao");
            this.carregarDados();
        },

        solicitacaoEmbarqueFechar() {
          this.solicitacaoEmbarqueDialog = false;
          this.solicitacaoEmbarque = {};
        },

        solicitacaoEmbarqueSalvar(solicitacaoEmbarque) {
          const indice = solicitacaoEmbarque.indice;
          const dataCadastro = Formatacao.formatDateCustom(new Date(), 'YYYY-MM-DD HH:mm:ss');
          const user = this.currentUser.username;

          let x = -1;
          solicitacaoEmbarque.transportadoras.forEach(function(transportadora) {
            x++;
            if (transportadora.id==null) {
              const historico = {
                id: null,
                dataCadastro: dataCadastro,
                solicitacaoEmbarqueId: solicitacaoEmbarque.id,
                codigoEstabelecimento: solicitacaoEmbarque.codigoEstabelecimento,
                transportadoraId: transportadora.transportadoraId,
                nomeTransportadora: transportadora.nomeFantasia,
                cnpj: transportadora.cnpj,
                usuario: user,
                valorFreteAnterior: 0,
                valorFreteNovo: transportadora.valorFrete,
                observacaoAnterior: "Valor Inicial",
                observacaoNova: transportadora.observacao,
                title: "Valor inicial: R$ " + transportadora.valorFrete
              };
              solicitacaoEmbarque.transportadoras[x]["historico"] = historico;
              solicitacaoEmbarque.transportadoras[x].usuario = user;
            }
          });

          solicitacaoEmbarque["saldoEmbarque"] = solicitacaoEmbarque.quantidade;
          if(indice >= 0) {
            this.embarque.solicitacoes[indice] = solicitacaoEmbarque;
          }
          else {
            this.embarque.solicitacoes.push(solicitacaoEmbarque);
          }
          this.pegaPedido(solicitacaoEmbarque);
          this.solicitacaoEmbarqueFechar();
          this.solicitacaoEmbarque = {};
          this.calcularSaldoEmbarque();
        },

        pegaPedido(solicitacaoEmbarque){
            const dat = this.data;
            let cc = -1;
            for(let d of dat){
              cc++;
              if(d.id==solicitacaoEmbarque.pedidoId){
                break;
              }
            }
            this.data[cc].saldoEmbarque = this.data[cc].saldoEmbarque - solicitacaoEmbarque.quantidade;
            solicitacaoEmbarque["pedido"] = this.data[cc];
            let sp = 0;
            for(let d of dat){
              sp += d.saldoEmbarque;
            }
            this.embarque.saldoEmbarque = sp;
        },

        visualizarHistorico(pedido) {
            this.descricaoHistoricoPedidoDialog = "Pedido: "+ pedido.id;
            this.historicoPedidoDialog = true;
            this.pedidoSelecionado = pedido;
        },

        ocultarHistorico() {
            this.historicoPedidoDialog = false;
            this.pedidoSelecionado = {};
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(data, format){
            return Formatacao.formatDateCustom(data, format);
        },

        faturar(pedido){
            pedido.modalidadePrecificacao = this.gestao.modalidadePrecificacao;
            this.faturamento = this.preencherFaturamento(pedido);
            this.faturamento["observacaoNota"] = pedido.observacaoNota;
            this.saldoFaturamentoModal = this.faturamento.saldoFaturamentoPedidoEmKG;
            this.isEdicaoFaturamento = true;
            this.faturamentoDialog = true;
        },

        calcularSaldoEmbarque(){
          this.embarque.saldo = this.embarque.saldoContrato - this.embarque.saldoEmbarque;
        },

        incluirSolicitacaoEmbarque(pedido){
          this.saldoEmbarqueModal = pedido.saldoEmbarque;
          this.isEdicaoSolicitacaoEmbarque = false;
          this.solicitacaoEmbarqueDialog = true;
          this.solicitacaoEmbarque = {
            id: null,
            indice: -1,
            embarqueId: this.embarque.id,
            saldoFaturamento: pedido.saldoFaturamento,
            codigoEstabelecimento: pedido.codEstabelecimentoOrigem,
            nomeEstabelecimento: null,
            quantidade: null,
            cota: null,
            classificacao: null,
            nomeClassificacao: null,
            transportadoras: [],
            pedidoId: pedido.id
          };
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.qtdRegistroPorPagina = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows;
            this.carregarDados();
        },

    }
}
</script>

<style scoped>
    .borda-personalizada {
        border: 1px solid #CCC;
        border-radius: 3px;
        padding: 1rem;
    }
</style>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
