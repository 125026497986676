<template>
  <div class="flex">
        <span class="p-input-icon-right mr-1" >
            <i class="pi pi-search" @click="abrirFiltroAvancado()" v-if="!disabled" />
            <InputText
                :disabled="disabled"
                :class="{ 'p-invalid': invalid }"
                style="width: 175px;"
                placeholder="Código Item"
                v-model="codItemInput"
                @keyup.enter="selecionar"
                @change="selecionar"
            />
        </span>

  </div>

  <Dialog modal header="Buscar Item" v-model:visible="filtroAvancado" :style="{width: '60%'}" >

    <DataTable
        @sort="sort($event)"
        showGridlines
        selectionMode="single"
        responsiveLayout="stack"
        :value="produtos"
        v-model:selection="produtoSelecionado"
        @row-select="selecionar">

      <template #empty>
        Nenhum registro encontrado.
      </template>

      <template #loading>
        Carregando... Por favor, aguarde.
      </template>

      <Column field="codItem" header="Código Item" style="width:8rem;" sortable/>
      <Column field="descItem" header="Descrição" sortable/>
      <Column field="saldoEstoque" header="Estoque" style="width:8rem;" sortable>
        <template #body="{data}" >
          {{ formatDecimal(data.saldoEstoque, 2, 2) }}
        </template>
      </Column>

    </DataTable>

  </Dialog>
</template>

<script>
import ProdutoService from "../../service/ProdutoService"
import Formatacao from '../../utilities/Formatacao';

export default {
  components: (ProdutoService),
  emits: ["selecionado"],
  props: {
    disabled: {
      type: Boolean,
      required: false
    },
    invalid: {
      type: Boolean,
      required: false
    },
    codGrupoProduto: {
      type: String,
      required: true
    },
    dados: {
      type: Object,
      required: true
    },
    codItem: {
      type: String,
      required: true
    },
    descItem: {
      type: String,
      required: true
    },

  },

  data() {
    return {
      filtros: {},
      produtos: [],
      totalRegistro: 0,
      filtroAvancado: false,
      produtoSelecionado: {},
      codItemInput: this.codItem,
      descItemInput: this.descItem
    }
  },

  updated() {
    this.codItemInput = this.codItem;
  },

  methods: {

    filtrar() {

      ProdutoService.getProdutoPorGrupo(this.codGrupoProduto)
          .then(({ data }) => {
            if(data) {
              this.produtos = data;
            }
          })
          .catch(error => {
            this.produtos = [];
          });
    },

    selecionar() {
      this.codItemInput = this.produtoSelecionado.codItem;
      this.descItemInput = this.produtoSelecionado.descItem;

      this.$emit('selecionado', this.produtoSelecionado);
      this.fecharFiltroAvancado();
    },

    abrirFiltroAvancado() {
      this.filtros = {};
      this.filtros["size"] = 100;
      this.filtros["page"] = 0;
      this.filtros["sort"] = 'codigoCliente,asc';

      this.filtros.inicio = Formatacao.subtrairDias(new Date(), 1);
      this.filtros.termino = new Date();

      this.filtrar();

      this.filtroAvancado = true;
    },

    fecharFiltroAvancado() {
      this.filtroAvancado = false;
    },

    limparFiltro() {
      this.filtros.inicio = Formatacao.subtrairDias(new Date(), 1);
      this.filtros.termino = new Date();

      this.filtros["nroDocproduto"] = undefined;
      this.filtrar();
    },

    limparPeriodoModal() {

      if(this.filtros) {
        this.filtros.inicio = null;
        this.filtros.termino = null;
      }

      this.filtrar();
    },

    formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
      return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
    },

    onPage(event) {
      this.filtros["page"] = event.page + 1;
      this.filtros["size"] = event.rows;
      this.filtrar();
    },

    sort(event) {
      const sortField = event.sortField;
      const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
      this.filtros["sort"] = `${ sortField },${sortOrder}`;
      this.filtrar();
    }
  }
}
</script>