import axios from "axios";
import ApiUrl from '../../config/ApiUrl';

class GestaoFaturamentoPedidoService {
    salvar(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/gestao-faturamento-pedido`, dados, config);
    }

    buscarPorId(id) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/gestao-faturamento-pedido/${ id }`);
    }

    buscarSaldoPedido(contratoCompraVendaId) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/gestao-faturamento-pedido/saldo-pedido/contrato-compra-venda/${ contratoCompraVendaId }`);
    }

    buscarSaldoPedidoGestao(gestaoFaturamentoPedidoId) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/gestao-faturamento-pedido/saldo-pedido-gestao/gestao-faturamento-pedido/${ gestaoFaturamentoPedidoId }`);
    }

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'id,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/gestao-faturamento-pedido/buscar-com-paginacao`, { params });
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/gestao-faturamento-pedido/inativar/${ id }`);
    }
    
    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/gestao-faturamento-pedido/ativar/${ id }`);
    }

}

export default new GestaoFaturamentoPedidoService();