<template>
    <div class="flex">
        <span class="p-input-icon-right mr-1" style="width: 34%;">
            <i class="pi pi-search" @click="abrirFiltroAvancado()" v-if="!disabled" />
            <InputNumber 
                :disabled="disabled" 
                :class="{ 'p-invalid': invalid }"
                class="w-full"
                placeholder="Código"
                locale="pt-BR" 
                :minFractionDigits="0"
                :maxFractionDigits="0"
                v-model="nroDocPesagemInput"
                @keyup.enter="selecionar"
                @change="selecionar"
            />
        </span>

        <Calendar
            :disabled="true"
            :class="{ 'p-invalid': invalid }"
            style="width: 66%;"
            v-model="dataDocPesagemInput"
            placeholder="Data"
            dateFormat="dd/mm/y"
        />
    </div>

    <Dialog modal header="Buscar Pesagem" v-model:visible="filtroAvancado"  :style="{width: '70%'}">

        <DataTable 
            @sort="sort($event)"
            showGridlines
            selectionMode="single"
            responsiveLayout="stack"
            :value="pesagens"
            v-model:selection="pesagemSelecionada"
            @row-select="selecionar">

            <template #header>
                <div class="grid p-fluid formgrid align-items-center">
                    <div class="col-12 lg:col-1">
                        <div class="field">
                            <label for="idestabelecimento"><strong>Estab.</strong></label>
                            <InputText id="idestabelecimento" class="w-full" v-model="codEstabelecimento" disabled/>
                        </div>
                    </div>

                    <div class="col-10 lg:col-1">
                        <div class="field">
                            <label for="idgrupo"><strong>Grupo</strong></label>
                            <InputText id="idgrupo" class="w-full" v-model="codGrupoProduto" disabled/>
                        </div>
                    </div>

                    <div class="col-12 lg:col-2">
                        <div class="field">
                            <label for="iddocpesageminicial"><strong>Doc. Pesagem Inicial</strong></label>
                            <InputText id="iddocpesageminicial" class="w-full" v-model="this.filtros.nroDocPesagemInicial" autofocus/>
                        </div>
                    </div>

                    <div class="col-12 lg:col-2">
                        <div class="field">
                            <label for="iddocpesagemfinal"><strong>Doc. Pesagem Final</strong></label>
                            <InputText id="iddocpesagemfinal" class="w-full" v-model="this.filtros.nroDocPesagemFinal" />
                        </div>
                    </div>

                    <div class="col-12 lg:col-3">
                        <div class="field">
                            <label><strong>Período</strong></label>
                            <div class="flex align-items-center">
                                <div class="overflow-hidden">
                                    <Calendar
                                        class="w-full"
                                        v-model="filtros.inicio"
                                        autocomplete="off"
                                        dateFormat="dd/mm/y"
                                        :maxDate="filtros.termino"
                                    />
                                </div>
                                <strong class="ml-2 mr-2">à</strong>
                                <div class="overflow-hidden">
                                    <div class="p-inputgroup">
                                        <Calendar
                                            class="w-full"
                                            v-model="filtros.termino"
                                            autocomplete="off"
                                            dateFormat="dd/mm/y"
                                            :minDate="filtros.inicio"
                                            :maxDate="dataMaxima"
                                        />
                                        <Button
                                            icon="pi pi-trash"
                                            style="border-radius: 0 10% 10% 0;"
                                            @click="limparPeriodoModal()"
                                        ></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-2 mt-2">
                        <div class="p-inputgroup">
                            <Button
                                label="Filtrar"
                                icon="pi pi-filter"
                                class="p-button-outlined p-button-info"
                                @click="filtrar"
                            ></Button>

                            <Button 
                                title="Limpar Filtro"
                                icon="pi pi-filter-slash"
                                class="p-button-outlined p-button-info"
                                style="margin-left: 1px;"
                                @click="limparFiltro"
                            ></Button>
                        </div>
                    </div>
                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="data" header="Data" style="padding-top: 10px; padding-bottom: 10px;" sortable>
                <template #body="{ data, field }">
                    {{ formatDateCustom(data[field], "DD/MM/YY") }}
                </template>
            </Column>   
            <Column field="hora" header="Hora" sortable/> 
            <Column field="nroDocPesagem" header="Doc. Pesagem" sortable/> 
            <Column field="codigoProduto" header="Produto" sortable>
                <template #body="{ data }">
                    <strong :title="data.nomeProduto">{{ data.codProduto }}</strong>
                </template>
            </Column>   
            <Column field="tipoPesagem" header="Tipo" sortable/> 
            <Column field="placa" header="Placa" sortable/>
            <Column field="motorista" header="Motorista" sortable/>
            <Column field="pesoEntrada" header="Peso Entrada" sortable/>
            <Column field="pesoSaida" header="Peso Saída" sortable/>
            <Column field="pesoLiquido" header="Peso Liquido" sortable/>
            <Column field="safra" header="Safra" sortable/> 
        </DataTable>
        <Paginator
            :rows="filtros.size"
            :totalRecords="totalRegistro"
            :rowsPerPageOptions="[10, 20, 30]"
            @page="onPage"/>
    </Dialog>
</template>

<script>
import PesagemService from '../../service/PesagemService';
import Formatacao from '../../utilities/Formatacao';

export default {
    emits: ["selecionado"],
    props: {
        disabled: {
            type: Boolean,
            required: false
        },
        invalid: {
            type: Boolean,
            required: false
        },
        codEstabelecimento: {
            type: String,
            required: true
        },
        codGrupoProduto: {
            type: String,
            required: true
        },
        nroDocPesagem: {
            type: String,
            required: false
        },
        dataDocPesagem: {
            type: String,
            required: false
        },
        nroDocPesagemI: {
          type: String,
          required: true
        },
        nroDocPesagemF: {
          type: String,
          required: true
        }
    },

    data() {
        return {
            filtros: {},
            pesagens: [],
            totalRegistro: 0,
            filtroAvancado: false,
            pesagemSelecionada: {},
            dataMaxima: new Date(),
            campoInvalido: {},
            nroDocPesagemInput: this.nroDocPesagem,
            dataDocPesagemInput: this.dataDocPesagem
        }
    },

  methods: {

        filtrar() {
          const filtrosCalculo = this.getFiltros();
          filtrosCalculo["size"] = 99;
          PesagemService.buscarPesagens(filtrosCalculo)
              .then(({ data }) => {
                if(data) {
                  this.totalRegistro = data.pesagens.content.length ;
                }
              })
              .catch(error => {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Atenção!',
                    detail: error.response.data.message,
                    life: 15000
                });
                
              });

          const filtros = this.getFiltros();
          PesagemService.buscarPesagens(filtros)
                .then(({ data }) => {
                    if(data) {     
                        let tickets = data.pesagens.content;
                        if(tickets){
                            tickets.forEach(function(ticket) {
                                if(ticket.pesoLiquido < 0){
                                    ticket.pesoLiquido = ticket.pesoLiquido * (-1);                            
                                } 
                            });
                        }
                        
                        this.pesagens = tickets;
                    }
                })
                .catch(error => {
                    this.pesagens = [];
                });
        },

        getFiltros() {
                const filtros = Object.assign({}, this.filtros);
                filtros.codEstabelecimento = this.codEstabelecimento;
                filtros.codGrupoProduto = this.codGrupoProduto;
                filtros.codProduto = this.codGrupoProduto;

                if(filtros.inicio) {
                    filtros.inicio = Formatacao.formatDateCustom(filtros.inicio, 'YYYY-MM-DD');
                }

                if(filtros.termino) {
                    filtros.termino = Formatacao.formatDateCustom(filtros.termino, 'YYYY-MM-DD');
                }
                return filtros;
            },

        selecionar() {     
            this.nroDocPesagemInput = this.pesagemSelecionada.nroDocPesagem;
            this.dataDocPesagemInput = Formatacao.formatDateCustom(this.pesagemSelecionada.data,"DD/MM/YYYY");

            this.$emit('selecionado', this.pesagemSelecionada);
            this.fecharFiltroAvancado();
        },

        abrirFiltroAvancado() {
            this.filtros = {};
            this.filtros["size"] = 10;
            this.filtros["page"] = 0;
            this.filtros["sort"] = 'nroDocPesagem,asc';

            this.filtros.inicio = Formatacao.subtrairDias(new Date(), 30);
            this.filtros.termino = new Date();
            
            this.filtrar();

            this.filtroAvancado = true;
        },

        fecharFiltroAvancado() {
            this.filtroAvancado = false;
        },

        limparFiltro() {
            this.filtros.inicio = Formatacao.subtrairDias(new Date(), 30);
            this.filtros.termino = new Date();
            this.filtros.nroDocPesagemInicial = null;
            this.filtros.nroDocPesagemFinal = null;
            this.filtrar();
        },

        limparPeriodoModal() {

            if(this.filtros) {
                this.filtros.inicio = null;
                this.filtros.termino = null;
            }

            this.filtrar();
        },

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },

        onPage(event) {
            this.filtros["page"] = event.page + 1;
            this.filtros["size"] = event.rows;
            this.filtrar();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.filtros["sort"] = `${ sortField },${sortOrder}`;
            this.filtrar();
        }
    }
}
</script>