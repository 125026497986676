<template>
    <Dialog v-model:visible="visivel" :style="{width: '85%', margin: '5%'}" :modal="true" @hide="fechar()" @show="init()" >
        <template #header>
            <div class="flex align-items-center mt-2">
                <i class="pi pi-file mr-2" style="font-size: 1.4rem;"></i>
                <h4 style="margin:0;">Faturamento</h4>
            </div> 
        </template>

        <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

        <div class="grid">
            <Fieldset legend="Dados faturamento">
                <div class="col-12">
                    <div class="grid align-items-center">

                        <div class="col-2 label">
                            <label> <strong>Pedido Gênesis:</strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <InputText class="w-full" v-model="faturamento.pedidoId" disabled/>
                        </div>
                        
                        <div class="col-2 label">
                            <label> <strong>Local de retirada:</strong> </label>
                        </div>
                        <div class="col-6 campo">
                            <AutoComplete 
                                :dropdown="true"
                                :disabled="true"
                                class="w-full"
                                field="codigoNomeFantasia"
                                placeholder="Informe o código ou o nome fantasia."
                                v-model="estabelecimentoSelecionado"
                                :forceSelection="true"
                                :suggestions="estabelecimentosFiltro"
                                @complete="carregarEstabelecimentos($event)"
                                @item-select="selecionarEstabelecimento()"
                                :class="{ 'w-full p-invalid': campoInvalido.estabelecimento }"/>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Cliente:</strong> </label>
                        </div>
                        <div class="col-6 campo">
                            <ProdutorFiltro
                                :disabled="true"
                                :campoObrigatorio="true"
                                :selecionado="faturamento.cliente"
                                :paginacao="clienteConsulta"
                                :invalid="campoInvalido.cliente"
                                @pesquisar="pesquisarCliente"
                                @selecionado="selecionarCliente"
                                />
                        </div>       

                        <div class="col-4 campo">
                            <InputText class="w-full" v-model="faturamento.cliente.cpfCnpj" disabled/>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Destino Mercadoria:</strong> </label>
                        </div>
                        <div class="col-6 campo">
                            <AutoComplete 
                                dropdown
                                :disabled="true"
                                placeholder="Selecione..." 
                                v-model="faturamento.destinoMercadoria"
                                field="descricao"
                                id="destinoMercadoria"
                                class="w-full"
                                :class="{ 'p-invalid': campoInvalido.destinoMercadoria }"
                                :suggestions="destinoMercadoriaFiltro"
                                :forceSelection="true"
                                @complete="buscarDestinoMercadoria($event)"
                            />
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Nº Pedido Compra Cliente:</strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <InputText disabled class="w-full" v-model="faturamento.nroPedidoCompraCliente" maxlength="200"/>
                        </div>

                        <div class="col-2 label ml-0">
                            <label> <strong>Nat. Operação:</strong> </label>
                        </div>
                        <div class="col-6 campo">
                            <AutoComplete      
                                field="label"  
                                disabled                                          
                                :dropdown="true" 
                                class="w-full"
                                @complete="buscarNaturezaOperacao($event)"
                                placeholder="Informe a natureza do pedido"
                                v-model="faturamento.naturezaOperacao"
                                :suggestions="naturezaOperacaoFiltro"
                                :forceSelection="true"
                                :class="{ 'w-full p-invalid': campoInvalido.naturezaOperacao }"
                               ></AutoComplete>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Condição Pagto:</strong> </label>
                        </div>
                        <div class="col-2 campo">
                          <InputText disabled class="w-full" v-model="condicaoPagamento" maxlength="200"/>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Grupo de produto:</strong> </label>
                        </div>
                        <div class="col-6 campo">
                            <InputText disabled class="w-full" v-model="grupoProduto.codDesc" maxlength="200"/>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Classificação:</strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <InputText class="w-full" disabled v-model="classificacao"/>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Ticket de Pesagem:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                        <div class="col-6 label">
                            <PesagemZoom
                                :codEstabelecimento=faturamento.codEstabelecimentoOrigem
                                :codGrupoProduto=grupoProduto.fmCodigo
                                :nroDocPesagem=faturamento.nrDocPes
                                :data=faturamento.dataNrDocPes
                                :disabled="false"
                                @selecionado="selecionarPesagem"
                            />
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Placa Veículo:</strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <InputText disabled class="w-full" v-model="faturamento.placa"/>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Transportadora:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                        <div class="col-6 campo">
                            <AutoComplete
                                class="w-full"
                                placeholder="Preencha o nome da transportadora."
                                field="transportadora"
                                v-model="faturamento.transportadora"
                                :suggestions="transportadora"
                                :forceSelection="true"
                                :dropdown="true"
                                @complete="buscarTransportador($event)"
                            />
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Motorista:</strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <InputText disabled class="w-full" v-model="faturamento.motorista"/>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Item:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                      <div class="col-10 label">
                        <div class="p-inputgroup">
                          <ProdutoZoom
                              :codGrupoProduto=grupoProduto.id
                              :codItem=faturamento.itCodigo
                              :data=faturamento.dataNrDocPes
                              :disabled="false"
                              @selecionado="selecionarProduto">
                          </ProdutoZoom>
                          <InputText
                              :disabled="true"
                              :class="{ 'p-invalid': invalid }"
                              v-model="descricaoItem"
                              placeholder="Nome"
                          />

                        </div>
                      </div>
                        <div class="col-2 label">
                            <label> <strong>Saldo Estoque:</strong> </label>
                        </div>
                        <div class="col-2 campo" v-if="faturamento.produto">
                            <div class="p-inputgroup">
                                <InputNumber class="w-full" v-model="faturamento.produto.saldoEstoque" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                                <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="siglaUnidadeMedida"/>
                            </div>
                        </div>
                        <div class="col-2 campo" v-if="!faturamento.produto">
                            <div class="p-inputgroup">
                                <InputNumber class="w-full" v-model="saldoEstoque" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                                <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="siglaUnidadeMedida"/>
                            </div>
                        </div>
                        
                        <div class="col-2 label" v-if="faturamento.modalidadePrecificacao!='A FIXAR - ANTECIPADA' && modalidadePrecificacao!='A FIXAR - ANTECIPADA'">
                            <label> <strong>Saldo Faturamento Pedido:</strong> </label>
                        </div>
                        <div class="col-2 campo" v-if="faturamento.modalidadePrecificacao!='A FIXAR - ANTECIPADA' && modalidadePrecificacao!='A FIXAR - ANTECIPADA'">
                            <div class="p-inputgroup">
                                <InputNumber class="w-full" v-model="saldoPedido" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                                <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="siglaUnidadeMedida"/>
                            </div>
                        </div>

                        <div class="col-2 label" v-if="faturamento.modalidadePrecificacao=='A FIXAR - ANTECIPADA' || modalidadePrecificacao=='A FIXAR - ANTECIPADA'">
                          <label> <strong>Saldo (Qt. Fixada):</strong> </label>
                        </div>
                        <div class="col-2 campo" v-if="faturamento.modalidadePrecificacao=='A FIXAR - ANTECIPADA' || modalidadePrecificacao=='A FIXAR - ANTECIPADA'">
                          <div class="p-inputgroup">
                            <InputNumber class="w-full" v-model="qtdFixada" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                            <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="siglaUnidadeMedida"/>
                          </div>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Qt. Faturamento:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <div class="p-inputgroup">
                                <InputNumber disabled class="w-full" @blur="recalcula($event)" id="quantidade"
                                    :class="{ 'p-invalid': campoInvalido.quantidade }"
                                    v-model="faturamento.quantidade" mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                                <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="siglaUnidadeMedida"/>
                            </div>
                        </div>

                      <div class="col-2 label">
                        <label> <strong>Valor Unitário:</strong> </label>
                      </div>
                      <div class="col-2 campo">
                        <div class="p-inputgroup">
                          <InputNumber :disabled="true" class="w-full"
                                       v-model="precoUnitarioCcv" mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="6" :min="0" :max="999999999"/>
                        </div>
                      </div>

                      <div class="col-2 label">
                        <label> <strong>Valor Total:</strong> </label>
                      </div>
                      <div class="col-2 campo">
                        <div class="p-inputgroup">
                          <InputNumber class="w-full" v-model="valorTotal" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                        </div>
                      </div>
                      <div class="col-2 label">
                        <label> <strong>Nº Nota Fiscal/Série:</strong> </label>
                      </div>
                      <div class="col-2 campo">
                        <div class="p-inputgroup">
                          <InputText v-model="notaFiscal" class="w-full" disabled />
                        </div>
                      </div>
                      <div class="col-2 label">
                        <label> <strong>Observações NF:</strong> </label>
                      </div>
                      <div class="col-10 campo">
                        <Textarea class="w-full" id="observacaoNotaFiscal" v-model="faturamento.observacaoNota" @focus="press()" variant="filled" rows="2"></Textarea>
                      </div>

                    </div>
                </div>
            </Fieldset>
        </div>

        <template #footer>
            <div class="flex justify-content-center">

                <Button id="botaoSalvar"
                    icon="pi pi-save"
                    label="Salvar"
                    class="p-button-info"
                    @click="salvar()"
                ></Button>

                <Button
                    icon="pi pi-times"
                    label="Cancelar"
                    class="p-button-danger ml-2"
                    @click="fechar()"
                ></Button>
            </div>
        </template>
    </Dialog>

    <ModalProduto
        :visivel="produtoDialog"
        :dados="faturamento"
        @fechar="fecharProduto"
        @salvar="fecharProduto"
    />    
</template>

<script>

    import ModalProduto from './ModalProduto.vue';
    import DestinoMercadoriaService from '../../../service/DestinoMercadoriaService';
    import CondicaoPagamentoService from '../../../service/CondicaoPagamentoService';
    import NaturezaOperacaoService from '../../../service/NaturezaOperacaoService';
    import EstabelecimentoService from '../../../service/EstabelecimentoService';
    import ProdutorService from '../../../service/ProdutorService';
    import Formatacao from '../../../utilities/Formatacao';
    import ProdutorFiltro from '../ProdutorFiltro.vue';
    import GrupoProdutoService from '../../../service/GrupoProdutoService';
    import ProdutoService from '../../../service/ProdutoService';
    import FaturamentoService from '../../../service/pedido/FaturamentoService';
    import TransportadoraService from '../../../service/TransportadoraService';
    import PesagemZoom from '../../zoom/PesagemZoom.vue';
    import ProdutoZoom from "../../zoom/ProdutoZoom.vue";
    import ContratoCompraVendaService from "@/service/ContratoCompraVendaService";

    export default {
        name: "ModalFaturamentoVG",
        components: { ProdutorFiltro, PesagemZoom, ModalProduto, ProdutoZoom},
        emits: ["fechar"],
        props: {
            visivel: {
                type: Boolean,
                required: true
            },
            isEdicao: {
                type: Boolean,
                required: true
            },
            faturamento: {
                type: Object,
                required: true
            },
            pedido: {
                type: Object,
                required: true
            },
            siglaUnidadeMedida:{
                type: String,
                required: true
            },
            saldoPedido:{
                type: Number,
                required: true
            },
            modalidadePrecificacao: {
              type: String
            },
            precoUnitarioCcv: {
              type: Number,
              required: true
            },
            qtdFixada: {
              type: Number,
              required: true
            },
            contratoId: {
              type: Number,
              required: true
            }

        },

        data() {
            return {
                cotaEmbarque: null,
                grupoProduto: {},
                mensagem: { ativo: false, gravidade: 'info', conteudo: '' },
                clienteConsulta: {},
                campoInvalido: {},
                produtos: [],
                transportadora: [],
                estabelecimentoSelecionado: null,
                estabelecimentosFiltro: [],
                condicaoPagamentoFiltro: [],
                destinoMercadoriaFiltro: [],
                naturezaOperacaoFiltro: [],
                destinosMercadoria: [],
                condicoesPagamento: CondicaoPagamentoService.listaMock(),
                naturezasOperacao: NaturezaOperacaoService.listaMock(),
                disabledItens: true,
                produtoDialog: false,
                produto: {},
                saldoEstoque: 0,
                valorTotal: 0,
                notaFiscal: '',
                descricaoItem: '',
                condicaoPagamento: '-',
                condicaoPagamentoId: null,
                condicaoPagamentoDesc: null
            }
        },

        updated() {
            this.buscarSaldoProduto();
            if(this.faturamento.itCodigo==null){ this.descricaoItem=null;}
        },

      methods: {

            init(){
                this.carregarEstabelecimento(); 
                this.buscarGrupoProduto();
                this.recalcula();
                this.carregarCondicao();
                if (!!this.faturamento.nrNotaFiscal) {
                  this.notaFiscal = this.faturamento.nrNotaFiscal + "/" + this.faturamento.serieNotaFiscal;
                }
            },

            carregarCondicao(){
              ContratoCompraVendaService.buscarPorId(this.contratoId)
                  .then(({data})=>{
                    this.condicaoPagamentoId = data.formaPagamento.condicaoPagamento!=null ?
                        data.formaPagamento.condicaoPagamento.id :
                        "0";
                    this.condicaoPagamentoDesc = data.getFormaPagamento;
                    this.condicaoPagamento=data.getFormaPagamento;
                  });
            },

            limpar() {
                this.mensagem = { ativo: false };
            },

            carregarDestinoMercadoria() {
                DestinoMercadoriaService.lista()
                    .then(({ data }) => {
                        this.destinosMercadoria = data;
                    });
            },
            
            async carregarEstabelecimento(){
                this.disabledEstabelecimento = this.faturamento.id != null;
                if(this.faturamento.codEstabelecimentoOrigem != null) {
                    try {
                        const estabelecimento = await EstabelecimentoService.getEstabelecimentosPorCodigo(this.faturamento.codEstabelecimentoOrigem);
                        if(!!estabelecimento.data) {
                            estabelecimento.data["codigoNomeFantasia"] = estabelecimento.data.codigo + " - " + estabelecimento.data.nomeFantasia;
                            this.estabelecimentoSelecionado = estabelecimento.data;
                        }
                    }
                    catch(error) {
                        if(!!error && !!error.response) {
                            const { data } = error.response;
                            this.$toast.add({
                                severity:'error', 
                                summary: 'Falha ao buscar estabelecimento.',
                                detail: data.message,
                                life: 5000
                            });
                        }
                    }

                }else{
                    this.estabelecimentoSelecionado = null;
                }
            },

            carregarEstabelecimentos(event) {
                const codigoOuNomeFantasia = !event.query.trim().length ? undefined : event.query;
                EstabelecimentoService.pesquisarPorParametroUsuarioVsEstabelecimentoComUsuarioAutenticado({codigoOuNomeFantasia, re: true})
                    .then(({ data }) => {
                        if(data) {
                            this.estabelecimentosFiltro = data.map(item => {
                                item["codigoNomeFantasia"] = item.codigo + " - " + item.nomeFantasia;
                                return item;
                            });
                        }
                    })
                    .catch(error => {
                        this.estabelecimentosFiltro = [];
                    });
            },

            desabilitar(){
                if(this.grupoProduto == null || this.grupoProduto == undefined || this.grupoProduto == ""){
                    return true;
                }
                return false;
            },

            selecionarEstabelecimento(){
                if(this.estabelecimentoSelecionado == undefined) { 
                    this.faturamento.codEstabelecimentoOrigem = null;
                    this.faturamento.nomeEstabelecimentoOrigem = null;
                }else{
                    this.faturamento.codEstabelecimentoOrigem = this.estabelecimentoSelecionado.codigo;
                    this.faturamento.nomeEstabelecimentoOrigem = this.estabelecimentoSelecionado.nomeFantasia;
                }
            },

            buscarTransportador(event) {             
                const codigoOuDescricao = !event.query.trim().length ? 's' : event.query;
                TransportadoraService.getPorDescricao(codigoOuDescricao)
                    .then(({ data }) => {
                        if(data) {
                            this.transportadora = [...data];
                            this.transportadora.forEach(item => {
                                item["transportadora"] = (!!item.codigoErp ? item.codigoErp + " - " : "")
                                + item.nomeFantasia + " - CNPJ: " + item.cnpj;
                            });
                        }
                    })
                    .catch(error => {
                        this.transportadora = [];
                    });
            },
            
            produtoModal(event){
                this.produtos = [];
                this.produtoDialog=true;
            },
            
            pesquisarCliente(filtros) {
                ProdutorService.pesquisarProdutor(filtros)
                    .then(({ data }) => {
                        if(data) {
                            this.clienteConsulta = data;
                        }
                    })
                    .catch(error => {
                        this.clienteConsulta = {}

                        const toast = {
                            severity: 'error',
                            summary: 'Falha ao carregar os clientes.',
                            detail: error, 
                            life: 7500
                        }

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            },

            selecionarPesagem(pesagem){
                if(pesagem == null || pesagem == undefined){ 
                    this.faturamento.pesagem = {};
                    this.faturamento.nrDocPes = null;
                    this.faturamento.placa = null;
                    this.faturamento.dataNrDocPes = null;
                    this.faturamento.motorista = null;
                    this.faturamento.quantidade = null;
                    this.valorTotal = 0;
                }else{
                    this.faturamento.pesagem = pesagem;
                    this.faturamento.nrDocPes = pesagem.nroDocPesagem;
                    if (pesagem.data != null) {
                      this.faturamento.dataNrDocPes = pesagem.data;
                    }
                    this.faturamento.placa = pesagem.placa;
                    this.faturamento.motorista = pesagem.motorista;
                    this.faturamento.quantidade = pesagem.pesoLiquido;
                    this.faturamento.itCodigo = pesagem.codProduto;
                    this.buscarSaldoProduto();
                    this.recalcula();
                }
            },

            selecionarProduto(prod){
              this.faturamento.itCodigo = prod.codItem;
              this.descricaoItem = prod.descItem;
              this.saldoEstoque = prod.saldoEstoque;
            },

            selecionarCliente(codProdutor) {
                if(codProdutor == undefined || codProdutor == null || codProdutor.trim() == "") {
                    this.faturamento.cliente = {};
                    return;
                }


                ProdutorService.buscarPorCodProdutorInfoContrato(codProdutor)
                    .then(({ data }) => {
                        if(data) {
                            this.faturamento.cliente = data;
                        }
                    })
                    .catch(error => {
                        this.faturamento.cliente = {};

                        const toast = {
                            severity: 'error',
                            summary: `Falha ao buscar o cliente.`,
                            detail: error, 
                            life: 7500
                        }

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;

                            if(!!message) {
                                toast["detail"] = message.replace("produtor", "cliente");
                            }
                        }

                        this.$toast.add(toast);
                    });
            },

            buscarGrupoProduto(){
                
                GrupoProdutoService.getGrupoPorid(this.faturamento.grupoProdutoId)
                    .then(({ data }) => {
                        if(data) {
                            this.grupoProduto = data;
                        }
                    })
                    .catch(error => {
                        const toast = {
                            severity: 'error',
                            summary: `Falha ao buscar o grupo de produto.`,
                            detail: "O grupo de produto não foi encontrado, por favor entre em contato com o Administrador!", 
                            life: 7500
                        }
                        this.fechar();
                    });
            },

            getProduto(){
                if(this.faturamento.itCodigo != null && this.faturamento.itCodigo != undefined && this.faturamento.itCodigo != ""){
                    
                    ProdutoService.getProdutoCodigoDescricao(this.faturamento.itCodigo)
                    .then(({ data }) => {
                        if(data) {
                            this.faturamento["produto"] = data[0];
                        }
                    })
                    .catch(error => {
                        const toast = {
                            severity: 'error',
                            summary: 'Não foi possível carregar o produto.',
                            detail: "Ocorreu uma falha ao carregar o item "+ this.faturamento.itCodigo, 
                            life: 7500
                        }
                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
                }
            },

            recalcula(event) {
              let quantidade = !!this.faturamento.quantidade ? this.faturamento.quantidade : 0;

              if(!!event) {
                const { value, id } = event.target;

                if(!!value) {
                  let valor = value.replace(/\./g, "");
                  valor = valor.replace(/\,/, ".");
                  valor = parseFloat(valor);

                  if(id == "quantidade") {
                    quantidade = valor;
                  }
                }
              }

              this.valorTotal = quantidade * this.precoUnitarioCcv;
            },

            salvar() {
                if(!this.validarCampos()) return;
                const faturamento = this.montarJson();
                FaturamentoService.salvar(faturamento)
                    .then(({ data }) => {
                        if(!!data) {
                            this.fechar();

                            this.$toast.add({
                                severity:'success',
                                summary: 'Sucesso.',
                                detail:`Faturamento salvo com sucesso.`, 
                                life: 7500
                            });
                        }
                    })
                    .catch(error => {
                        const toast = {
                            severity: 'error',
                            summary: 'Não foi possível salvar o faturamento.',
                            detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                            life: 7500
                        }
                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            },

            montarJson() {
                const obj = {};
                obj["id"] = this.faturamento.id;
                obj["versao"] = this.faturamento.versao;
                obj["pedidoId"] = this.faturamento.pedidoId;
                obj["grupoProdutoId"] = this.grupoProduto.id;
                obj["grupoProdutoCodigo"] = this.grupoProduto.codGrupoProduto;
                obj["itCodigo"] = this.faturamento.itCodigo;
                obj["nrDocPes"] = this.faturamento.nrDocPes;
                obj["dataNrDocPes"] = !!this.faturamento.dataNrDocPes ? 
                    Formatacao.formatDateCustom(this.faturamento.dataNrDocPes, "YYYY-MM-DD") : null;
                obj["placa"] = this.faturamento.placa;
                obj["motorista"] = this.faturamento.motorista;
                obj["quantidade"] = this.faturamento.quantidade;
                obj["codNaturezaOperacao"] = this.faturamento.naturezaOperacao.codigo;
                obj["naturezaOperacao"] = this.faturamento.descNaturezaOperacao
                obj["destinoMercadoria"] = this.faturamento.destinoMercadoria.codigo;
                obj["transportadoraId"] = this.faturamento.transportadora.id;
                obj["nomeTransportadora"] = this.faturamento.transportadora.nomeFantasia;
                obj["cnpjTransportadora"] = this.faturamento.transportadora.cnpj;
                obj["valorTotal"] = this.valorTotal;
                obj["valorUnitario"] = this.precoUnitarioCcv;
                obj["nrNotaFiscal"] = this.faturamento.nrNotaFiscal;
                obj["serieNotaFiscal"] = this.faturamento.serieNotaFiscal;
                obj["observacaoNota"] = this.faturamento.observacaoNota;
                return obj;
            },

            preencherCliente(){
                const cliente = {};
                cliente["codigo"] = this.faturamento.cliente.codProdutor;
                cliente["nome"] = this.faturamento.cliente.nome;
                cliente["cnpj"] = this.faturamento.cliente.cpfCnpj;
                cliente["inscricaoEstadual"] = this.faturamento.cliente.rgIe;
                cliente["endereco"] = this.faturamento.cliente.endereco;
                cliente["bairro"] = this.faturamento.cliente.bairro;
                cliente["cidade"] = this.faturamento.cliente.cidade;
                cliente["unidadeFederativa"] = this.faturamento.cliente.estado;
                cliente["cep"] = this.faturamento.cliente.cep;
                cliente["representante"] = this.faturamento.cliente.representante;        
                this.faturamento["cliente"] = cliente;
            },

            validarCampos() {
                let campos = [];
                if(!this.faturamento.quantidade || this.faturamento.quantidade == 0) {
                    campos.push("Quantidade");
                    this.campoInvalido.quantidade = true;
                }
                else {
                    this.campoInvalido.quantidade = false;
                }
                if(!this.faturamento.transportadora) {
                  campos.push("Transportadora");
                  this.campoInvalido.transportadora = true;
                }
                else {
                  this.campoInvalido.transportadora = false;
                }

                if(campos.length > 0) {
                    let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

                    if(campos.length > 1) {
                        mensagem = "Por favor, preencher os campos: ";
                        mensagem += campos.join(", ");

                        const indice = mensagem.lastIndexOf(", ");
                        mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                    }

                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Atenção!',
                        detail: mensagem, 
                        life: 15000
                    });
                }
                return campos.length === 0;
            },

            buscarDestinoMercadoria(event) {
                if (!event.query.trim().length) {
                    this.destinoMercadoriaFiltro = [...this.destinosMercadoria];
                } else {
                    this.destinoMercadoriaFiltro = this.destinosMercadoria.filter((item) => {
                        return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            },

            buscarNaturezaOperacao(event){
                if (!event.query.trim().length) {
                    this.naturezaOperacaoFiltro = [... this.naturezasOperacao];
                } else {
                    this.naturezaOperacaoFiltro = this.naturezasOperacao.filter((item) => {
                        return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            },

            buscarCondicaoPagamento(event) {
                if (!event.query.trim().length) {
                    this.condicaoPagamentoFiltro = [...this.condicoesPagamento];
                } else {
                    this.condicaoPagamentoFiltro = this.condicoesPagamento.filter((item) => {
                        return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            },

            buscarSaldoProduto(){
                ProdutoService.getProdutoCodigoDescricao(this.faturamento.itCodigo)
                    .then(({ data }) => {
                        this.descricaoItem = data[0].descItem;
                        this.saldoEstoque = data[0].saldoEstoque;
                    })
                    .catch(error => {

                        this.$message({
                        severity: 'error',
                        summary: 'Erro',
                        detail: error.response.data.message
                        });
                    });
            },

            fechar() {
                this.$emit("fechar");
                this.limpar();
            },

            fecharProduto() {
                this.produtoDialog = false;
                this.produto = {};
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },

            formatDateCustom(data, format){
                return Formatacao.formatDateCustom(data, format);
            },

            press(){
                document.getElementById("botaoSalvar").focus();
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../../assets/demo/styles/badges.scss';
    .fontUpper{
        text-transform: uppercase;
    }

    .label {
        text-align: right;
        padding: 0;
    }

    .campo {
        margin: 0.5rem 0;
        padding: 0 0 0 0.5rem;
        overflow: hidden;
    }
    .valor-campo-numerico{
        text-align: right !important; 
        font-weight: bold !important;        
        border-bottom: 1px solid rgba(0, 0, 0, 0.38) !important;
    }
    .sigla-campo-numerico{
        border-bottom: 1px solid rgba(0, 0, 0, 0.38) !important;
        text-align: left; 
        width: 70px; 
        font-weight: 600;
    }
</style>