<template>
    <Dialog v-model:visible="visivel" :style="{width: '50%', margin: '10%'}" :modal="true" @hide="fechar()" @show="init()">
        <template #header>
            <div class="flex align-items-center">

                <img src="layout/images/order.png" alt="" style="font-size: 1.4rem" class="mr-2">
                <h3>
                    Configuração de Cotas
                </h3>
            </div> 
        </template>

        <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

        <Fieldset legend="" class="card">
            <div class="grid p-fluid formgrid align-items-center">

                <div class="col-10 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                    <div class="field">
                        <label >
                            <strong>Estabelecimento</strong>
                        </label>
                        <div class="p-inputgroup">
                            <InputText class="w-full" v-model="codigoNomeEstabelecimento" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                        </div>
                    </div>
                </div>

                <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                  <div class="field">
                    <label >
                      <strong>Cliente</strong>
                    </label>
                    <div class="p-inputgroup">
                      <InputText class="w-full" v-model="clienteIdNome" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                    </div>
                  </div>
                </div>

                <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                  <div class="field">
                    <label >
                      <strong>SE</strong>
                    </label>
                    <InputText v-model="solicitacaoEmbarque.id" disabled/>
                  </div>
                </div>

                <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                  <div class="field">
                    <label >
                      <strong>Produto</strong>
                    </label>
                    <div class="p-inputgroup">
                      <InputText class="w-full" v-model="solicitacaoEmbarque.tipoProduto.nome" disabled />
                    </div>
                  </div>
                </div>

                <div class="col-12 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                  <div class="field">
                    <label >
                      <strong>Inicio Embarque<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <Calendar
                        class="w-full"
                        v-model="inicioEmbarqueSelecionado"
                        autocomplete="off"
                        dateFormat="dd/mm/y"
                        :maxDate="new Date()"
                        disabled
                    />
                  </div>
                </div>

                <div class="col-12 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                    <div class="field">
                        <label >
                            <strong>Saldo Embarque</strong>
                        </label>
                        <InputNumber class="w-full" v-model="solicitacaoEmbarque.saldoEmbarque" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999" />
                    </div>
                </div>

                <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                  <div class="field">
                    <label >
                      <strong>Cota Padrão</strong>
                    </label>
                    <InputNumber class="w-full" v-model="solicitacaoEmbarque.cota" :min="0" :max="999999999" :class="{ 'w-full, p-invalid': campoInvalido.cota }" />
                  </div>
                </div>

            </div>

        </Fieldset>

        <HistoricoCotasForm
            @editarHist="editarHist"
            :dto="this.cotas"
            :editavel="true"
            :cotaPadrao="solicitacaoEmbarque.cota"
            :campoInvalido="campoInvalido"
        />

        <template #footer>
            <div class="flex justify-content-center">

                <Button :disabled="editing"
                    icon="pi pi-save"
                    label="Salvar"
                    class="p-button-info"
                    @click="salvar()"
                ></Button>

                <Button
                    icon="pi pi-times"
                    label="Cancelar"
                    class="p-button-danger ml-2"
                    @click="fechar()"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
    import Formatacao from '../../../utilities/Formatacao';
    import HistoricoCotasForm from "@/components/cadastro/embarque/HistoricoCotasForm.vue";
    import AjusteCotaService from "@/service/AjusteCotaService";

    export default {
        name: "ModalManutencaoValorFrete",
        components: { HistoricoCotasForm, AjusteCotaService },
        emits: ["fechar"],
        props: {
            visivel: {
                type: Boolean,
                required: true
            },
            solicitacaoEmbarque: {
                type: Object,
                required: true
            },
            cotas : {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                mensagem: { ativo: false, gravidade: 'info', conteudo: '' },
                codigoNomeEstabelecimento: null,
                clienteIdNome: null,
                inicioEmbarqueSelecionado: null,
                destino: null,
                valorFreteAnterior: null,
                justificativasFiltradas: [],
                justificativaSelecionada: null,

                classificacao: null,
                campoInvalido: {},
                valorFrete: null,
                observacao: null,
                editing: false
            }
        },

      computed: {
			currentUser() {
				return this.$store.state.auth.user;
			},
		},

        methods: {

            init(){
                this.codigoNomeEstabelecimento = this.solicitacaoEmbarque.estabelecimento.codigo +" - "+ this.solicitacaoEmbarque.estabelecimento.nome;
                this.destino = this.solicitacaoEmbarque.destino.nome + "/" + this.solicitacaoEmbarque.destino.uf;
                this.clienteIdNome = this.solicitacaoEmbarque.cliente.id + " - " + this.solicitacaoEmbarque.cliente.nome;
                this.inicioEmbarqueSelecionado = Formatacao.formatDateCustom(this.solicitacaoEmbarque.inicioEmbarque,"DD/MM/YY");

                this.campoInvalido.cota = false;

            },

            editarHist(vl){
              this.editing = vl;
            },

            limpar() {
                this.mensagem = { ativo: false };
            },

            salvar() {  
                if(!this.validarCampos()) return;

                let cotas = [];
                for (let c of this.cotas){
                  if (Formatacao.formatDateCustom(c.dataReferencia, 'DD/MM/YYYY') >= Formatacao.formatDateCustom(new Date, 'DD/MM/YYYY')){
                    cotas.push(c);
                  }
                }

                const dados = {
                  solicitacaoEmbarqueId: this.solicitacaoEmbarque.id,
                  cotaPadrao: this.solicitacaoEmbarque.cota,
                  cotas: cotas
                }

                AjusteCotaService.salvar(dados)
                    .then(({data})=> {

                      this.$toast.add({
                        severity:'success',
                        summary: 'Sucesso.',
                        detail:`Ajuste de Cotas salvo com sucesso.`,
                        life: 7500

                      });
                    })
                    .catch(error => {
                      const toast = {
                        severity: 'error',
                        summary: 'Não foi possível salvar o ajuste de cotas.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!",
                        life: 7500
                      }

                      if(!!error && !!error.response && !!error.response.data) {
                        const { message, exception } = error.response.data;

                        if(exception === "Pedido obrigatório") {
                          toast["detail"] = exception;
                        }
                        else {
                          toast["detail"] = message;
                        }
                      }

                      this.$toast.add(toast);
                    });


                this.$emit("fechar",true);
                this.limpar();
            },

            validarCampos() {
                let campos = [];

                if(this.solicitacaoEmbarque.cota == null || this.solicitacaoEmbarque.cota == 0) {
                    campos.push("Cota Padrão");
                    this.campoInvalido.cota = true;
                }
                else {
                    this.campoInvalido.cota = false;
                }

                if(campos.length > 0) {
                    let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

                    if(campos.length > 1) {
                        mensagem = "Por favor, preencher os campos: ";
                        mensagem += campos.join(", ");

                        const indice = mensagem.lastIndexOf(", ");
                        mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                    }

                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Não foi possível realizar a alteração.',
                        detail: mensagem, 
                        life: 15000
                    });
                }

                return campos.length === 0;
            },

            fechar() {
                this.$emit("fechar",true);
                this.limpar();
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },

            formatDateCustom(data, format){
                return Formatacao.formatDateCustom(data, format);
            }
        }
    }
</script>
