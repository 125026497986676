<template>
	<div class="layout-invoice-page">

    <div class="grid layout-invoice-content" id="div-se">
        <div class="col-12">
            <div class="card">
                <div class="flex justify-content-between">
                    <div class="flex flex-column">
                        <h3>SOLICITAÇÃO DE EMBARQUE # {{ solicitacaoEmbarque.id }}</h3>

                        <div class="p-2" style="border: 1px solid #cecece;">
                            <div class="mt-1">
                                <b>Estabelecimento:</b> {{ solicitacaoEmbarque.estabelecimento.codigo }} - {{ solicitacaoEmbarque.estabelecimento.nome }}
                            </div> 
                            <div class="mt-1">
                                <b>Destino:</b> {{ solicitacaoEmbarque.destino.nome}}/{{ solicitacaoEmbarque.destino.uf}}
                            </div> 
                            <div class="mt-1">
                                <b>Início Embarque.:</b> {{ formatDateCustom(solicitacaoEmbarque.inicioEmbarque, "DD/MM/YYYY") }} 
                            </div> 
                            <div class="mt-1">
                                <b>Emissao:</b> {{ formatDateCustom(solicitacaoEmbarque.dataCadastro, "DD/MM/YYYY HH:mm:ss") }} 
                            </div>  
                        </div>
                    </div>
                    
                    <div class="flex flex-column align-items-end">
                        <img src="layout/images/logo-genesis-blue.png" alt="" style="height: 4rem"/> 
                    </div>
                </div>

                <div class="p-3 pt-5">

                    <div class="mb-3">
                        <i class="pi pi-shopping-cart mr-2" style="font-size: 1.4rem;"></i>
                        <strong style="font-size: 1rem;">PEDIDOS</strong>
                        <ListarPedidos :gestao="solicitacaoEmbarque.pedidos" @atualizar="atualizarSolicitacoes()"/>
                    </div>

					<div class="mb-3">
                        <i class="pi pi-truck mr-2" style="font-size: 1.4rem;"></i>
                        <strong style="font-size: 1rem;">TRANSPORTADORAS</strong>
                        <TransportadoraList :tela="'embarque'" 
                            :transportadoras="solicitacaoEmbarque.transportadoras" 
                            :solicitacaoEmbarque="solicitacaoEmbarque"
                            @atualizar="atualizarSolicitacoes()"/>
                    </div> 

                    <div class="mb-3">
                        <i class="pi pi-info-circle mr-2" style="font-size: 1.4rem;"></i>
                        <strong class="pb-2" style="font-size: 1rem;">OBSERVAÇÕES</strong>
                        <Textarea v-model="solicitacaoEmbarque.observacao" class="w-full" disabled
                            style="border: 1px solid #cecece;"/>
                    </div> 

                    <div class="grid">
                        <div class="col-12 md:col-6">
                            <Panel header="DADOS ADICIONAIS">
                                <div class="flex align-items-center justify-content-between py-2">
                                    <span class="font-bold">Classificação:</span>
                                    <span>{{ solicitacaoEmbarque.descricaoClassificacao }}</span>
                                </div>
                                <div class="flex align-items-center justify-content-between pt-2 py-2">
                                    <span class="font-bold">Classificadora:</span>
                                    <span>{{ solicitacaoEmbarque.nomeClassificadora }}</span>
                                </div>
                                <div class="flex align-items-center justify-content-between pt-2 py-2">
                                    <span class="font-bold">Valor do Frete:</span>
                                    <span>{{ solicitacaoEmbarque.valorFreteFormatado }}</span>
                                </div>
                                <div class="flex align-items-center justify-content-between pt-2 py-2">
                                    <span class="font-bold">Terminal:</span>
                                    <span>{{ solicitacaoEmbarque.terminal?.razaoSocial }}</span>
                                </div>
                            </Panel>
                        </div>
                        <div class="col-12 md:col-6">
                            <Panel header="TOTAL GERAL">
                                <div class="grid text-right">
                                    <div class="col-10 font-bold">
                                        Total Pedido:
                                    </div>
                                    <div class="col-2">
                                        <span class="blue-color">
                                            {{ formatDecimal(solicitacaoEmbarque.quantidade, 2, 2) }} kg
                                        </span>
                                    </div>

                                    <div class="col-10 font-bold">
                                        <span>Total Embarque</span>
                                    </div>
                                    <div class="col-2">
                                        <span class="blue-color">
                                            {{ formatDecimal(solicitacaoEmbarque.totalEmbarque, 2, 2) }} kg
                                        </span>
                                    </div>

                                    <div class="col-10 font-bold">
                                        <span>Saldo Embarque</span>
                                    </div>
                                    <div class="col-2 pb-6">
                                        <span class="blue-color">
                                            {{ formatDecimal(solicitacaoEmbarque.saldoEmbarque, 2, 2) }} kg
                                        </span>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import ListarPedidos from "./EmbarqueListarPedidos.vue";
import TransportadoraList from "@/components/cadastro/TransportadoraList.vue";

export default {
    components: { ListarPedidos, TransportadoraList},
    emits: ["atualizarSolicitacoes"],
    props: {
        solicitacaoEmbarque: {
            type: Object,
            required: true
        }
    },
	data() {
		return {
            
		}
	},
	methods: {
        atualizarSolicitacoes(){
            this.$emit("atualizarSolicitacoes");
        },
        formatDateCustom(data, format){
            return Formatacao.formatDateCustom(data, format);
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        }
	}
}
</script>

<style scoped>
</style>
