import axios from "axios";
import ApiUrl from '../../config/ApiUrl';

class FaturamentoService {
    salvar(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        if(!!dados.id) {
            return axios.post(`${ApiUrl.vg}api/genesis-vg/v1/faturamento/atualizar`, dados, config);
        } else {
            return axios.post(`${ApiUrl.vg}api/genesis-vg/v1/faturamento/cadastrar`, dados, config);
        }
    }

    buscarPorId(id) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/faturamento/${ id }`);
    }

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'id,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/faturamento/buscar-com-paginacao`, { params });
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/faturamento/inativar/${ id }`);
    }
    
    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/faturamento/ativar/${ id }`);
    }

    quantidadeTotalFaturadaPorGestaoCI(gestaoFaturamentoPedidoId) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/faturamento/qtde-total-faturada-por-gestao-ci/${ gestaoFaturamentoPedidoId }`);
    }

    listarPedidosPendentes(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/faturamento/buscar-pedidos-pendentes`, dados, config);
    }

    listarTransportadoras(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/faturamento/buscar-transportadora`, dados, config);
    }

}


export default new FaturamentoService();