<template>
    <Dialog v-model:visible="visivel" :style="{width: '60%'}" :modal="true" @hide="fechar()" @show="init()">
        <template #header>
            <div class="flex align-items-center mt-2">
            </div> 
        </template>

        <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

        <div class="grid">
            <div class="col-12">
                <DataTable :value="produtos" showGridlines>
                    <Column header="Código Item">
                        <template #body="{ data }">
                            {{ data.codItem }}
                        </template>
                    </Column>
                    <Column header="Descrição">
                        <template #body="{ data }"> 
                            {{ data.codigoDescricao     }}
                        </template>
                    </Column>
                    <Column header="Estoque">
                        <template #body="{ data }">
                            {{ formatDecimal(data.saldoEstoque)  }}
                        </template>
                    </Column>
                    <Column header="Selecione" style="width:9rem;" class="centralizar-titulo-tabela">
                        <template #body="{ index }">
                            <div class="flex align-items-center justify-content-center">
                                <Button
                                    class="p-button-rounded p-button-info flex-none mr-2"
                                    icon="pi pi-pencil"
                                    aria-label="Editar"
                                    @click="selecionarProduto(index)"
                                ></Button>
                            </div>
                        </template>
                    </Column>

                </DataTable>
            </div>
        </div>
        <template #footer>
            <div class="flex justify-content-center">
                <Button
                    icon="pi pi-times"
                    label="Fechar"
                    class="p-button-danger ml-2"
                    @click="fechar()">
                </Button>
            </div>
        </template></Dialog>
</template>

<script>

import ProdutoService from '../../../service/ProdutoService';
import Formatacao from '../../../utilities/Formatacao';

    export default {
        name: "ModalProduto",
        components: {ProdutoService},
        emits: ["fechar"],
        props: {
            visivel: {
                type: Boolean,
                required: true
            },
            isEdicao: {
                type: Boolean,
                required: true
            },
            dados: {
                type: Object,
                required: true
            }            
        },

        data() {
            return {
                produtosFiltro: [],
                produtos: [],

                mensagem: { ativo: false, gravidade: 'info', conteudo: '' }
            }
        },

        methods: {

            init(){
                this.carregarProdutos();
            },
            
            limpar() {
                this.mensagem = { ativo: false };
                this.dados = {};
            },
          
            carregarProdutos() {
                const codigoGrupoProduto = this.dados["grupoProdutoId"];
                  
                ProdutoService.getProdutoPorGrupo(codigoGrupoProduto)
                    .then(({ data }) => {
                        if(!!data && data.length > 0) {
                            this.produtos = data.map(item => {
                                item["codigoDescricao"] = item.codItem + " - " + item.descItem;
                                return item;
                            })
                        }
                    })
                    .catch(error => {
                        this.produtos = [];

                        if(!!error && !!error.response) {
                            const { data } = error.response;
                            this.$toast.add({
                                severity:'error', 
                                summary: `Falha ao consultar produtos vinculados ao grupo de produto "${ codigoGrupoProduto }".`,
                                detail: data.message,
                                life: 5000
                            });
                        }
                    });
            },

            selecionarProduto(indice) {
                this.dados.produto=this.produtos[indice];
                
                this.fechar();
                
            },

            fechar() {
                this.$emit("fechar");
                this.limpar();
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },

            formatDateCustom(data, format){
                return Formatacao.formatDateCustom(data, format);
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../../assets/demo/styles/badges.scss';
    .fontUpper{
        text-transform: uppercase;
    }

    .label {
        text-align: right;
        padding: 0;
    }

    .campo {
        margin: 0.5rem 0;
        padding: 0 0 0 0.5rem;
        overflow: hidden;
    }
    .valor-campo-numerico{
        text-align: right !important; 
        font-weight: bold !important;        
        border-bottom: 1px solid rgba(0, 0, 0, 0.38) !important;
    }
    .sigla-campo-numerico{
        border-bottom: 1px solid rgba(0, 0, 0, 0.38) !important;
        text-align: left; 
        width: 70px; 
        font-weight: 600;
    }
</style>