<template>

    <div style="border: 1px solid #cecece">

      <DataTable :value="solicitacoesEmbarque.content"
              v-model:expandedRows="expandedRows"
              dataKey="id"
              @rowExpand="onRowExpand"
              @rowCollapse="onRowCollapse"
              responsiveLayout="stack"
              selectionMode="single"
              id="dataTable"
              @keyup.enter="selecionar"
              @change="selecionar"
              @click="selecionar"
              v-model:selection="solicitacaoEmbarque">
          <template #header>
              <div class="flex align-items-center justify-content-between">
                  <div style="width:90%">
                  </div>

                  <div class="flex align-items-center">
                      <Button v-if="isAdministracao || isLogistica" icon="pi pi-plus" title="Incluir" label="Incluir" @click="modalSolicitacaoEmbarque" class="p-button-outlined p-button-info mr-2" />
                      <Button icon="pi pi-plus" title="Expandir todos" @click="expandAll" class="p-button-outlined p-button-info mr-2" />
                      <Button icon="pi pi-minus" title="Recolher todos" @click="collapseAll" class="p-button-outlined p-button-info" />
                  </div>
              </div>
          </template>

          <template #empty>
              Nenhum origem.
          </template>

          <Column :expander="true" headerStyle="width: 3rem" id="exp"/>
          <Column field="codigoEstabelecimento" header="Estab." style="width:2rem;">
              <template #body="{ data }">
                  <strong :title="data.estabelecimento.nome">{{data.estabelecimento.codigo}}</strong>
              </template>
          </Column>

          <Column field="codigo" header="Cliente" style="width:12rem;">
            <template #body="{ data }">
              <span :title="data.cliente.cnpj">{{data.cliente.codigo}} - {{data.cliente.nome}}</span>
            </template>
          </Column>

          <Column field="destino" header="Destino" style="width:12rem;">
            <template #body="{ data }">
              <span>{{data.destino.nome}}/{{data.destino.uf}}</span>
            </template>
          </Column>

          <Column field="tipoProduto.nome" header="Produto"/>

          <Column field="id" header="SE" class="centralizar-titulo-tabela"/>

          <Column field="inicioEmbarque" header="Inicio Embarque" class="centralizar-titulo-tabela">
              <template #body="{ data , field }">
                {{ formatDateCustom(data[field], "DD/MM/YYYY") }}
              </template>
          </Column>

          <Column field="cota" header="Cotas" class="centralizar-titulo-tabela" style="width: 7rem;">
              <template #body="{ data }">
                <strong :class="'product-badge status-' + corStatusCota(data)">
                  {{data.cotaRealizada}}/{{data.cotaLiberada}}
                </strong>
              </template>
          </Column>

          <Column field="quantidade" header="Total Pedido" class="alinhar-direita-titulo-tabela">
              <template #body="{ data , field }">
                  {{ formatDecimal(data[field], 2, 2) +' kg'}}
              </template>
          </Column>

          <Column field="totalEmbarque" header="Total Embarque" class="alinhar-direita-titulo-tabela">
            <template #body="{ data , field }">
              {{ formatDecimal(data[field], 2, 2) +' kg' }}
            </template>
          </Column>

          <Column field="saldoEmbarque" header="Saldo Embarque" class="alinhar-direita-titulo-tabela">
            <template #body="{ data , field }">
              {{ formatDecimal(data[field], 2, 2) +' kg' }}
            </template>
          </Column>

          <Column v-if="isAdministracao || isLogistica" header="Ações" style="width:1rem;" >
              <template #body="{ data, index }">
                <Button type="button" class="p-button-rounded p-button-info mr-2 mb-2" icon="pi pi-ellipsis-v" @click="toggle($event,data,index)" aria-haspopup="true" aria-controls="overlay_menu" />
                <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
              </template>
          </Column>

          <template #expansion="row" >
            <DetalheSolicitacaoEmbarque :solicitacaoEmbarque="row.data" @atualizarSolicitacoes="buscaSolicitacaoEmbarque()"/>      
          </template>
      </DataTable>

    </div>

    <Dialog v-model:visible="excluirSolicitacaoDialog" :style="{ width: '300px' }" header="Alerta" :modal="true">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>Tem certeza que deseja <strong>Excluir</strong> a solicitação de embarque {{ ind }}?</span>
      </div>
      <template #footer>
        <Button label="Não" icon="pi pi-times" class="p-button-text" @click="excluirSolicitacaoDialog = false" />
        <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="excluirSolicitacaoEmbarque(ind)" />
      </template>
    </Dialog>

  <Paginator
      :rows="filtros.size"
      :totalRecords="totalRegistro"
      :rowsPerPageOptions="[1, 5, 10]"
      @page="onPage"/>

   <ModalSolicitacaoEmbarque
       :solicitacao="solicitacaoEmbarque"
       :visivel="modalSolicitacaoEmbarqueDialog"
       @fechar="fechar"
     />

    <ModalManutencaoValorFrete
        :solicitacaoEmbarque="solicitacaoEmbarque"
        :solicitacaoEmbarqueTransportadora="solicitacaoEmbarque.transportadoras"
        :visivel="manutencaoValorFreteDialog"
        @fechar="fechar"
     />

    <ModalHistoricoAjusteFrete
        :solicitacaoEmbarque="solicitacaoEmbarque"
        :solicitacaoEmbarqueTransportadora="solicitacaoEmbarque.transportadoras"
        :visivel="historicoValorFreteDialog"
        @fechar="fechar"
    />

    <ModalConfiguracaoCota
        :solicitacaoEmbarque="solicitacaoEmbarque"
        :cotas="solicitacaoEmbarque.cotas"
        :visivel="configuracaoCotaDialog"
        @fechar="fechar"
    />

    <ModalHistoricoGenerico
        codigoPagina="SOLICITACAO_EMBARQUE"
        :id="solicitacaoEmbarque.id"
        :descricao="descricaoHistoricoDialog"
        :visivel="historicoSolicitacaoEmbarque"
        @fechar="fechar"
    />
</template>

<script>
import DetalheSolicitacaoEmbarque from "@/components/cadastro/embarque/DetalheSolicitacaoEmbarque.vue";
import ModalHistoricoGenerico from "@/components/ModalHistoricoGenerico.vue";
import Formatacao from '../../../utilities/Formatacao';
import SolicitacaoEmbarqueService from "@/service/SolicitacaoEmbarqueService";
import ListarPedidos from "./EmbarqueListarPedidos.vue";
import TransportadoraList from "@/components/cadastro/TransportadoraList.vue";
import ModalSolicitacaoEmbarque from "@/components/cadastro/embarque/ModalSolicitacaoEmbarque.vue";
import ModalManutencaoValorFrete from "@/components/cadastro/embarque/ModalManutencaoValorFrete.vue";
import ModalHistoricoAjusteFrete from "@/components/cadastro/embarque/ModalHistoricoAjusteFrete.vue";
import ModalConfiguracaoCota from "@/components/cadastro/embarque/ModalConfiguracaoCota.vue";
import AjusteCotaService from "@/service/AjusteCotaService";
import {ref} from "vue";

export default {
    components: {ModalManutencaoValorFrete,
      ListarPedidos,TransportadoraList,
      ModalSolicitacaoEmbarque,
      ModalConfiguracaoCota,
      AjusteCotaService,
      ModalHistoricoAjusteFrete,
      ModalHistoricoGenerico,
      DetalheSolicitacaoEmbarque},
    props: {
      filter: { type: Object, required: true },
      visivel: { type: Boolean },
      selectedItem: { type: Object },
      aba: { type: Number }
    },
    watch: {
      filter(filtro) {
        if(!!filtro) {
          this.buscaSolicitacaoEmbarque();
        }
      },
      selectedItem(t){
        this.solicitacaoEmbarque = t;
      }

    },

    data() {
        return {
            ind: null,
            solicitacoesEmbarque: {},
            solicitacaoEmbarque: {},
            expandedRows: [],
            filterAtivo: {},
            filtros: {},
            totalRegistro: 0,
            filtroAvancado: false,
            split: true,
            modalSolicitacaoEmbarqueDialog: false,
            excluirSolicitacaoDialog: false,
            manutencaoValorFreteDialog: false,
            historicoValorFreteDialog: false,
            configuracaoCotaDialog: false,
            historicoSolicitacaoEmbarque: false,
            items: ref([
              {
                label: 'Escolha...',
                items: [
                  {label:'Editar Solicitação',icon:'pi pi-pencil',tag:1, command: () => this.editarSolicitacao(this.selectedItem.id,0) },
                  {label:'Cancelar Solicitação',icon:'pi pi-times',tag:1, command: () => this.editarSolicitacao(this.selectedItem.id,1) },
                  {label:'Histórico Solicitação',icon:'pi pi-list',tag:2, command: () => this.editarSolicitacao(this.selectedItem.id,2) },
                  {label:'Ajuste de Cotas',icon:'pi pi-save',tag:3, command: () => this.editarSolicitacao(this.selectedItem.id,3) },
                  {separator:true},
                  {label:'Alterar Valor Frete',icon:'pi pi-upload',tag:4, command: () => this.editarSolicitacao(this.selectedItem.id,4) },
                  {label:'Histórico Preço Frete',icon:'pi pi-save',tag:5, command: () => this.editarSolicitacao(this.selectedItem.id,5) }
                ],
              }
            ]),
            historicoDialog: false,
            descricaoHistoricoDialog: null,
            tabViewActiveIndex: 0,
            transportadoras: [],
            valorFrete: 0,
            classificacao: [
              { name: "CLIENTE", descricao: "Cliente" },
              { name: "PROPRIA", descricao: "Própria" },
            ],
            isAdministracao: false,
            isLogistica: false,
            isOperacao: false
        }
    },

    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      }
    },

  created() {
      this.abrirFiltroAvancado();

    },

  updated() {
      this.atualizaPage();
      this.isAdministracao = this.$store.state.auth.user.isAdministracao;
      this.isLogistica = this.$store.state.auth.user.isLogistica;
      this.isOperacao = this.$store.state.auth.user.isOperacao;

    },

  methods: {
        buscaSolicitacaoEmbarque(sv){
            if(this.aba != 0 || sv == false){return;}
            this.filter.page = this.filtros.page;
            this.filter.size = this.filtros.size;
            this.filter.sort = this.filtros.sort;

          SolicitacaoEmbarqueService.buscarComPaginacao(this.filter)
                .then(({data}) => {
                  if (!!data) {
                      const dados = data.content.map(item => {
                          item.valorFreteFormatado = this.formata(item.valorFrete);
                          for(let c of this.classificacao) {
                            if (item.classificacao==c.name) {
                              item.descricaoClassificacao = c.descricao;
                            }
                          }
                          return item;
                      });
                      this.solicitacoesEmbarque = data;
                      this.solicitacoesEmbarque.content = dados;
                  }
                })
                .catch(error => {
                  const toast = {
                    severity: 'error',
                    summary: `Falha ao buscar a solicitação de embarque.`,
                    detail: error,
                    life: 7500
                  }

                  if (!!error && !!error.response && !!error.response.data) {
                    const {message} = error.response.data;

                    if (!!message) {
                      toast["detail"] = message.replace("produtor", "cliente");
                    }
                  }

                  this.$toast.add(toast);
                });
          },

        abrirFiltroAvancado() {
          this.filtros = {};
          this.filtros["size"] = 10;
          this.filtros["page"] = 1;
          this.filtros["sort"] = 'codigoEstabelecimento,asc';
        },

        atualizaPage(){
          if(!!this.solicitacoesEmbarque.pageable && !!this.solicitacoesEmbarque.totalPages) {
            const qtdRegistros = this.solicitacoesEmbarque.pageable.pageSize;
            this.totalRegistro = this.solicitacoesEmbarque.totalPages * qtdRegistros;
          }
        },

        modalSolicitacaoEmbarque(){
          this.solicitacaoEmbarque = {};
          this.buscaSolicitacaoEmbarque(false);
          this.modalSolicitacaoEmbarqueDialog=true;
        },

        fechar(save){
          this.modalSolicitacaoEmbarqueDialog=false;
          this.manutencaoValorFreteDialog=false;
          this.configuracaoCotaDialog=false;
          this.historicoValorFreteDialog=false;
          this.historicoSolicitacaoEmbarque=false;
          this.excluirSolicitacaoDialog = false;
          this.buscaSolicitacaoEmbarque(save);
        },

        editarSolicitacao(id,tag){
            SolicitacaoEmbarqueService.buscarPorId(id)
                .then(({data}) => {
                  if (!!data) {
                    this.solicitacaoEmbarque = data;

                    if (tag==0) {
                      this.modalSolicitacaoEmbarqueDialog = true;
                    } else if (tag==2){
                      this.descricaoHistoricoDialog = "Solicitação de Embarque: "+ id;
                      this.historicoSolicitacaoEmbarque = true;
                    } else if (tag==3){
                      let dados = data.cotas;
                      let cotas = [];
                      for(let d of dados){
                        if (!d.dataInativacao){
                          cotas.push(d);
                        }
                      }
                      this.solicitacaoEmbarque.cota = data.cota;
                      this.solicitacaoEmbarque["cotas"] = cotas;
                      this.configuracaoCotaDialog = true;
                    } else if (tag==4) {
                      this.manutencaoValorFreteDialog = true;
                    } else if (tag==5) {
                      this.historicoValorFreteDialog = true;
                    }
                  }
                })

          if (tag==1) {
            this.ind = id;
            this.excluirSolicitacaoDialog = true;
          }
        },

        selecionar(index){
          this.split=false;
        },

        onPage(event) {
          this.filtros["page"] = event.page + 1;
          this.filtros["size"] = event.rows;
          this.buscaSolicitacaoEmbarque();
        },

        sort(event) {
          const sortField = event.sortField;
          const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
          this.filtros["sort"] = `${ sortField },${sortOrder}`;
        },

    excluirSolicitacaoEmbarque(indice){
          SolicitacaoEmbarqueService.inativar(indice)
              .then(({data}) => {

                this.$toast.add({
                  severity:'success',
                  detail:`A solicitação de embarque inativada com sucesso!`,
                  life: 3000
                });
                this.buscaSolicitacaoEmbarque(true);
              })
              .catch(error => {
                this.$toast.add({
                  severity:'warn',
                  detail:`Falha ao inativar a solicitação de embarque! \n` + error.response.data.exception ,
                  life: 5000
                });
              });
          this.excluirSolicitacaoDialog = false;
        },

        visualizarHistorico(solicitacaoEmbarque){
            this.solicitacaoEmbarque = solicitacaoEmbarque;
            this.solicitacaoEmbarqueDialogHistorico = true;
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },

        formatDateCustom(data, format){
            return Formatacao.formatDateCustom(data, format);
        },

        formata(valor){
          return "R$ " + Formatacao.formatDecimal(valor,2,2) + " / TON";
        },

        reloadAba(indice){
          this.transportadoras = Object.assign({},this.solicitacaoEmbarque.transportadoras);
        },

        onRowExpand(event) {
          this.solicitacaoEmbarque = event.data;
        },
        onRowCollapse(event) {
        },
        expandAll() {
            this.expandedRows = this.solicitacoesEmbarque.content;
        },
        collapseAll() {
            this.expandedRows = [];
        },
        corStatusCota(data){
            if(data.cotaRealizada == 0 && data.cotaRealizada < data.cotaLiberada){
                return 'instock';
            }else
            if(data.cotaRealizada > 0 && data.cotaRealizada < data.cotaLiberada){
              return 'lowstock';
            }else
            if(data.cotaRealizada >= data.cotaLiberada){                
                return 'outofstock';
            }
        }
    }
}
</script>
<script setup>
import {ref} from "vue";

const menu = ref();
const selectedItem= ref();

const toggle = (event,data,index) => {
  menu.value.toggle(event);
  selectedItem.value = data;
};

</script>

<style>    
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

    .alinhar-direita-titulo-tabela {
        text-align: right !important;
    }

    .alinhar-direita-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>


<style scoped>
    .borda-personalizada {
        border: 1px solid #CCC;
        border-radius: 3px;
        padding: 1rem;
    }
</style>

<style scoped lang="scss">
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

    @import '../../../assets/demo/styles/badges.scss';
    .fontUpper{
        text-transform: uppercase;
    }
</style>
