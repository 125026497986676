<template>
  <Dialog v-model:visible="visivel" :style="{width: '70%', margin: '17%'}" :modal="true" @hide="fechar()" @show="init()">
    <template #header>
      <div class="flex align-items-center">
        <img src="layout/images/truck-timer.png" alt="" style="font-size: 1.4rem" class="mr-2">
        <h3>
          Históricos de Preço de Frete
        </h3>
      </div>
    </template>

  <div class="grid p-fluid formgrid align-items-center" >

    <div class="col-12 lg:col-1">
      <div class="field">
        <label><strong>SE</strong></label>
        <InputNumber disabled v-model="solicitacaoEmbarque.id" class="w-full" :min="0" :max="999999"/>
      </div>
    </div>

    <div class="col-12 lg:col-2">
      <div class="field">
        <label><strong>Local de Embarque</strong></label>
        <AutoComplete
            :dropdown="true"
            class="w-full"
            field="codigoNomeFantasia"
            placeholder="Todos"
            v-model="estabelecimentoSelecionado"
            :forceSelection="true"
            :suggestions="estabelecimentosFiltro"
            @complete="carregarEstabelecimentos($event)"
            @item-select="selecionarEstabelecimento()"/>
      </div>
    </div>

    <div class="col-12 lg:col-2">
      <div class="field">
        <label><strong>Destino</strong></label>
        <AutoComplete
            field="label"
            class="w-full"
            :dropdown="true"
            @complete="buscarDestinos($event)"
            placeholder="Todos"
            v-model="destinoSelecionado"
            :suggestions="destinosFiltrados"
            @item-select="selecionarDestino"
            :forceSelection="true"
        />
      </div>
    </div>

    <div class="col-12 lg:col-2">
      <div class="field">
        <label><strong>Produto</strong></label>
        <AutoComplete
            field="nome"
            class="w-full"
            :dropdown="true"
            @complete="buscarProdutos($event)"
            placeholder="Todos"
            v-model="produtoSelecionado"
            :suggestions="produtosFiltrados"
            @item-select="selecionarProduto"
            :forceSelection="true"/>
      </div>
    </div>

    <div class="col-12 lg:col-3">
      <div class="field">
        <label><strong>Período</strong></label>
        <div class="flex align-items-center">
          <div class="overflow-hidden">
            <Calendar
                class="w-full"
                v-model="periodoInicioSelecionado"
                autocomplete="off"
                dateFormat="dd/mm/y"
                :maxDate="periodoFimSelecionado"
                @dateSelect="selecionarPeriodoInicio()"
            />
          </div>
          <strong class="ml-2 mr-2">à</strong>
          <div class="overflow-hidden">
            <div class="p-inputgroup">
              <Calendar
                  class="w-full"
                  v-model="periodoFimSelecionado"
                  autocomplete="off"
                  dateFormat="dd/mm/y"
                  :minDate="periodoInicioSelecionado"
                  :maxDate="new Date()"
                  @dateSelect="selecionarPeriodoFim()"
              />
              <Button
                  icon="pi pi-trash"
                  style="border-radius: 0 10% 10% 0;"
                  @click="limparPeriodoModal()"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 lg:col-2" >
      <div class="p-inputgroup">
        <Button
            label="Filtrar"
            icon="pi pi-filter"
            class="p-button-outlined p-button-info"
            style="width:110px"
            @click="filtrar"
        ></Button>

        <Button
            class="p-button-outlined p-button-info"
            icon="pi pi-filter-slash"
            title="Limpar Filtro"
            @click="limparFiltro"
        ></Button>
      </div>
    </div>

    <DataTable style="width:99%"
        @sort="sort($event)"
        showGridlines
        selectionMode="single"
        responsiveLayout="stack"
        :value="dados.content"
        v-model:selection="dadoSelecionado"
        @row-select="selecionar">

      <template #empty>
        Nenhum registro encontrado.
      </template>

      <template #loading>
        Carregando... Por favor, aguarde.
      </template>

      <Column field="dataCadastro" header="Data/Hora" sortable>
      <template #body="{data,field}">
        {{formatDateCustom(data[field],'DD/MM/YYYY HH:mm:ss')}}
      </template>
      </Column>

      <Column field="solicitacaoEmbarqueId" header="SE"  sortable v-if="false"/>
      <Column field="codigoEstabelecimento" header="Origem"  sortable/>
      <Column field="destino.id" header="Destino"  sortable/>
      <Column field="tipoProduto.nome" header="Produto"  sortable/>

      <Column field="valorFreteAnterior" header="Valor Anterior (R$)"  sortable>
      <template #body="{data,field}">
        {{formatDecimal(data[field],2,2)}}
      </template>
      </Column>

      <Column field="valorFreteNovo" header="Novo Valor (R$)"  sortable>
      <template #body="{data,field}">
        {{formatDecimal(data[field],2,2)}}
      </template>
      </Column>

      <Column field="justificativa.descricao" header="Justificativa"  sortable/>
      <Column field="usuario" header="Usuário"  sortable/>

    </DataTable>
  </div>

    <Paginator
        :rows="filtros.size"
        :totalRecords="totalRegistro"
        :rowsPerPageOptions="[1, 5, 10]"
        @page="onPage"/>

    <center>
      <hr>
    <Button label="Fechar" icon="pi pi-times" @click="fechar" class="p-button p-component p-button-danger"/>
    </center>
  </Dialog>
</template>

<script>
import MunicipioService from '../../../service/MunicipioService.js';
import EmpresaCompraVendaFiltro from "@/components/cadastro/EmpresaCompraVendaFiltro.vue";
import Formatacao from '../../../utilities/Formatacao';
import ContratoZoom from '../../zoom/ContratoZoom.vue';
import EmbarqueTabelaOrigem from './EmbarqueTabelaOrigem.vue';
import EstabelecimentoService from "@/service/EstabelecimentoService";
import PedidoForm from "@/components/cadastro/pedido/PedidoForm.vue";
import FaturamentoForm from "@/components/cadastro/pedido/FaturamentoForm.vue";
import TransportadoraZoom from "@/components/zoom/TransportadoraZoom.vue";
import TipoProdutoService from "@/service/TipoProdutoService";
import AjusteFreteService from "@/service/AjusteFreteService";

export default {
    name: "ModalHistoricoAjusteFrete",
    components: {TransportadoraZoom, FaturamentoForm, PedidoForm, ContratoZoom, EmbarqueTabelaOrigem, EmpresaCompraVendaFiltro },
    props: {
      visivel: { type: Boolean },
      solicitacaoEmbarque: { type: Object, required: true}
    },
    data() {
        return {
            dados: [],
            dadoSelecionado: {},
            estabelecimentoSelecionado: null,
            estabelecimentosFiltro: [],
            destinosFiltrados: [],
            destinoSelecionado: null,
            produtosFiltrados: [],
            produtoSelecionado: null,
            solicitacaoSelecionada: null,
            periodoInicioSelecionado: null,
            periodoFimSelecionado: null,

            filter: {},

            filtros: {},
            totalRegistro: 0,
            filtroAvancado: false

        }
    },

  updated() {
    if (!!this.dados.pageable && !!this.dados.totalPages) {
      const qtdRegistros = this.dados.pageable.pageSize;
      this.totalRegistro = this.dados.totalPages * qtdRegistros;
    }
  },

  methods: {
    init() {
      this.abrirFiltroAvancado();
    },
    fechar() {
      this.dados = [];
      this.limparFiltro();
      this.$emit("fechar",true);
      
    },

    carregarDados(filtro) {
      filtro.page = this.filtros.page;
      filtro.size = this.filtros.size;
      filtro.sort = this.filtros.sort;
      filtro.solicitacaoEmbarqueId = this.solicitacaoEmbarque.id;

      AjusteFreteService.buscarComPaginacao(filtro)
          .then(({data}) => {
              if(!!data){
                this.dados = data;
                this.totalRegistros = data.totalElements;

              }
          })
          .catch(error => {
            const mensagem = {
              gravidade: "error", duracao: 7500, fechar: true,
              conteudo: "Ocorreu uma falha não mapeada ao consultar ajustes de frete."
            }

            if (!!error.response && !!error.response.data && !!error.response.data.message) {
              mensagem["conteudo"] = error.response.data.message;
            }

            this.mensagens.push(mensagem);
          });
    },

    carregarEstabelecimentos(event) {
      const codigoOuNomeFantasia = !event.query.trim().length ? undefined : event.query;
      const listaEstabelecimentos = this.codEstabelecimentosOrigem;
      const modFrete = this.modalidadeFrete;

      EstabelecimentoService.getTodosEstabelecimentos() //codigoOuNomeFantasia, re: true, listaEstabelecimentos, modalidadeFrete: modFrete})
          .then(({ data }) => {
            if(data) {
              this.estabelecimentosFiltro = data.map(item => {
                item["codigoNomeFantasia"] = item.codigo + " - " + item.nomeFantasia;
                return item;
              });
            }
          })
          .catch(error => {
            this.estabelecimentosFiltro = [];
          });
    },

    selecionarEstabelecimento(){
      this.filter.codigoEstabelecimento = this.estabelecimentoSelecionado.codigo;
    },

    buscarDestinos(event){
      const codigoOuDescricao = !event.query.trim().length ? 'a' : event.query;
      MunicipioService.buscarPorDescricao(codigoOuDescricao)
          .then(({ data }) => {
            if(!!data && data.length > 0) {
              this.destinosFiltrados = data.map(municipio => {
                municipio["label"] = `${ municipio.nome } - ${ municipio.uf }`;
                return municipio;
              });
            } else{
              this.destinosFiltrados =[];
            }
          })
          .catch(error => {
            this.destinosFiltrados = [];
          });
    },

    selecionarDestino(){
      this.filter.destinoId = this.destinoSelecionado.id;
    },

    buscarProdutos(event){
      const dados = !event.query.trim().length ? null : event.query;
      TipoProdutoService.getAutoComplete(dados)
          .then(({ data }) => {
            let predefinidos = [];
            data.find(item => {
                predefinidos.push(item);
            });
            this.produtosFiltrados = predefinidos;
          })
          .catch(error => {
            this.produtosFiltrados = [];
          });
    },

    selecionarProduto(){
      this.filter.tipoProdutoId = this.produtoSelecionado.id;
      this.filter.nomeTipoProduto = this.produtoSelecionado.nome;
    },

    selecionarSolicitacao(){
      if (!!this.solicitacaoSelecionada) this.filter.solicitacaoEmbarqueId = this.solicitacaoSelecionada;
    },

    selecionarPeriodoInicio(){
      this.filter.periodoInicio = this.periodoInicioSelecionado;
    },

    selecionarPeriodoFim(){
      this.filter.periodoFim = this.periodoFimSelecionado;
    },

    filtrar(){
      this.selecionarSolicitacao();
      this.selecionarPeriodoInicio();
      this.selecionarPeriodoFim();
      this.atualizar();
    },

    limparFiltro(){
      this.statusSelecionado= null;
      this.clienteSelecionado= 0;
      this.estabelecimentoSelecionado= null;
      this.destinoSelecionado= null;
      this.produtoSelecionado= null;
      this.transportadoraSelecionada= 0;
      this.pedidoSelecionado= null;
      this.periodoInicioSelecionado= null;
      this.periodoFimSelecionado= null;
      this.notaSelecionada= null;
      this.contratoInicioSelecionado= null;
      this.contratoFimSelecionado= null;
      
      this.filter = {};
      this.atualizar();
    },

      abrirFiltroAvancado() {
        this.filtros = {};
        this.filtros["size"] = 10;
        this.filtros["page"] = 1;
        this.filtros["sort"] = 'dataCadastro,desc';

      },

    limparPeriodoModal(){
      this.periodoInicioSelecionado= null;
      this.periodoFimSelecionado= null;
    },

    atualizar(){
      const filtro = {};
      filtro["codigoEstabelecimento"] = !this.filter.codigoEstabelecimento ? null : this.filter.codigoEstabelecimento;
      filtro["solicitacaoEmbarqueId"] = !this.filter.solicitacaoEmbarqueId ? null : this.filter.solicitacaoEmbarqueId;
      filtro["periodoInicio"] = !this.filter.periodoInicio ? null : Formatacao.formatDateCustom(this.filter.periodoInicio, "YYYY-MM-DD");
      filtro["periodoFim"] = !this.filter.periodoFim ? null : Formatacao.formatDateCustom(this.filter.periodoFim, "YYYY-MM-DD");
      filtro["destinoId"] = !this.filter.destinoId ? null : this.filter.destinoId;
      filtro["tipoProdutoId"] = !this.filter.tipoProdutoId ? null : this.filter.tipoProdutoId;
      filtro["nomeTipoProduto"] = !this.filter.nomeTipoProduto ? null : this.filter.nomeTipoProduto;

      this.carregarDados(filtro);
    },

    formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(data, format){
            return Formatacao.formatDateCustom(data, format);
        },
        onPage(event) {
          this.filtros["page"] = event.page + 1;
          this.filtros["size"] = event.rows;
          this.carregarDados();
        },

        sort(event) {
          const sortField = event.sortField;
          const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
          this.filtros["sort"] = `${ sortField },${sortOrder}`;
          this.carregarDados();
        }

    }

}
</script>

<style scoped>

    .valor-campo-numerico{
        text-align: right; 
        font-weight: bold;
    }
    .sigla-campo-numerico{
        text-align: left; 
    }
</style>