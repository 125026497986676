<template>
    <Dialog v-model:visible="visivel" :style="{width: '80%', margin: '10%'}" :modal="true" @hide="fechar()" @show="init()">
        <template #header>
            <div class="flex align-items-center mt-2">
                <img src="layout/images/truck.png" alt="" style="font-size: 1.4rem" class="mr-2">
                <h4 style="margin:0;">{{isEdicao ? 'Edição da ' : 'Nova'}} Solicitação de Embarque</h4>
            </div> 
        </template>

        <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>


        <div class="grid p-fluid formgrid">
            
              <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">

                <div class="field">
                    <label for="data">
                        <strong>Estabelecimento</strong>
                    </label>
                    <AutoComplete 
                        :dropdown="true"
                        class="w-full"
                        field="codigoNomeFantasia"
                        placeholder="Informe o código ou o nome fantasia."
                        v-model="estabelecimentoSelecionado"
                        :forceSelection="true"
                        :disabled="true"
                        :suggestions="estabelecimentosFiltro"
                        @complete="carregarEstabelecimentos($event)"
                        @item-select="selecionarEstabelecimento()"
                        :class="{ 'w-full p-invalid': campoInvalido.estabelecimento }"/>
                </div>
            </div>

            <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="pedidoId">
                        <strong>Pedido</strong>
                    </label>
                    <div class="p-inputgroup">
                        <InputText class="w-full" v-model="dados.pedidoId" disabled />
                    </div>
                </div>
            </div>

            <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
              <div class="field">
                <label for="quantidade">
                  <strong>Saldo disponível</strong>
                </label>
                <div class="p-inputgroup">
                  <InputNumber class="w-full" v-model="saldoEmbarque" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999" />
                  <span class="p-inputgroup-addon" style="height: 30px; background: #eaeaea !important">{{ siglaUnidadeMedida }}</span>
                </div>
              </div>
            </div>

            <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="quantidade">
                        <strong>Quantidade <span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <div class="p-inputgroup">
                        <InputNumber 
                            :class="{ 'w-full p-invalid': campoInvalido.quantidade }"
                            v-model="dados.quantidade" 
                            mode="decimal" 
                            locale="pt-BR" 
                            :minFractionDigits="2" 
                            :maxFractionDigits="2" 
                            :min="0" 
                            :max="999999999" />
                        <span class="p-inputgroup-addon" style="height: 30px;">{{ siglaUnidadeMedida }}</span>    
                    </div>                    
                </div>
            </div>

            <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label for="unidade">
                        <strong>Cota <span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputNumber class="w-full" v-model="dados.cota" mode="decimal" locale="pt-BR" :minFractionDigits="0" :maxFractionDigits="0" :min="0" :max="9999" />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label for="preco_unitario">
                        <strong>Classificação <span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete
                        placeholder="Selecione..." 
                        dropdown
                        v-model="classificacaoSelecionada"
                        field="descricao"
                        class="w-full"
                        id="classificacao"
                        :suggestions="classificacaoFiltro"
                        :forceSelection="true"
                        @complete="buscarClassificacoes"
                        @item-select="selecionarClassificacao()"/>
                </div>
            </div>

            <div class="col-12 sm:col-8 md:col-8 lg:col-8 xl:col-8">
              <div class="field">
                <label for="nome">
                  <strong>Nome Classificadora</strong>
                </label>
                <div class="p-inputgroup">
                  <InputText class="w-full" v-model="dados.nomeClassificacao" />
                </div>
              </div>
            </div>

        </div>

        <Fieldset legend="Dados de transporte" class="card">
            <div class="grid p-fluid formgrid align-items-center">
                <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                    <div class="field">
                        <label for="data">
                            <strong>Transportadora</strong>
                        </label>

                        <AutoComplete
                            field="label"
                            class="w-full"
                            :dropdown="true"
                            id="filtroTransportadora"
                            placeholder="Selecionar"
                            v-model="transportadoraSelecionada"
                            :suggestions="transportadorasFiltradas"
                            @complete="buscarTransportadoras($event)"
                            @keyup.enter="buscarTransportadoras($event)"
                            @item-select="carregarCidade()">
                        </AutoComplete>
                    </div>
                </div>

                <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                    <div class="field">
                        <label for="data">
                            <strong>Cidade</strong>
                        </label>
                        <InputText v-model="cidadeTransportadora" disabled/>
                    </div>
                </div>

                <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                    <div class="field">
                        <label for="data">
                            <strong>Valor Frete ( R$ / TON)</strong>
                        </label>
                        <InputNumber class="w-full" v-model="valorFrete" mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999" />
                    </div>
                </div>

                <div class="col-12 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                    <div class="field">
                        <label for="data">
                            <strong>Observação</strong>
                        </label>
                        <InputText v-model="observacao"/>
                    </div>
                </div>

                <div class="mt-2">
                    <Button
                        icon="pi pi-plus"
                        label="Incluir"
                        class="p-button-info"
                        :disabled="!transportadoraSelecionada || !valorFrete"
                        @click="incluir()"
                    ></Button>
                </div>
            </div>

            <DataTable :value="dados.transportadoras"
                        responsiveLayout="stack" 
                        selectionMode="single"
                        dataKey="id"
                        showGridlines
                        v-model:selection="solicitacaoEmbarqueTransportadora">

                <template #empty>
                    Nenhum registro.
                </template>

                <Column field="dataCadastro" header="Data/Hora" headerStyle="width:10rem" class="pt-3 pb-3">
                    <template #body=" {data}">
                        {{ formatDateCustom(data.dataCadastro, 'DD/MM/YY HH:mm:ss') }}
                    </template>
                </Column>
                <Column field="nomeFantasia" header="Transportadora" headerStyle="width:20rem"></Column>
                <Column field="cnpj" header="CNPJ" headerStyle="width:9rem"></Column>
                <Column field="valorFrete" header="Valor Frete" headerStyle="width:8rem">
                    <template #body=" {data}">
                        {{ formatDecimal(data.valorFrete, 2, 2) }}
                    </template>
                </Column>
                <Column field="observacao" header="Observação"></Column>
            </DataTable>

        </Fieldset>

        <template #footer>
            <div class="flex justify-content-center">

                <Button id="bt1"
                    icon="pi pi-plus"
                    label="Incluir"
                    class="p-button-info"
                    @click="salvar()"
                ></Button>

                <Button id="bt2"
                    icon="pi pi-times"
                    label="Cancelar"
                    class="p-button-danger ml-2"
                    @click="fechar()"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
    import Formatacao from '../../../utilities/Formatacao';
    import TransportadoraService from '../../../service/TransportadoraService';
    import EstabelecimentoService from '../../../service/EstabelecimentoService';
    import PedidoService from "@/service/pedido/PedidoService";

    export default {
        name: "ModalNovaSolicitacaoEmbarque",
        components: {  },
        emits: ["salvar", "fechar"],
        props: {
            visivel: {
                type: Boolean,
                required: true
            },
            isEdicao: {
                type: Boolean,
                required: true
            },
            dados: {
                type: Object,
                required: true
            },

            gestao: {
                type: Object,
                required: false
            },

            saldoEmbarque: {
                  type: Number,
                  required: true
              },
            siglaUnidadeMedida: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                mensagem: { ativo: false, gravidade: 'info', conteudo: '' },
                disabledEstabelecimento: true,
                solicitacaoEmbarqueTransportadora: {},
                transportadoraSelecionada: null,
                transportadorasFiltradas: [],
                cidadeTransportadora: null,
                valorFrete: null,
                observacao: null,
                classificacaoSelecionada: {},
                classificacaoFiltro:[],
                classificacoes: [
                    { codigo: "CLIENTE", descricao: "Cliente" },
                    { codigo: "PROPRIA", descricao: "Própria" }
                ],
                estabelecimentoSelecionado: null,
                dadosTransporte: null,
                estabelecimentosFiltro: [],
                campoInvalido: {},
                pedido: {}
            }
        },

        methods: {

            init(){
                this.limparTransortadora();
                this.carregarClassificacao();
                this.carregarEstabelecimento();
                //this.montaPedido();
            },

            carregarClassificacao(){
                this.classificacaoSelecionada = this.classificacoes.find(item => { return item.codigo === this.dados.classificacao });
            },

            limpar() {
                this.mensagem = { ativo: false };
            },

            incluir(){
                const transportadora = {
                    id: null,
                    solicitacaoEmbarqueId: this.dados.id,
                    transportadoraId: this.transportadoraSelecionada.id,
                    nomeFantasia: this.transportadoraSelecionada.nomeFantasia,
                    cnpj: this.transportadoraSelecionada.cnpj,
                    valorFrete: this.valorFrete,
                    observacao: this.observacao,
                    dataCadastro: Formatacao.formatDateCustom(new Date(), 'YYYY-MM-DD HH:mm:ss')
                };

                this.dados.transportadoras.push(transportadora);
                this.limparTransortadora();
            },

            limparTransortadora(){
                this.transportadoraSelecionada = null;
                this.cidadeTransportadora = null;
                this.valorFrete = null;
                this.observacao = null;
            },

            salvar() {        
                if(!this.validarCampos()) return;
                if(!this.dados.pedido){
                  this.dados["pedido"] = this.pedido;
                  this.dados.pedido.saldoEmbarque = this.saldoEmbarque - this.dados.quantidade;
                }
                this.$emit("salvar", this.dados);
                this.limpar();
            },

            validarCampos() {

                if(this.dados.quantidade && this.dados.quantidade > this.saldoEmbarque){
                    const mensagem = "A quantidade informada não pode ser superior ao saldo disponível de "+ 
                        this.formatDecimal(this.saldoEmbarque, 2, 2) + " "+ this.siglaUnidadeMedida;

                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Atenção!',
                        detail: mensagem, 
                        life: 15000
                    });
                    return false;
                }


                let campos = [];

                if(!this.estabelecimentoSelecionado) {
                    campos.push("Estabelecimento");
                    this.campoInvalido.estabelecimento = true;
                }
                else {
                    this.campoInvalido.estabelecimento = false;
                }

                if(!this.dados.quantidade || this.dados.quantidade == 0) {
                    campos.push("Quantidade");
                    this.campoInvalido.quantidade = true;
                }
                else {
                    this.campoInvalido.quantidade = false;
                }

                if(this.dados.cota == null || this.dados.cota == 0){
                    campos.push("Cota");
                    this.campoInvalido.cota = true;
                }
                else {
                    this.campoInvalido.cota = false;
                }

                if(!this.classificacaoSelecionada) {
                    campos.push("Classificação");
                    this.campoInvalido.classificacao = true;
                }
                else {
                    this.campoInvalido.classificacao = false;
                }

                if(this.dados.transportadoras.length==0) {
                  campos.push("Dados de transporte");
                  this.campoInvalido.dadosTransporte = true;
                }
                else {
                  this.campoInvalido.dadosTransporte = false;
                }

                if(campos.length > 0) {
                    let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

                    if(campos.length > 1) {
                        mensagem = "Por favor, preencher os campos: ";
                        mensagem += campos.join(", ");

                        const indice = mensagem.lastIndexOf(", ");
                        mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                    }

                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Atenção!',
                        detail: mensagem, 
                        life: 15000
                    });
                }

                return campos.length === 0;
            },

            fechar() {
                this.$emit("fechar");
                this.limpar();
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },

            formatDateCustom(data, format){
                return Formatacao.formatDateCustom(data, format);
            },

            buscarClassificacoes(event) {
                if (!event.query.trim().length) {
                    this.classificacaoFiltro = [...this.classificacoes];
                } else {
                    this.classificacaoFiltro = this.classificacoes.filter((item) => {
                        return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            },

            carregarEstabelecimentos(event) {
                const codigoOuNomeFantasia = !event.query.trim().length ? undefined : event.query;
                EstabelecimentoService.pesquisarPorParametroUsuarioVsEstabelecimentoComUsuarioAutenticado({codigoOuNomeFantasia, re: true})
                    .then(({ data }) => {
                        if(data) {
                            this.estabelecimentosFiltro = data.map(item => {
                                item["codigoNomeFantasia"] = item.codigo + " - " + item.nomeFantasia;
                                return item;
                            });
                        }
                    })
                    .catch(error => {
                        this.estabelecimentosFiltro = [];
                    });
            },

            selecionarEstabelecimento(){
                if(this.estabelecimentoSelecionado == undefined) { 
                    this.dados.codigoEstabelecimento = null;
                    this.dados.nomeEstabelecimento = null;
                }else{
                    this.dados.codigoEstabelecimento = this.estabelecimentoSelecionado.codigo;
                    this.dados.nomeEstabelecimento = this.estabelecimentoSelecionado.nomeFantasia;
                }
            },

            selecionarClassificacao(){
                if(this.classificacaoSelecionada){
                    this.dados.classificacao = this.classificacaoSelecionada.codigo;
                }else{
                    this.dados.classificacao = null;
                }
            },
            
            async carregarEstabelecimento(){
                this.disabledEstabelecimento = this.dados.id != null;

                if(this.dados.codigoEstabelecimento != null) {
                    try {
                        const estabelecimento = await EstabelecimentoService.getEstabelecimentosPorCodigo(this.dados.codigoEstabelecimento);
                        if(!!estabelecimento.data) {
                            estabelecimento.data["codigoNomeFantasia"] = estabelecimento.data.codigo + " - " + estabelecimento.data.nomeFantasia;
                            this.estabelecimentoSelecionado = estabelecimento.data;
                            this.dados["nomeEstabelecimento"] = estabelecimento.data.nomeFantasia;
                        }
                    }
                    catch(error) {
                        if(!!error && !!error.response) {
                            const { data } = error.response;
                            this.$toast.add({
                                severity:'error', 
                                summary: 'Falha ao buscar estabelecimento.',
                                detail: data.message,
                                life: 5000
                            });
                        }
                    }

                }else{
                    this.estabelecimentoSelecionado = null;
                }
            },

            buscarTransportadoras(event) {  
                const descricao = !event.query.trim().length ? '' : event.query;
                TransportadoraService.getPorFiltro(descricao)
                    .then(({ data }) => {
                        if(!!data && data.length > 0) {
                            this.transportadorasFiltradas = data.map(transportadora => {
                                transportadora["label"] = `${ transportadora.nomeFantasia } - ${ transportadora.cnpj }`;
                                return transportadora;
                            });
                        } else{
                            this.transportadorasFiltradas = [];
                            this.cidadeTransportadora = null;
                        }
                    })
                    .catch(error => {
                        this.transportadorasFiltradas = [];
                        this.cidadeTransportadora = null;
                    });
            },

            montaPedido() {
              PedidoService.buscarPorId(this.dados.pedidoId)
                  .then(({data}) => {
                    this.pedido = data;
                  });
            },

            carregarCidade(){
                if(this.transportadoraSelecionada){
                    this.cidadeTransportadora = this.transportadoraSelecionada.municipio.nome +"/"+ 
                                                this.transportadoraSelecionada.municipio.uf;
                }else{
                    this.cidadeTransportadora = null;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../../assets/demo/styles/badges.scss';
    .fontUpper{
        text-transform: uppercase;
    }
</style>