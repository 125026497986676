<template>
    <Dialog v-model:visible="visivel" :style="{width: '85%', margin: '5%', maxWidth: '1100px'}" :modal="true" @hide="fechar()" @show="init()">
        <template #header>
            <div class="flex align-items-center mt-2">
                <i class="pi pi-file mr-2" style="font-size: 1.4rem;"></i>
                <h4 style="margin:0;">Pedido <span :class="'product-badge status-' + corMetodo(acao)"> {{acao}} </span></h4>
            </div> 
        </template>

        <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>


        <div class="grid">
            <Fieldset legend="Dados pedido">
                <div class="col-12">
                    <div class="grid align-items-center">

                        <div class="col-2 label" v-if="pedido.id != null">
                          <label><strong>Pedido Id:</strong></label>
                        </div>
                        <div :class="(isAdministracao || isComercial) && pedido.bloqueioCCV?'col-1 campo':'col-2 campo'" v-if="pedido.id != null">
                          <InputText class="w-full" v-model="pedido.id" maxlength="200" :disabled="true"/>
                        </div>

                        <div class="col-2 label" v-if="pedido.id != null && !isLogistica && !isOperacao && pedido.bloqueioCCV" :style="isAdministracao || isComercial?'margin-left:-10rem':'margin-left:0rem'">
                          <label><strong>Bloq.:</strong></label>
                        </div>
                        <div class="col-1 campo" v-if="pedido.id != null && !isLogistica && !isOperacao && pedido.bloqueioCCV" >
                          <Checkbox v-model="pedido.pedidoBloqueado" :binary="true" />
                        </div>

                        <div class="col-2 label" v-if="pedido.id != null" :style="(isAdministracao || isComercial) && pedido.bloqueioCCV?'margin-left:-3.5rem':'margin-left:0rem'">
                            <label> <strong>Autorizado Por:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                        <div class="col-2 campo" v-if="pedido.id != null">
                            <InputText class="w-full" :class="{ 'w-full p-invalid': campoInvalido.autorizadoPor }" v-model="pedido.autorizadoPor" maxlength="200"/>
                        </div>

                        <div class="col-2 label" v-if="pedido.id == null">
                          <label> <strong>Autorizado Por:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                        <div class="col-6 campo" v-if="pedido.id == null">
                          <InputText class="w-full" :class="{ 'w-full p-invalid': campoInvalido.autorizadoPor }" v-model="pedido.autorizadoPor" maxlength="200"/>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Data Autorização:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <Calendar class="w-full"
                                :class="{ 'p-invalid': campoInvalido.dataAutorizacao }"
                                v-model="pedido.dataAutorizacao"
                                id="dataAutorizacao"
                                dateFormat="dd/mm/yy"/>
                        </div>
                        
                        <div class="col-2 label">
                            <label> <strong>Emitente:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                        <div class="col-6 campo">
                            <ProdutorFiltro
                                :disabled="false"
                                :campoObrigatorio="true"
                                :selecionado="pedido.cliente"
                                :paginacao="clienteConsulta"
                                :invalid="campoInvalido.cliente"
                                @pesquisar="pesquisarCliente"
                                @selecionado="selecionarCliente"
                                />
                        </div>       
                        
                        <div class="col-2 label">
                            <label> <strong>CNPJ Cliente:</strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <InputText class="w-full" v-model="pedido.cliente.cnpj" disabled/>
                        </div>

                        <div class="col-2 label" v-if="pedido.modalidadeFrete=='CIF'">
                          <label> <strong>Regional Origem Preferencial:</strong> </label>
                        </div>
                        <div style="width:15%;margin-left:0.5%" v-if="pedido.modalidadeFrete=='CIF'">
                          <InputText class="w-full" v-model="pedido.regionalPreferencial" disabled/>
                        </div>
                        <div style="width:11%;margin-left:1%" v-if="pedido.modalidadeFrete=='CIF'">
                            <label> <strong>Orig. Mercadoria:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                        <div style="width:21%;margin-left:1.5%" v-if="pedido.modalidadeFrete=='CIF'">
                            <AutoComplete
                                :dropdown="true"
                                :disabled="isLogistica && pedido.modalidadeFrete=='FOB'"
                                class="w-full"
                                field="label"
                                placeholder="Informe o código ou o nome fantasia."
                                v-model="estabelecimentoSelecionado"
                                :forceSelection="true"
                                :suggestions="estabelecimentosFiltro"
                                @complete="carregarEstabelecimentos($event)"
                                @item-select="selecionarEstabelecimento()"
                                :class="{ 'w-full p-invalid': campoInvalido.origemMercadoria }"/>
                        </div>

                        <div class="col-2 label" v-if="pedido.modalidadeFrete=='FOB'">
                          <label> <strong>Origem Mercadoria:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                        <div class="col-6 campo" v-if="pedido.modalidadeFrete=='FOB'">
                          <AutoComplete
                              :dropdown="true"
                              :disabled="isLogistica && pedido.modalidadeFrete=='FOB'"
                              class="w-full"
                              field="label"
                              placeholder="Informe o código ou o nome fantasia."
                              v-model="estabelecimentoSelecionado"
                              :forceSelection="true"
                              :suggestions="estabelecimentosFiltro"
                              @complete="buscarEstabelecimentoDeOrigem($event)"
                              @item-select="selecionarEstabelecimento()"
                              :class="{ 'w-full p-invalid': campoInvalido.origemMercadoria }"/>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Destino Mercadoria:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <AutoComplete 
                                dropdown
                                placeholder="Selecione..." 
                                v-model="pedido.destinoMercadoria"
                                field="descricao"
                                id="destinoMercadoria"
                                class="w-full"
                                :class="{ 'p-invalid': campoInvalido.destinoMercadoria }"
                                :suggestions="destinoMercadoriaFiltro"
                                :forceSelection="true"
                                @complete="buscarDestinoMercadoria"
                            />
                        </div>
                        
                        <div class="col-2 label">
                            <label> <strong>Natureza Operação:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                        <div class="col-6 campo">
                            <NaturezaOperacaoZoom
                                codigo="codigo"
                                descricao="descricao"
                                :selecionado="pedido.naturezaOperacao"
                                :disabled="true"
                                :invalid="campoInvalido.naturezaOperacao"
                                :paginacao="naturezaOperacaoConsulta"
                                @pesquisar="pesquisarNaturezaOperacao"
                                @selecionado="selecionarNaturezaOperacao"
                            />
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Condição Pagto:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                        <div class="col-2 campo">
                          <InputText disabled class="w-full" v-model="pedido.descCondicaoPagto" maxlength="200"/>
                        </div>

                        
                        <div class="col-2 label">
                            <label> <strong>Nº Pedido Compra Cliente:</strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <InputText class="w-full" v-model="pedido.nroPedidoCompraCliente" maxlength="200"/>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Cód. Entrega:</strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <InputText class="w-full" v-model="pedido.codigoEntrega"/>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Cód. Entr. Remessa:</strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <InputText class="w-full" v-model="pedido.codigoEntregaRemessa"/>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>RCO:</strong> </label>
                        </div>
                        <div class="col-1 campo">
                            <Checkbox v-model="pedido.rco" :binary="true"/>
                        </div>
                        
                        <div class="col-2 label">
                            <label> <strong>Nome Abrev. Remessa:</strong> </label>
                        </div>
                        <div class="col-3 campo">
                            <InputText class="w-full" v-model="pedido.nomeAbrevRemessa" maxlength="200" />
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Parcela:</strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <InputText class="w-full" v-model="pedido.parcela"/>
                        </div>


                        
                        <div class="col-2 label">
                            <label> <strong>Saldo Disponível:</strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <div class="p-inputgroup">
                                <InputNumber class="w-full" v-model="pedido.saldoPedido" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                                <InputText :disabled="true" class="p-inputgroup-addon" v-model="siglaUnidadeMedida"/>
                            </div>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Saldo Utilizado:<span style="color:red;">&nbsp;*</span></strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <div class="p-inputgroup">
                                <InputNumber class="w-full" 
                                    id="saldoUtilizado"
                                    @blur="calcularSaldoReal($event)"
                                    :class="{ 'p-invalid': campoInvalido.saldoUtilizado }"
                                    v-model="pedido.saldoUtilizado" mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                                <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="siglaUnidadeMedida"/>
                            </div>
                        </div>



                        <div class="col-2 label">
                            <label> <strong>Saldo Real:</strong> </label>
                        </div>
                        <div class="col-2 campo">
                            <div class="p-inputgroup">
                                <InputNumber class="w-full" v-model="saldoReal" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                                <InputText :disabled="true" class="p-inputgroup-addon" v-model="siglaUnidadeMedida"/>
                            </div>
                        </div>

                        <div class="col-2 label">
                            <label> <strong>Observações NF:</strong> </label>
                        </div>
                        <div class="col-10 campo">
                            <Textarea class="w-full" id="observacaoNotaFiscal" v-model="pedido.observacaoNota" rows="4" @keydown="ajustaTA($event)"></Textarea>
                        </div>

                    </div>
                </div>
            </Fieldset>
        </div>

        <template #footer>
            <div class="flex justify-content-center">

                <Button
                    icon="pi pi-save"
                    label="Salvar"
                    class="p-button-info"
                    @click="salvar()"
                ></Button>

                <Button
                    icon="pi pi-times"
                    label="Cancelar"
                    class="p-button-danger ml-2"
                    @click="fechar()"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<script>

    import NaturezaOperacaoZoom from '../../zoom/NaturezaOperacaoZoom.vue';
    import NaturezaOperacaoVgService from '../../../service/NaturezaOperacaoVgService';
    import DestinoMercadoriaService from '../../../service/DestinoMercadoriaService';
    import CondicaoPagamentoService from '../../../service/CondicaoPagamentoService';
    import EstabelecimentoService from '../../../service/EstabelecimentoService';
    import ProdutorService from '../../../service/ProdutorService';
    import Formatacao from '../../../utilities/Formatacao';
    import ProdutorFiltro from '../ProdutorFiltro.vue';
    import PedidoService from '../../../service/pedido/PedidoService';
    import ContratoCompraVendaService from "../../../service/ContratoCompraVendaService";
    import UnidadeMedidaService from '../contrato/service/UnidadeMedidaService';
    import GestaoFaturamentoPedidoService from '../../../service/pedido/GestaoFaturamentoPedidoService'
    import FaturamentoService from "@/service/pedido/FaturamentoService";

    export default {
        name: "ModalPedidoVG",
        components: { ProdutorFiltro, NaturezaOperacaoZoom},
        emits: ["fechar"],
        props: {
            pedidoId: { type: Number, required: false },
            contratoId: { type: Number, required: false },
            acao: { type: String, required: true },
            visivel: { type: Boolean, required: true },
            gestaoPF: { type: Object, required: false},
            totalPedidos: { type: Number, required: true}
        },

        data() {
            return {
                mensagem: { ativo: false, gravidade: 'info', conteudo: '' },
                clienteConsulta: {},
                campoInvalido: {},
                estabelecimentoSelecionado: null,
                estabelecimentosFiltro: [],
                estabelecimentos: [],
                destinoMercadoriaFiltro: [],
                destinosMercadoria: [],

                siglaUnidadeMedida: "",
                pedido: { cliente: {}, naturezaOperacao: {} },
                saldoReal: 0,
                isAdministracao: false,
                isLogistica: false,
                isOperacao: false,
                isComercial: false,
                grupoClienteCCV: null,
                obsDefault: null,

                naturezaOperacaoConsulta: {},
                totalPedidos: this.totalPedidos,
                saldoUtilizado: 0
            }
        },
        methods: {

            init(){
                this.isAdministracao = this.$store.state.auth.user.isAdministracao;
                this.isLogistica = this.$store.state.auth.user.isLogistica;
                this.isOperacao = this.$store.state.auth.user.isOperacao;
                this.isComercial = this.$store.state.auth.user.isComercial;

                this.estabelecimentoSelecionado = null;
                this.carregarDestinoMercadoria();

                if(!!this.pedidoId) { 
                    this.preencherFormularioPedido();
                }
                else if(!!this.contratoId) {
                    this.preencherFormularioContrato();
                }
            },

            limpar() {
                this.mensagem = { ativo: false };
                this.pedido = { cliente: {}, naturezaOperacao: {} };
            },

            corMetodo(value){
              if(value == 'Novo'){
                return 'instock';
              }else
              if(value == 'Edição'){
                return 'outofstock';
              }else
              if(value == 'Clone'){
                return 'lowstock';
              }else
              if(value == 'Desmembramento'){
                return 'lowstock';
              }
            },

            carregarEstabelecimentos(event) {

              EstabelecimentoService.getTodosEstabelecimentos()
                  .then(({ data }) => {
                    if(data) {
                      this.estabelecimentosFiltro = data.map(item => {
                        item["label"] = item.codigo + " - " + item.nomeFantasia;
                        item["nome"] = item.nomeFantasia;
                        return item;
                      });
                    }
                  })
                  .catch(error => {
                    this.estabelecimentosFiltro = [];
                  });
            },

            carregarDestinoMercadoria() {
                DestinoMercadoriaService.lista()
                    .then(({ data }) => {
                        this.destinosMercadoria = data;
                    });
            },

            carregarRegional() {
              if(this.pedido.modalidadeFrete=="FOB"){return;}
              EstabelecimentoService.getRegionais("")
              .then(({ data }) => {
                let retorno = data.map(item => {
                  if(this.pedido.regionalOrigem==item.codigo) {
                    item["label"] = `${item.codigo} - ${item.nomeFantasia}`;
                    this.pedido["regionalPreferencial"] = item.label;
                    return item;
                  }
                });
              });

            },
            
            carregarEstabelecimento() {
                if(this.pedido.codEstabelecimentoOrigem == null) {
                    this.estabelecimentoSelecionado = null;
                    return;
                }
                
                EstabelecimentoService.getEstabelecimentosPorCodigo(this.pedido.codEstabelecimentoOrigem)
                    .then(({ data }) => {
                        this.estabelecimentoSelecionado = data;
                        this.estabelecimentoSelecionado["label"] = data.codigo + " - " + data.nomeFantasia;
                    })
                    .catch(error => {
                        if(!!error && !!error.response) {
                            const { data } = error.response;
                            this.$toast.add({
                                severity:'error', 
                                summary: 'Falha ao buscar estabelecimento.',
                                detail: data.message,
                                life: 5000
                            });
                        }
                    });
            },

            carregarEstabelecimentosDeOrigem() {
                ContratoCompraVendaService.buscarEstabelecimentoDeOrigem(this.pedido.idContratoCompraVenda)
                    .then(({ data }) => {
                        this.estabelecimentos = data.map(item => {
                            item["label"] = item.codigo + " - " + item.nomeFantasia;
                            return item;
                        });

                        this.carregarEstabelecimento();
                    })
                    .catch(error => {
                        this.estabelecimentos = [];
                    });
            },

            buscarEstabelecimentoDeOrigem(event) {
                const codigoOuNomeFantasia = !event.query.trim().length ? undefined : event.query;
                if(!event.query.trim().length) {
                    this.estabelecimentosFiltro = [...this.estabelecimentos];
                }
                else {
                    this.estabelecimentosFiltro = this.estabelecimentos.filter((item) => {
                        return item.label.toLowerCase().startsWith(codigoOuNomeFantasia.toLowerCase());
                    });
                }
            },

            selecionarEstabelecimento(){
                if(this.estabelecimentoSelecionado == undefined) { 
                    this.pedido.codEstabelecimentoOrigem = null;
                    this.pedido.nomeEstabelecimentoOrigem = null;
                }else{
                    this.pedido.codEstabelecimentoOrigem = this.estabelecimentoSelecionado.codigo;
                    this.pedido.nomeEstabelecimentoOrigem = this.estabelecimentoSelecionado.nomeFantasia;
                }
            },

            pesquisarCliente(filtros) {
                
                filtros.grupoCliente = this.grupoClienteCCV;

                ProdutorService.pesquisarProdutor(filtros)
                    .then(({ data }) => {
                        if(data) {
                            this.clienteConsulta = data;
                        }
                    })
                    .catch(error => {
                        this.clienteConsulta = {}

                        const toast = {
                            severity: 'error',
                            summary: 'Falha ao carregar os clientes.',
                            detail: error, 
                            life: 7500
                        }

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            },

            selecionarCliente(codProdutor) {
                if(codProdutor == undefined || codProdutor == null || codProdutor.trim() == "") {
                    this.pedido.cliente = {};
                    return;
                }

                this.desativarCampoNaturezaOperaca = false;

                ProdutorService.buscarPorCodProdutorInfoContrato(codProdutor)
                    .then(({ data }) => {
                        if(data) {
                            if(!!this.grupoClienteCCV && this.grupoClienteCCV.trim() != ""){
                                if(!data.grupoCliente || this.grupoClienteCCV != data.grupoCliente){
                                    this.pedido.cliente = {};

                                    const msg = "O cliente informado não pertence ao mesmo grupo de cliente: "+ this.grupoClienteCCV;
                                    const toast = {
                                        severity: 'warn',
                                        summary: "Atenção!",
                                        detail: msg, 
                                        life: 7500
                                    }
                                    this.$toast.add(toast);
                                    return;
                                    
                                }
                            }
                            this.pedido.cliente = data;
                            this.pedido.cliente.cnpj = data.cpfCnpj;
                        }
                    })
                    .catch(error => {
                        this.pedido.cliente = {};

                        const toast = {
                            severity: 'error',
                            summary: `Falha ao buscar o cliente.`,
                            detail: error, 
                            life: 7500
                        }

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;

                            if(!!message) {
                                toast["detail"] = message.replace("produtor", "cliente");
                            }
                        }

                        this.$toast.add(toast);
                    });

            },

            salvar() {
                if(!this.validarCampos()) return;
                const pedido = this.montarJson();
                PedidoService.salvar(pedido)
                    .then(({ data }) => {
                        if(!!data) {
                            this.fechar();

                            this.$toast.add({
                                severity:'success',
                                summary: 'Sucesso.',
                                detail:`Pedido foi salvo com sucesso.`, 
                                life: 7500
                            });
                        }
                    })
                    .catch(error => {
                        const toast = {
                            severity: 'error',
                            summary: 'Não foi possível salvar o pedido.',
                            detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                            life: 7500
                        }
                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            },
            
            montarJson() {
                const obj = {};
                obj["id"] = this.pedido.id;
                obj["permissao"] = {
                                    isAdministracao: this.isAdministracao,
                                    isLogistica: this.isLogistica,
                                    isComercial: this.isComercial,
                                    isOperacao: this.isOperacao};
                obj["pedidoBloqueado"] = !!this.pedido.pedidoBloqueado?true:false;
                obj["contratoCompraVendaId"] = this.pedido.contratoCompraVendaId;
                obj["autorizadoPor"] = this.pedido.autorizadoPor;
                obj["nomeAbrevRemessa"] = this.pedido.nomeAbrevRemessa;
                obj["rco"] = this.pedido.rco;
                obj["codigoEntrega"] = this.pedido.codigoEntrega;
                obj["codigoEntregaRemessa"] = this.pedido.codigoEntregaRemessa;
                obj["saldoUtilizado"] = this.pedido.saldoUtilizado;
                obj["nroPedidoCompraCliente"] = this.pedido.nroPedidoCompraCliente;
                obj["codCondicaoPagto"] = this.pedido.codCondicaoPagto;
                obj["descCondicaoPagto"] = this.pedido.descCondicaoPagto;
                obj["codEstabelecimentoOrigem"] = this.pedido.codEstabelecimentoOrigem;
                obj["codNaturezaOperacao"] = this.pedido.naturezaOperacao.codigo;
                obj["descNaturezaOperacao"] = this.pedido.naturezaOperacao.descricao;
                obj["destinoMercadoria"] = this.pedido.destinoMercadoria.codigo;
                obj["dataAutotizacao"] = Formatacao.formatDateCustom(this.pedido.dataAutorizacao, "YYYY-MM-DD");
                obj["idGestaoFaturamentoPedido"] = this.pedido.idGestaoFaturamentoPedido;
                obj["observacaoNota"] = !!this.pedido.observacaoNota ? this.pedido.observacaoNota.trim() : "";
                obj["parcela"] = this.pedido.parcela;
                obj["versao"] = this.pedido.versao;

                obj["grupoProdutoId"] = this.pedido.grupoProdutoId;
                obj["grupoProdutoCodigo"] = this.pedido.grupoProdutoCodigo;
                obj["grupoProdutoDescricao"] = this.pedido.grupoProdutoDescricao;

                const cliente = {};
                cliente["codigo"] = this.pedido.cliente.codProdutor;
                cliente["nome"] = this.pedido.cliente.nome;
                cliente["cnpj"] = this.pedido.cliente.cnpj;
                cliente["inscricaoEstadual"] = this.pedido.cliente.rgIe;
                cliente["endereco"] = this.pedido.cliente.endereco;
                cliente["bairro"] = this.pedido.cliente.bairro;
                cliente["cidade"] = this.pedido.cliente.cidade;
                cliente["unidadeFederativa"] = this.pedido.cliente.estado;
                cliente["cep"] = this.pedido.cliente.cep;
                cliente["representante"] = this.pedido.cliente.representante;  
                cliente["grupoCliente"] = this.pedido.cliente.grupoCliente;    
                
                obj["cliente"] = cliente;

                return obj;
            },

            preencherCliente(){
                const cliente = {};
                cliente["codigo"] = this.pedido.cliente.codProdutor;
                cliente["nome"] = this.pedido.cliente.nome;
                cliente["cnpj"] = this.pedido.cliente.cpfCnpj;
                cliente["inscricaoEstadual"] = this.pedido.cliente.rgIe;
                cliente["endereco"] = this.pedido.cliente.endereco;
                cliente["bairro"] = this.pedido.cliente.bairro;
                cliente["cidade"] = this.pedido.cliente.cidade;
                cliente["unidadeFederativa"] = this.pedido.cliente.estado;
                cliente["cep"] = this.pedido.cliente.cep;
                cliente["representante"] = this.pedido.cliente.representante; 
                cliente["grupoCliente"] = this.pedido.cliente.grupoCliente;           
                this.pedido["cliente"] = cliente;
            },

            validarCampos() {

                let campos = [];

                if(!this.pedido.autorizadoPor) {
                    campos.push("Autorizado Por");
                    this.campoInvalido.autorizadoPor = true;
                }else {
                    this.campoInvalido.autorizadoPor = false;
                }

                if(!this.pedido.dataAutorizacao) {
                    campos.push("Data Autorização");
                    this.campoInvalido.dataAutorizacao = true;
                }else {
                    this.campoInvalido.dataAutorizacao = false;
                }

                if(!this.pedido.cliente) {
                    campos.push("Cliente");
                    this.campoInvalido.cliente = true;
                }else {
                    this.campoInvalido.cliente = false;
                }

                if(!this.estabelecimentoSelecionado) {
                    campos.push("Origem Mercadoria");
                    this.campoInvalido.origemMercadoria = true;
                }else {
                    this.campoInvalido.origemMercadoria = false;
                }

                if(!this.pedido.destinoMercadoria) {
                    campos.push("Destino Mercadoria");
                    this.campoInvalido.destinoMercadoria = true;
                }else {
                    this.campoInvalido.destinoMercadoria = false;
                }

                if(!this.pedido.naturezaOperacao) {
                    campos.push("Natureza Operação");
                    this.campoInvalido.naturezaOperacao = true;
                }else {
                    this.campoInvalido.naturezaOperacao = false;
                }

                if(!this.pedido.saldoUtilizado || this.pedido.saldoUtilizado == 0) {
                    campos.push("Saldo Utilizado");
                    this.campoInvalido.saldoUtilizado = true;
                }
                else {
                    this.campoInvalido.saldoUtilizado = false;
                }

                if(!this.pedido.id && this.isLogistica && this.pedido.saldoUtilizado > this.gestaoPF.saldoLiberadoBloqueio - this.totalPedidos){
                  campos.push("Saldo Utilizado ultrapassa o valor desbloqueado!");
                  this.campoInvalido.saldoUtilizado = true;
                }
                else {
                  this.campoInvalido.saldoUtilizado = false;
                }

              if(!!this.pedido.id && this.isLogistica && this.pedido.saldoUtilizado > this.gestaoPF.saldoLiberadoBloqueio - this.totalPedidos + this.saldoUtilizado){
                campos.push("Saldo Utilizado ultrapassa o valor desbloqueado!");
                this.campoInvalido.saldoUtilizado = true;
              }
              else {
                this.campoInvalido.saldoUtilizado = false;
              }

                if(campos.length > 0) {
                    let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

                    if(campos.length > 1) {
                        mensagem = "Por favor, preencher os campos: ";
                        mensagem += campos.join(", ");

                        const indice = mensagem.lastIndexOf(", ");
                        mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                    }

                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Atenção!',
                        detail: mensagem, 
                        life: 15000
                    });
                }

                if(this.pedido.saldoUtilizado && this.pedido.saldoUtilizado > this.pedido.saldoPedido){
                    const mensagem = "A quantidade informada não pode ser superior ao saldo disponível de "+ 
                        this.formatDecimal(this.pedido.saldoPedido, 2, 2) + " "+ this.siglaUnidadeMedida;

                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Atenção!',
                        detail: mensagem, 
                        life: 15000
                    });
                    return false;
                }

                return campos.length === 0;
            },

            calcularSaldoBloqueio(){
              //this.pedido.saldoPedido = this.pedido.saldoPedido - this.gestaoPF.saldoLiberadoBloqueio;
            },

            calcularSaldoReal(event){
                let saldoPedido = !!this.pedido.saldoPedido ? this.pedido.saldoPedido : 0;       
                let saldoUtilizado = !!this.pedido.saldoUtilizado ? this.pedido.saldoUtilizado : 0;

                if(!!event) {
                    const { value, id } = event.target;

                    if(!!value) {
                        let valor = value.replace(/\./g, "");
                        valor = valor.replace(/\,/, ".");
                        valor = parseFloat(valor);

                        if(id == "saldoUtilizado") {
                            saldoUtilizado = valor;
                        }
                    }
                }
                this.saldoReal = saldoPedido - saldoUtilizado;
            },

            buscarDestinoMercadoria(event) {
                if (!event.query.trim().length) {
                    this.destinoMercadoriaFiltro = [...this.destinosMercadoria];
                } else {
                    this.destinoMercadoriaFiltro = this.destinosMercadoria.filter((item) => {
                        return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            },

            fechar() {
                this.$emit("fechar");
                this.limpar();
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },

            formatDateCustom(data, format){
                return Formatacao.formatDateCustom(data, format);
            },

            preencherFormularioPedido() {
                if(!this.pedidoId) return;
                PedidoService.buscarPorId(this.pedidoId)
                    .then(({ data }) => {
                        this.pedido = data;
                        this.escreveObservacao(data);
                        this.saldoUtilizado = data.saldoUtilizado;
                        if(data.observacaoNota==null){data.observacaoNota="";}

                        let obsAut = data.observacaoNota.substring(0,data.observacaoNota.indexOf(" |"));
                        let obsMan = data.observacaoNota.substring(data.observacaoNota.indexOf(" |")+2).trim();

                        this.pedido.observacaoNota = this.obsDefault + obsMan;

                        this.pedido["pedidoBloqueado"] = data.pedidoBloqueado;
                        this.pedido["dataAutorizacao"] = Formatacao.convertStringToDate(data.dataAutotizacao, 'YYYY-MM-DD');
                        this.pedido["condicaoPagamento"] = CondicaoPagamentoService.buscarMockPorCodigo(data.codCondicaoPagto);

                        const unidade = UnidadeMedidaService.buscarPorCodigo(data.unidade);
                        this.pedido["qtdFixada"] = (data.qtdFixada * unidade.fatorConversao).toFixed(2);

                        const naturezaOperacao = {};
                        naturezaOperacao["codigo"] = data.codNaturezaOperacao;
                        naturezaOperacao["descricao"] = data.descNaturezaOperacao;
                        this.pedido["naturezaOperacao"] = naturezaOperacao;

                        if(!!data.cliente && Object.keys(data.cliente).length > 0) {
                            this.pedido.cliente["codProdutor"] = data.cliente.codigo;
                            this.grupoClienteCCV = data.cliente.grupoCliente;
                        }

                        if(!!this.acao && this.acao == "Clonar") {
                            this.pedido["id"] = null;
                            this.pedido.saldoPedido = this.pedido.saldoPedido - this.pedido.saldoUtilizado;
                            this.pedido.saldoUtilizado = this.pedido.saldoPedido;
                        }

                        this.carregarSiglaUnidadeMedida(data.unidade);
                        this.carregarEstabelecimentosDeOrigem();
                        this.calcularSaldoReal(null);
                        this.calcularSaldoBloqueio();
                        this.carregarRegional();
                    });
            },

            preencherFormularioContrato() {
                if(!this.contratoId) return;
                
                ContratoCompraVendaService.buscarPorId(this.contratoId)
                    .then(({ data }) => {
                        this.pedido = {};
                        this.pedido["versao"] = 0
                        this.pedido["cliente"] = data.comprador;
                        if(!!data.comprador && Object.keys(data.comprador).length > 0) {
                            this.pedido.cliente["codProdutor"] = data.comprador.codigo;
                            this.grupoClienteCCV = data.comprador.grupoCliente;
                        }

                        this.pedido["idGestaoFaturamentoPedido"] = data.id;
                        this.pedido["grupoProdutoId"] = data.grupoProdutoId;
                        this.pedido["tipoTributacao"] = data.tipoTributacao;
                        this.pedido["grupoProdutoCodigo"] = data.grupoProdutoCodigo;
                        this.pedido["grupoProdutoDescricao"] = data.grupoProdutoDescricao;
                        this.pedido["modalidadePrecificacao"] = data.modalidadePrecificacao;
                        this.pedido["codEstabelecimentosOrigem"] = data.codEstabelecimentosOrigem;
                        this.pedido["regionalOrigem"] = data.codEstabOrigemEntrega;
                        this.pedido["modalidadeFrete"] = data.modalidadeFrete;

                        const naturezaOperacao = {};
                        naturezaOperacao["codigo"] = data.codNaturezaOperacao;
                        naturezaOperacao["descricao"] = data.descNaturezaOperacao;
                        this.pedido["naturezaOperacao"] = naturezaOperacao;

                        this.carregarRegional();

                        if(!!data.formaPagamento) {
                            const formaPagamento = data.formaPagamento;
                            this.pedido["descCondicaoPagto"] = data.getFormaPagamento;

                            if(!!formaPagamento.condicaoPagamento && !!formaPagamento.condicaoPagamento.id) {
                                this.pedido["codCondicaoPagto"] =  formaPagamento.condicaoPagamento.id;
                            }
                        }

                        this.carregarSaldo();
                        this.carregarSiglaUnidadeMedida(data.unidade);
                        this.carregarEstabelecimentosDeOrigem();
                        this.escreveObservacao(data);
                        this.pedido.observacaoNota = this.obsDefault;
                    });
            },

            carregarSiglaUnidadeMedida(unidadeMedida) {
                if(!unidadeMedida) return;
                const unidade = UnidadeMedidaService.buscarPorCodigo(unidadeMedida);
                this.siglaUnidadeMedida = !!unidade ? unidade.sigla : "";
            },

            carregarSaldo() {
              GestaoFaturamentoPedidoService.buscarSaldoPedido(this.contratoId)
                .then(({ data }) => {
                  this.pedido.saldoPedido = data;
                  this.pedido.saldoUtilizado = data;
                });
            },

          escreveObservacao(dado){
            let obs = !dado.obeservacao ? !dado.observacaoCCV ? "N/A" : dado.observacaoCCV : dado.obeservacao;
            let cj = !dado.contratoJuridico ? "*****" : dado.contratoJuridico;
            let seg = !dado.valorSeguro ? "*****" : dado.valorSeguro;

            let texto = "CI: " + dado.contratoInternoId + ", Contrato Corretora: " + dado.contratoCorretora +
                ", Valor para efeito de seguro R$ " + Formatacao.formatDecimal(seg,2,2) +
                "/saca, ContratoJurídico: " + cj + ", Obs. CCV: " + obs + " |  ";

            this.obsDefault = texto.replace("null","*****");
          },

            ajustaTA(evt) {
                if(evt.target.value.length < this.obsDefault.length || evt.srcElement.selectionStart < this.obsDefault.length ){
                this.pedido["observacaoNota"] = this.obsDefault;
                evt.srcElement.selectionStart = this.obsDefault.length;
                evt.srcElement.selectionEnd = evt.srcElement.selectionStart;

                }

            },

            pesquisarNaturezaOperacao(filtros) {
                NaturezaOperacaoVgService.buscar(filtros)
                    .then(({ data }) => {
                        if(data) {
                            this.naturezaOperacaoConsulta = data.naturezas;
                        }
                    })
                    .catch(error => {
                        this.naturezaOperacaoConsulta = {}

                        const toast = {
                            severity: 'error',
                            summary: 'Falha ao carregar as naturezas.',
                            detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                            life: 7500
                        }

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            },

            selecionarNaturezaOperacao(natureza) {

                if(natureza == undefined || natureza == null || natureza.trim() == "") {
                    this.pedido.naturezaOperacao = {};
                    return;
                }

                NaturezaOperacaoVgService.buscarPorNatureza(natureza)
                    .then(({ data }) => {
                        const naturezaOperacao = {};
                        naturezaOperacao["codigo"] = data.natureza.natureza;
                        naturezaOperacao["descricao"] = data.natureza.denominacao;
                        this.pedido.naturezaOperacao = naturezaOperacao;
                    })
                    .catch(error => {
                        this.pedido.naturezaOperacao = {};

                        const toast = {
                            severity: 'error',
                            summary: `Falha ao buscar a natureza de operação.`,
                            detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                            life: 7500
                        }

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message, exception } = error.response.data;

                            if(!!message) {
                                toast["detail"] = exception;
                            }
                        }

                        this.$toast.add(toast);
                    });
            }

        }
    }
</script>

<style scoped lang="scss">
    @import '../../../assets/demo/styles/badges.scss';
    .fontUpper{
        text-transform: uppercase;
    }

    .label {
        text-align: right;
        padding: 0;
    }

    .campo {
        margin: 0.5rem 0;
        padding: 0 0 0 0.5rem;
        overflow: hidden;
    }
    .valor-campo-numerico{
        text-align: right !important; 
        font-weight: bold !important;        
        border-bottom: 1px solid rgba(0, 0, 0, 0.38) !important;
    }
    .sigla-campo-numerico{
        text-align: left; 
        border-bottom: 1px solid rgba(0, 0, 0, 0.38) !important;
    }
</style>