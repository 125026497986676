<template xmlns="http://www.w3.org/1999/html">
    <DataTable editMode="row" v-model:editingRows="editingRows" responsiveLayout="stack" :value="dto"
        selectionMode="single" showGridlines v-model:selection="itemSelecionado">
        <template #header>
          <div class="flex align-items-center justify-content-between">
            <strong>Histórido de Cotas</strong>

            <Button
                type="button"
                icon="pi pi-calendar"
                label="Agendar Cota"
                class="p-button-outlined "
                aria-label="Editar"
                @click="novaLinha"
            ></Button>

          </div>
        </template>

        <template #empty>
            Nenhum registro encontrado.
        </template>

        <template #loading>
            Carregando... Por favor, aguarde.
        </template>

        <Column field="dataReferencia" header="Data" class="centralizar-titulo-tabela">
          <template #body="{ data }">
            {{ formatDateCustom(data.dataReferencia, "DD/MM/YYYY") }}
          </template>
          <template #editor="{ data, field }" center>
            <Calendar v-model="data[field]" class="w-full" dateFormat="dd/mm/yy" :disabled="!permitirEdicaoDataReferencia(data.dataReferencia)"/>
          </template>
        </Column>

        <Column field="cotaPadrao" header="Cota Padrão" class="centralizar-titulo-tabela">
          <template #body="{ data, field }">
            {{ data[field] }}
          </template>
        </Column>

        <Column field="cotaLiberada" header="Cota Liberada" class="centralizar-titulo-tabela">
          <template #body="{ data, field }">
            {{ data[field] }}
          </template>
          <template #editor="{ data, field }" >
            <InputNumber v-model="data[field]" class="w-full"/>
          </template>
        </Column>

        <Column field="cotaRealizada" header="Cota Realizada" class="centralizar-titulo-tabela">
          <template #body="{ data, field }">
            {{ data[field] }}
          </template>
        </Column>

        <Column v-if="editavel" header="Ações" style="width: 9rem;" class="centralizar-titulo-tabela">
            <template #body="{ data, index }">
                <div class="flex align-items-center justify-content-center" v-if="permitirEdicaoCota(data.dataReferencia)">
                    <Button class="p-button-rounded p-button-info flex-none mr-2" icon="pi pi-pencil" aria-label="Editar"
                        @click="editar(data)" v-if="data.cotaPadrao" title="Editar"></Button>
                    <Button class="p-button-rounded p-button-danger flex-none mr-2" icon="pi pi-trash" aria-label="Excluir"
                        @click="excluir(index)" v-if="data.cotaPadrao && permitirExcluirDataReferencia(data.dataReferencia)" title="Excluir"></Button>
                </div>
            </template>
            <template #editor="{ data, index }">
                <div class="flex align-items-center justify-content-center" >
                    <Button class="p-button-rounded p-button-info flex-none mr-2" icon="pi pi-save" aria-label="Editar"
                        @click="salvarLinha(data, index)" title="Salvar"></Button>
                    <Button class="p-button-rounded p-button-danger flex-none mr-2" icon="pi pi-times" aria-label="Editar"
                        @click="cancelar()" title="Cancelar"></Button>
                </div>
            </template>
        </Column>

    </DataTable>
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import AjusteCotaService from "@/service/AjusteCotaService";

export default {
    props: {
        editavel: {
            type: Boolean,
            required: true
        },

        dto: {
            type: Object,
            required: true
        },

        campoInvalido: {
            type: Object,
            required: true
        },

        cotaPadrao: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            editingRows: [],

            indicadorFiltro: [],
            indicadores: [],

            operacaoFiltro: [],
            operacoes: [],

            itemSelecionado: {}

        }
    },

    mounted() {
    },

    unmounted() {
    },

  methods: {

        setup() {
            if (!this.dto) {
                this.dto = [];
            }

        },

        editar(registro) {
            this.$emit("editarHist", true);
            const registroAux = Object.assign({}, registro);
            this.editingRows = [registroAux];
        },

        validarCamposObrigatorios(registro) {
            const campos = [];

            if (!registro.dataReferencia || Formatacao.formatDateCustom(registro.dataReferencia,"DD/MM/YYYY") < Formatacao.formatDateCustom(new Date,"DD/MM/YYYY")) {
                campos.push("Data");
                this.campoInvalido.data = true;
            }
            else {
                this.campoInvalido.data = false;
            }

            if (!registro.cotaPadrao) {
                campos.push("Cota Padrão");
                this.campoInvalido.cotaPadrao = true;
            }
            else {
                this.campoInvalido.cotaPadrao = false;
            }

            if (!registro.cotaLiberada) {
              campos.push("Cota Liberada");
              this.campoInvalido.cotaLiberada = true;
            }
            else {
              this.campoInvalido.cotaLiberada = false;
            }

            if (registro.cotaRealizada==null) {
              campos.push("Cota Realizada");
              this.campoInvalido.cotaRealizada = true;
            }
            else {
              this.campoInvalido.cotaRelizada = false;
            }

            if (campos.length > 0) {
                const toast = {
                    severity: 'warn',
                    summary: 'Histórico de Cotas',
                    detail: `Por favor, preencher o campo ${campos[0]}`,
                    life: 7500
                }

                if (campos.length > 1) {
                    toast.detail = "Por favor, preencher os campos: ";
                    toast.detail += campos.join(", ");

                    const indice = toast.detail.lastIndexOf(", ");
                    toast.detail = `${toast.detail.substring(0, indice)} e ${toast.detail.substring(indice + 1, toast.detail.length)}.`;
                }

                this.$toast.add(toast);
                return true;
            }

            return false;
        },

        salvarLinha(registro, indice) {
            if (!registro) return;
            if (this.validarCamposObrigatorios(registro)) return;

            registro.dataReferencia = Formatacao.formatDateCustom(registro.dataReferencia,"YYYY-MM-DD");

            this.dto[indice] = registro;
            this.dto.sort((objA, objB) => { return objA["dataReferencia"] - objB["dataReferencia"]; });
            this.editingRows = [];
            this.$emit("editarHist", false);
        },

        excluir(indice) {
            if(!!this.dto[indice].id) {
              AjusteCotaService.inativar(this.dto[indice].id)
                  .then(({data}) => {

                  });
            }
            this.dto.splice(indice, 1);
        },

        cancelar() {
            if (!this.editingRows && this.editingRows.length <= 0) return;
            this.editingRows = [];
            this.campoInvalido.indicadorValor = false;
            this.campoInvalido.operacao = false;
            this.campoInvalido.ordem = false;
            this.$emit("editarHist", false);
        },

        novaLinha(){
            const linha = {
              cotaPadrao: this.cotaPadrao,
              cotaLiberada: this.cotaPadrao,
              cotaRealizada: 0,
              dataReferencia: Formatacao.somarDias(new Date(), 1)
            };

            for(let l=this.dto.length; l > 0; l--){
              this.dto[l]=this.dto[l-1];
            }

            this.dto[0]=linha;

            this.editar(this.dto[0])
        },
        permitirEdicaoCota(dataReferencia) { 
          var strDataReferencia = this.formatDateCustom(dataReferencia, 'DD/MM/YYYY');
          var strNow = this.formatDateCustom(new Date(), 'DD/MM/YYYY');;
          return Formatacao.convertStringToDate(strDataReferencia, "YYYY-MM-DD") >= Formatacao.convertStringToDate(strNow, "YYYY-MM-DD");
        },
        permitirExcluirDataReferencia(dataReferencia) { 
          var strDataReferencia = this.formatDateCustom(dataReferencia, 'DD/MM/YYYY');
          var strNow = this.formatDateCustom(new Date(), 'DD/MM/YYYY');;
          return Formatacao.convertStringToDate(strDataReferencia, "YYYY-MM-DD") > Formatacao.convertStringToDate(strNow, "YYYY-MM-DD");
        },
        permitirEdicaoDataReferencia(dataReferencia) { 
          var strDataReferencia = this.formatDateCustom(dataReferencia, 'DD/MM/YYYY');
          var strNow = this.formatDateCustom(new Date(), 'DD/MM/YYYY');;
          return Formatacao.convertStringToDate(strDataReferencia, "YYYY-MM-DD") > Formatacao.convertStringToDate(strNow, "YYYY-MM-DD");
        },
        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            if (value!=null) return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(date, format) {
          return Formatacao.formatDateCustom(date, format);
        }

    }
}
</script>

<style>
.centralizar-titulo-tabela {
    text-align: center !important;
}

.centralizar-titulo-tabela .p-column-header-content {
    display: inline;
}
</style>
