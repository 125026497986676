class CondicaoPagamentoService {
    listaMock() {
        return [
            { descricao: "À vista", codigo: "1"},
            { descricao: "10 dias", codigo: "2" },
            { descricao: "30 dias", codigo: "3" },
            { descricao: "60 dias", codigo: "4" },
            { descricao: "30/60 dias", codigo: "5" }
        ]
    }

    buscarMockPorCodigo(codigo) {
        return this.listaMock().find(item => {
            return item.codigo === codigo;
        })
    }
}

export default new CondicaoPagamentoService();