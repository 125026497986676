<template>
    <DataTable 
            responsiveLayout="stack"
            :value="faturamentos"
            v-model:selection="faturamentoSelecionado"
            selectionMode="single"
            showGridlines
            @sort="sort($event)">
        <template #header>
            <div class="flex align-items-center justify-content-between">
                <Button
                    v-if="filter.situacao == 'ATIVO'"
                    type="button"
                    icon="pi pi-ban"
                    label="Listar inativos"
                    class="p-button-outlined p-button-danger mr-2"
                    @click="filter.situacao = 'INATIVO'; carregarDados()"
                ></Button>

                <Button
                    v-if="filter.situacao == 'INATIVO'"
                    type="button"
                    icon="pi pi-check-circle"
                    label="Listar ativos"
                    class="p-button-outlined mr-2"
                    @click="filter.situacao = 'ATIVO'; carregarDados()"
                ></Button>
            </div>
        </template>

        <template #empty>
            Nenhum faturamento encontrado.
        </template>
        <Column field="id" header="ID"/>
        <Column field="pedidoId" header="NP" style="font-weight: bold"/>
      
        <Column field="itCodigo" header="Item"/>
        <Column field="codNaturezaOperacao" header="Natureza Op."/>
        <Column field="descCondicaoPagto" header="Condição Pagto" />
        <Column field="nomeTransportadora" header="Transportadora" />
        <Column field="placa" header="Placa"/>
        <Column field="nrDocPes" header="Doc. Pesagem"/>
        <Column field="codEntrega" header="Cód. Entrega"/>
        <Column field="destinoMercadoria" header="Destino Mercadoria">
            <template #body="{ data }">
                {{ data.destinoMercadoria?.descricao }}
            </template>
        </Column>
        <Column field="nrNotaFiscal" header="NF"/>
        <Column field="quantidade" header="Quantidade" style="width:11rem; font-weight: bold">
            <template #body="{ data }">
                {{ formatDecimal(data.quantidade, 2, 2) }} KG
            </template>
        </Column>
        <Column header="Ações" style="width:9rem;" class="centralizar-titulo-tabela">
            <template #body="{ data }">
                <div class="flex align-items-center justify-content-center">

                    <Button 
                        class="p-button-rounded p-button-warning flex-none mr-2" 
                        title="Visualizar Histórico"  
                        icon="pi pi-list" 
                        v-if="data.id"
                        @click="visualizarHistorico(data)"/>

                    <Button
                        v-if="data.ativo"
                        class="p-button-rounded p-button-info flex-none mr-2"
                        icon="pi pi-pencil"
                        aria-label="Editar"
                        title="Editar"
                        @click="editarFaturamento(data)"
                    ></Button>

                    <Button 
                        v-if="data.ativo"
                        title="Inativar"
                        class="p-button-rounded flex-none p-button-danger"
                        icon="pi pi-ban"
                        @click="confirmarAtivarOuInativar(data)"
                    ></Button>

                    <Button 
                        v-if="!data.ativo"
                        title="Ativar"
                        class="p-button-rounded flex-none p-button-info"
                        icon="pi pi-check-circle"
                        @click="confirmarAtivarOuInativar(data)"
                    ></Button>
                </div>
            </template>
        </Column>



        <ColumnGroup type="footer">
            <Row v-if="filter.situacao == 'ATIVO'">
                <Column :colspan="11" footerStyle="text-align:right">
                    <template #footer>
                        <strong>
                            Total Geral:
                        </strong>
                    </template>
                </Column>
                <Column>
                    <template #footer>
                        <strong>
                            {{ formatDecimal(quantidadeTotalFaturada, 2, 2) }} KG
                        </strong>
                    </template>
                </Column>
                <Column :colspan="2"></Column>
               
            </Row>
        </ColumnGroup>
        
    </DataTable>

    <Dialog v-model:visible="ativarOuInativarFaturamentoDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>Tem certeza que deseja <strong>{{ faturamentoSelecionado.ativo ? 'inativar' : 'ativar' }}</strong> o faturamento: <strong>{{ faturamentoSelecionado.id }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarFaturamentoDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
        </template>
    </Dialog>

    <Paginator
        v-model:first="primeiraLinha"
        :rows="qtdRegistroPorPagina"
        :total-records="totalRegistro"
        :rowsPerPageOptions="[5, 10, 20, 30]"
        @page="onPage" />

    <ModalFaturamentoEmbarque
        :faturamento="faturamentoDto"
        :visivel="faturamentoDialog"
        @fechar="faturamentoFechar"
    />

    <ModalHistoricoGenerico
        codigoPagina="FATURAMENTO_VG"
        :id="faturamentoSelecionado.id"
        :descricao="descricaoHistoricoFaturamentoDialog"
        :visivel="historicoFaturamentoDialog"
        @fechar="ocultarHistorico()"
    />
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import ModalHistoricoGenerico from '../../ModalHistoricoGenerico.vue';
import FaturamentoService from '../../../service/pedido/FaturamentoService';
import PedidoService from "../../../service/pedido/PedidoService";
import CondicaoPagamentoService from '../../../service/CondicaoPagamentoService';
import ModalFaturamentoForm from './ModalFaturamentoForm.vue';
import ProdutoService from "../../../service/ProdutoService";
import UnidadeMedidaService from "@/components/cadastro/contrato/service/UnidadeMedidaService";
import ModalFaturamentoEmbarque from "@/components/cadastro/embarque/ModalFaturamentoEmbarque.vue";

export default {

    components: {ModalFaturamentoEmbarque, ModalFaturamentoForm, ModalHistoricoGenerico, PedidoService, ProdutoService},
    emits: ["atualizarGestao"],
    props: {
        gestao: {
            type: Object,
            required: true
        },

        refreshFaturamentoCount: {
            type: Number,
            default: 0
        },

        contratoId: {
          type: Number,
          required: true
        }

    },
    watch: {
        refreshFaturamentoCount:function() {
            this.carregarDados();
        }
    },
    
    data() {
        return {
            faturamentoDialogHistorico: false,
            isEdicaoFaturamento: false,
            faturamentoDialog: false,
            manutencaoValorFreteDialog: false,
            faturamentoSelecionado: {},
            faturamento: {},
            transportadora: {},
            saldoFaturamentoModal: 0.0,
            quantidadeTotalFaturada: 0,
            primeiraLinha: 0,
            faturamentos: [],
            filter: {},
            siglaUnidadeMedida: 'KG',

            historicoFaturamentoDialog: false,
            ativarOuInativarFaturamentoDialog: false,
            descricaoHistoricoFaturamentoDialog: null,

            modalidadePrecificacao: '',
            precoUnitarioCcv: 0.00,
            qtdFixada: 0.00,
            descricaoItem: '',

            pagina: 0,
            qtdRegistroPorPagina: 10,
            ordenar: 'id,desc',
            totalRegistro: 0,
            contratoId: null,
            faturamentoDto: null
        }
    },

    created() {
        this.filter["situacao"] = "ATIVO";
        this.carregarDados();
    },

    methods: {

        carregarDados() {
            this.filter["page"] = this.pagina;
            this.filter["size"] = this.qtdRegistroPorPagina;
            this.filter["sort"] = this.ordenar;
            this.filter["idGestaoFaturamentoPedido"] = this.gestao.id;

            FaturamentoService.buscarComPaginacao(this.filter)
                .then(response => {
                    this.faturamentos = response.data.content;
                    this.totalRegistros = response.data.totalElements;
                    this.carregarQtdeTotalFaturada();
                })
                .catch(error => {
                    this.$message({
                        severity: 'error',
                        summary: 'Erro',
                        detail: error.response.data.message
                    });
                });
            this.contratoId = this.gestao.contratoCompraVendaId;
        },

        carregarQtdeTotalFaturada() {
            FaturamentoService.quantidadeTotalFaturadaPorGestaoCI(this.gestao.id)
                .then(response => {
                    this.quantidadeTotalFaturada = response.data;
                })
                .catch(error => {
                    this.$message({
                        severity: 'error',
                        summary: 'Erro',
                        detail: error.response.data.message
                    });
                });
        },

        editarFaturamento(faturamento){
          FaturamentoService.buscarPorId(faturamento.id)
              .then(({data}) => {
                    this.faturamentoDto = data.dto.value;
                    this.faturamentoDialog = true;
                })
                .catch(error => {
                    this.carregarDados();

                    this.$message({
                        severity: 'error',
                        summary: 'Erro',
                        detail: error.response.data.message
                    });
                });

        },

        preencherFormFaturamento(data) {
            const faturamento = {};
            faturamento["id"] = data.id;
            faturamento["versao"] = data.versao;
            faturamento["pedidoId"] = data.pedidoId;
            faturamento["grupoProdutoId"] = data.grupoProdutoId;
            faturamento["grupoProdutoCodigo"] = data.grupoProdutoCodigo;
            faturamento["codEstabelecimentoOrigem"] = data.codEstabelecimentoOrigem;
            faturamento["itCodigo"] = data.itCodigo;
            faturamento["nrDocPes"] = data.nrDocPes;
            faturamento["dataNrDocPes"] = Formatacao.convertStringToDate(data.dataNrDocPes, 'YYYY-MM-DD');
            faturamento["placa"] = data.placa;
            faturamento["motorista"] = data.motorista;
            faturamento["quantidade"] = data.quantidade;
            faturamento["codNaturezaOperacao"] = data.codNaturezaOperacao;
            faturamento["destinoMercadoria"] = data.destinoMercadoria;
            faturamento["descNaturezaOperacao"] = data.descNaturezaOperacao;
            faturamento["condicaoPagamento"] = CondicaoPagamentoService.buscarMockPorCodigo(data.codCondicaoPagto);
            faturamento["codCondicaoPagto"] = data.codCondicaoPagto;
            faturamento["classificacao"] = data.classificacao;
            faturamento["transportadoraId"] = data.transportadoraId;
            faturamento["nomeTransportadora"] = data.nomeTransportadora;
            faturamento["cnpjTransportadora"] = data.cnpjTransportadora;
            faturamento["saldoFaturamentoPedidoEmKG"] = data.saldoFaturamentoPedidoEmKG;
            faturamento["serieNotaFiscal"] = data.serieNotaFiscal;
            faturamento["nrNotaFiscal"] = data.nrNotaFiscal;
            faturamento["observacaoNota"] =  data.observacaoNota==undefined?this.faturamento.observacaoNota:data.observacaoNota;

            const cliente = {};
            cliente["id"] = data.cliente.id;
            cliente["codProdutor"] = data.cliente.codigo;
            cliente["nome"] = data.cliente.nome;
            cliente["cpfCnpj"] = data.cliente.cnpj;  
            cliente["inscricaoEstadual"] = data.cliente.inscricaoEstadual;  
            cliente["endereco"] = data.cliente.endereco;  
            cliente["numeroEndereco"] = data.cliente.numeroEndereco;  
            cliente["bairro"] = data.cliente.bairro;  
            cliente["cidade"] = data.cliente.cidade;  
            cliente["unidadeFederativa"] = data.cliente.unidadeFederativa;   
            cliente["cep"] = data.cliente.cep;  
            cliente["representante"] = data.cliente.representante;           
            faturamento["cliente"] = cliente;

            const pesagem = {
                nroDocPesagem: data.nrDocPes, 
                placa: data.placa, 
                data: Formatacao.formatDateCustom(data.dataNrDocPes, 'YYYY-MM-DD'), 
                motorista: data.motorista,
                quantidade: data.quantidade
            };
            faturamento["pesagem"] = pesagem;

            const transportadora = {
                id: data.transportadoraId,
                nomeFantasia: data.nomeTransportadora,
                cnpj: data.cnpjTransportadora,
                codigoErp: data.codigoErpTransportadora,
                transportadora: (!!data.codigoErpTransportadora ? data.codigoErpTransportadora + " - " : "")
                    + data.nomeTransportadora + " - CNPJ: " + data.cnpjTransportadora
            };
            faturamento["transportadora"] = transportadora;

            const naturezaOperacao = {};
            naturezaOperacao["codigo"] = data.codNaturezaOperacao;
            naturezaOperacao["descricao"] = data.descNaturezaOperacao;
            naturezaOperacao["label"] = data.codNaturezaOperacao + " - " + data.descNaturezaOperacao;
            faturamento["naturezaOperacao"] = naturezaOperacao;

            return faturamento;
        },

        visualizarHistorico(faturamento) {
            this.descricaoHistoricoFaturamentoDialog = "Faturamento: "+ faturamento.id;
            this.historicoFaturamentoDialog = true;
            this.faturamentoSelecionado = faturamento;
        },

        ocultarHistorico() {
            this.historicoFaturamentoDialog = false;
            this.faturamentoSelecionado = {};
        },

        confirmarAtivarOuInativar(faturamento) {
            this.faturamentoSelecionado = faturamento;
            this.ativarOuInativarFaturamentoDialog = true;
        },

        ativarOuInativar() {
            if(this.faturamentoSelecionado.ativo) {
                FaturamentoService.inativar(this.faturamentoSelecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Inativado',
                            detail:`O faturamento foi inativado com sucesso.`, 
                            life: 7500
                        });

                        this.carregarDados();
                        this.$emit("atualizarGestao");
                        this.ativarOuInativarFaturamentoDialog = false;
                        this.faturamentoSelecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao inativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                FaturamentoService.ativar(this.faturamentoSelecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Ativada',
                            detail:`O faturamento foi ativado com sucesso.`, 
                            life: 7500
                        });
                        
                        this.carregarDados();
                        this.$emit("atualizarGestao");
                        this.ativarOuInativarFaturamentoDialog = false;
                        this.faturamentoSelecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao ativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }

            this.ativarOuInativarFaturamentoDialog = false;            
        },        

        faturamentoSalvar(faturamento) {
            const indice = faturamento.indice;

            if(indice >= 0) {
                this.dados.faturamentos[indice] = faturamento;
            }
            else {
                this.dados.faturamentos.push(faturamento);
            }

            this.faturamentoFechar();
            this.faturamento = {}; 
        },

        faturamentoFechar() {
            this.faturamentoDialog = false;
            this.faturamento = {};

            this.$emit("atualizarGestao");
            this.carregarDados();
        },

        faturamentoHistoricoFechar() {
            this.faturamentoDialogHistorico = false;
            this.faturamento = {};
        },

        getCotaTotal() {
            this.totalizadorCotas = 0;
            if(this.dados != null && this.dados.solicitacoes != null){
                for(let origem of this.dados.solicitacoes) {
                    const cota = isNaN(origem.cota) ? 0 : parseFloat(origem.cota);
                    this.totalizadorCotas += cota;
                }
            }
            return this.totalizadorCotas;
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(data, format){
            return Formatacao.formatDateCustom(data, format);
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.qtdRegistroPorPagina = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows;
            this.carregarDados();
        }
    }
}
</script>

<style scoped>
    .borda-personalizada {
        border: 1px solid #CCC;
        border-radius: 3px;
        padding: 1rem;
    }
</style>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
