<template>
  <DataTable editMode="row" responsiveLayout="stack" :value="linha"
             selectionMode="single" showGridlines >

    <template #empty>
      Nenhum registro encontrado.
    </template>

    <template #loading>
      Carregando... Por favor, aguarde.
    </template>

    <Column field="pedidoId" header="Pedido"/>
    <Column field="contratoInternoId" header="CI"/>
    <Column field="solicitacaoEmbarquePedidoId" header="SE"/>
    <Column field="classificacao.descricao" header="Classificação"/>
    <Column field="classificadora.nome" header="Classificadora"/>
    <Column field="limiteUmidade" header="Lim. Umidade (%)" >
      <template #body="{ data }">
        {{formatDecimal(data.limiteUmidade==null ? 0 : data.limiteUmidade,2,2)}}
      </template>
    </Column>
    <Column field="limiteImpureza" header="Lim. Impureza (%)">
      <template #body="{ data }">
        {{formatDecimal(data.limiteImpureza==null ? 0 : data.limiteImpureza,2,2)}}
      </template>
    </Column>
    <Column field="limiteGraoAvariado" header="Lim. Grão Avariado (%)">
      <template #body="{ data }">
        {{formatDecimal(data.limiteGraoAvariado==mull ? 0 : data.limiteGraoAvariado,2,2)}}
      </template>
    </Column>
  </DataTable>

</template>
<script>
import Formatacao from "@/utilities/Formatacao";

export default {
  props: {
    dados: { type: Object, required: true },
    campoInvalido: { type: Object, required: true },
    editavel: { type: Boolean, required: true}
  },
  watch:{
    dados(value){
      this.linha = [];
      if(!!value[0]) {
        this.linha.push(value[0]);
      }
    }
  },

  data() {
    return {
      itemSelecionado: {},
      linha: []

    }
  },

  created() {
  },

  updated() {
  },

  methods: {
    formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
      if (value!=null) return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
    },

    formatDateCustom(date, format) {
      return Formatacao.formatDateCustom(date, format);
    }
  }
}

</script>
