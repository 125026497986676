import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class AjusteCotaService {
    salvar(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/cota-embarque`, dados, config);
    }

    buscarCotas(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'id,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/cota-embarque/buscar-com-paginacao`, {params});
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/cota-embarque/inativar/${ id }`);
    }

}

export default new AjusteCotaService();