<template>
  <Dialog v-model:visible="visivel" :style="{heigth: '100%', width: '70%', maxWidth: '1200px'}" :closable="false" :modal="true" @hide="fechar()" @show="init()" >
    <template #header>
      <div class="flex align-items-center mt-2">
        <i class="pi pi-file mr-2" style="font-size: 1.4rem;"></i>
        <h4 style="margin:0;">{{ !!faturamento.id ? 'Editar' : 'Novo' }} Faturamento {{ !!faturamento.id ? faturamento.id : '' }} </h4>
      </div>
    </template>

    <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

    <br><div class="grid p-fluid formgrid align-items-center">

      <div class="col-12 lg:col-6">
        <div class="field">
          <label><strong>Origem<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              :dropdown="true"
              class="w-full"
              field="codigoNome"
              placeholder="Selecione..."
              v-model="faturamento.estabelecimento"
              :forceSelection="true"
              :suggestions="estabelecimentosFiltro"
              @complete="carregarEstabelecimentos($event)"
              @item-select="buscarDestinos"
              :disabled="true"
              :class="{ 'w-full p-invalid': campoInvalido.estabelecimento }"
              />
        </div>
      </div>

      <div class="col-12 lg:col-3">
        <div class="field">
          <label><strong>Destino<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              field="label"
              class="w-full"
              :dropdown="true"
              @complete="buscarDestinos($event)"
              placeholder="Selecione..."
              v-model="faturamento.destino"
              :suggestions="destinosFiltrados"
              @item-select="buscarTerminais"
              :forceSelection="true"
              :disabled="true"
              :class="{ 'w-full p-invalid': campoInvalido.destino }"
          />
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Nota Fiscal/Série</strong></label>
          <InputText  :disabled="true" class="w-full" v-model="notaSelecionada"/>
        </div>
      </div>

      <div class="col-12 lg:col-1">
        <div class="field">
          <label><strong>SE</strong></label>
          <InputText  :disabled="true" class="w-full" v-model="faturamento.solicitacaoEmbarqueId"/>
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="field">
          <label><strong>Terminal</strong></label>
          <AutoComplete
              field="label"
              class="w-full"
              :dropdown="true"
              @complete="buscarTerminais($event)"
              @focusout="carregarPedidos"
              v-model="faturamento.terminal"
              :suggestions="terminaisFiltrados"
              :forceSelection="true"
              :disabled="true"
              @item-select="carregarPedidos(false)"
          />
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="field">
          <label><strong>Cliente<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              field="codigoNome"
              class="w-full"
              :dropdown="true"
              @complete="pesquisarCliente($event)"
              placeholder="Selecione..."
              v-model="faturamento.cliente"
              :suggestions="clienteConsulta"
              :forceSelection="true"
              :disabled="true"
              @item-select="carregarPedidos(false)"
              :class="{ 'w-full p-invalid': campoInvalido.cliente }"
          />
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="field">
          <label><strong>Produto<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              field="nome"
              class="w-full"
              :dropdown="true"
              @complete="buscarProdutos($event)"
              placeholder="Selecione..."
              v-model="faturamento.tipoProduto"
              :suggestions="produtosFiltrados"
              @item-select="carregarPedidos(false)"
              :forceSelection="true"
              :disabled="true"
              :class="{ 'w-full p-invalid': campoInvalido.produto }"
          />
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="field">
          <label><strong>Grupo Produto<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              placeholder="Selecione..."
              dropdown
              v-model="faturamento.grupoProduto"
              field="codigoNome"
              class="w-full"
              id="1"
              :suggestions="gruposFiltrados"
              :forceSelection="true"
              @complete="buscarGrupoProduto"
              :disabled="true"
              @item-select="carregarPedidos(false)"
              :class="{ 'w-full p-invalid': campoInvalido.grupoProduto }"
          />
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Tipo Pesagem<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              placeholder="Selecione..."
              dropdown
              v-model="tipoPesagemSelecionada"
              field="descricao"
              class="w-full"
              :suggestions="tipoPesagemFiltro"
              :forceSelection="true"
              @complete="buscarTipoPesagem"
              :disabled="!!faturamento.id || loading"
              @item-select="limparPesagemManual();carregarPedidos(false)"
              :class="{ 'w-full p-invalid': campoInvalido.tipoPesagem }"
          />
        </div>
      </div>

      <div class="col-12 lg:col-4" v-if="!faturamento.id && tipoPesagemSelecionada.name=='AUTOMATICA'">
          <div class="field">
            <label><strong>Ticket Pesagem<span style="color:red;">&nbsp;*</span></strong></label>
            <PesagemZoom
                :codGrupoProduto="!!faturamento.grupoProduto ? faturamento.grupoProduto.codigo : 0"
                :codEstabelecimento="!!faturamento.estabelecimento ? faturamento.estabelecimento.codigo : 0"
                :dataDocPesagem="formatDateCustom(faturamento.dataNrDocPes,'DD/MM/YYYY')"
                :nroDocPesagem="faturamento.nrDocPes"
                @selecionado="selecionarPesagem"
                :class="{ 'w-full p-invalid': campoInvalido.ticket }"
                :disabled="!!faturamento.id || loading"
            />
          </div>
      </div>

      <div class="col-6 lg:col-2" v-if="!faturamento.id && tipoPesagemSelecionada.name=='MANUAL'">
        <div class="field">
          <label><strong>Ticket Pesagem<span style="color:red;">&nbsp;*</span></strong></label>
          <InputNumber :disabled="loading" v-model="faturamento.nrDocPes"
                        locale="pt-BR" :minFractionDigits="0"
                        :maxFractionDigits="0" class="w-full"/>          
        </div>
      </div>

      <div class="col-6 lg:col-2" v-if="!faturamento.id && tipoPesagemSelecionada.name=='MANUAL'">
        <div class="field">
          <label><strong>Data Pesagem<span style="color:red;">&nbsp;*</span></strong></label><br>
          <Calendar :disabled="loading" v-model="faturamento.dataNrDocPes" placeholder="Data" :maxDate="new Date()" class="w-full"/>
        </div>
      </div>

      <div class="col-6 lg:col-2" v-if="!!faturamento.id">
        <div class="field">
          <label><strong>Ticket Pesagem<span style="color:red;">&nbsp;*</span></strong></label><br>
          <InputNumber :disabled="true" v-model="nroDocPesSelecionado"
                        locale="pt-BR" :minFractionDigits="0"
                        :maxFractionDigits="0" class="w-full"/>
        </div>
      </div>

      <div class="col-6 lg:col-2" v-if="!!faturamento.id">
        <div class="field">
          <label><strong>Data Pesagem<span style="color:red;">&nbsp;*</span></strong></label><br>
          <InputText :disabled="true" v-model="dataNroDocPesSelecionada" class="w-full"/>
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Umidade (%)</strong></label>
          <InputNumber  :disabled="tipoPesagemSelecionada.name=='AUTOMATICA' || !!faturamento.id || loading" class="w-full" v-model="faturamento.percUmidade"
                        locale="pt-BR" :max="99" :minFractionDigits="2"
                        :maxFractionDigits="2"/>
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Impureza (%)</strong></label>
          <InputNumber  :disabled="tipoPesagemSelecionada.name=='AUTOMATICA' || !!faturamento.id || loading" class="w-full" v-model="faturamento.percImpureza"
                        locale="pt-BR" :max="99" :minFractionDigits="2"
                        :maxFractionDigits="2"/>
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Grão Avariado (%)</strong></label>
          <InputNumber  :disabled="tipoPesagemSelecionada.name=='AUTOMATICA' || !!faturamento.id || loading" class="w-full" v-model="faturamento.percGraoAvariado"
                        locale="pt-BR" :max="99" :minFractionDigits="2"
                        :maxFractionDigits="2"/>
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Placa Veículo&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;UF<span style="color:red;">&nbsp;*</span></strong></label>
          <InputMask :disabled="tipoPesagemSelecionada.name=='AUTOMATICA' || !!faturamento.id || loading" style="width:6rem" v-model="faturamento.placa" mask="aaa9*99" @focusout="faturamento.placa=faturamento.placa.toUpperCase()"/>
          <InputMask :disabled="!!faturamento.id || loading" style="margin-left:0.6rem;width:2.6rem" v-model="faturamento.ufPlaca" mask="aa" @focusout="faturamento.ufPlaca=faturamento.ufPlaca.toUpperCase()"/>
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Peso Balança<span style="color:red;">&nbsp;*</span></strong></label>
          <InputNumber :disabled="tipoPesagemSelecionada.name=='AUTOMATICA' || !!faturamento.id || loading"
                       class="w-full" @focusout="carregarPedidos($event)" id="quantidade"
                       :class="{ 'p-invalid': campoInvalido.quantidade }"
                       v-model="quantidadeSelecionada" mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Motorista<span style="color:red;">&nbsp;*</span></strong></label>
          <InputText :disabled="tipoPesagemSelecionada.name=='AUTOMATICA' || !!faturamento.id || loading" 
              @focusout="faturamento.motorista=faturamento.motorista.toUpperCase()"
              :class="{ 'p-invalid': campoInvalido.motorista }"
              class="w-full" v-model="faturamento.motorista"/>
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="field">
          <label><strong>Transportadora<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              placeholder="Selecione..."
              dropdown
              v-model="faturamento.transportadora"
              field="nomeCnpj"
              class="w-full"
              id="1"
              :suggestions="transportadorasFiltradas"
              :forceSelection="true"
              @complete="carregarTransportadoras"              
              :disabled="loading || !!faturamento.id"
              :class="{ 'w-full p-invalid': campoInvalido.transportadora }"
          />
        </div>
      </div>

      <ScrollPanel style="width: 100%; height: 200px">
      <div class="col-12" >
        <TabView v-model:activeIndex="tabViewActiveIndex">
          <TabPanel>
            <template #header>
              <i class="pi pi-mobile mr-2" style="font-size: 1rem;"></i>
              <span>Pedidos</span>
            </template>
            <div style="width:100%">
              <PedidosPendentesForm :campoInvalido="campoInvalido" :dados="faturamento.pedidos"/>
            </div>
          </TabPanel>
          <TabPanel>
            <template #header>
              <i class="pi pi-mobile mr-2" style="font-size: 1rem;"></i>
              <span>Classificação</span>
            </template>
            <div style="width: 100%">
              <ClassificacaoForm :campoInvalido="campoInvalido"
                                 :dados="faturamento.pedidos"
                                 :editavel="faturamento.tipoPesagem=='MANUAL' && !faturamento.id"
              />
            </div>
          </TabPanel>

        </TabView>
      </div>
      </ScrollPanel>

      <div class="flex p-inputgroup">

        <Button id="botaoSalvar"
                icon="pi pi-file"
                label="Emitir Nota"
                class="p-button-info"
                @click="salvar()"
                :loading="loading"
                :disabled="pedidoBloqueado || !!faturamento.id"
        ></Button>

        <Button
            id="botaoFechar"
            icon="pi pi-times"
            label="Fechar"
            :disabled="loading"
            class="p-button-danger ml-2"
              @click="fechar(false)"
        ></Button>
      </div>


    </div>
</Dialog>
</template>

<script>
import MunicipioService from '../../../service/MunicipioService.js';
import TerminalDesembarqueService from '../../../service/TerminaisDesembarqueService.js';
import Formatacao from '../../../utilities/Formatacao';
import ContratoZoom from '../../zoom/ContratoZoom.vue';
import EmbarqueTabelaOrigem from './EmbarqueTabelaOrigem.vue';
import EstabelecimentoService from "@/service/EstabelecimentoService";
import PedidoForm from "@/components/cadastro/pedido/PedidoForm.vue";
import FaturamentoForm from "@/components/cadastro/pedido/FaturamentoForm.vue";
import EmpresaCompraVendaFiltro from "@/components/cadastro/EmpresaCompraVendaFiltro.vue";
import TransportadoraZoom from "@/components/zoom/TransportadoraZoom.vue";
import TipoProdutoService from "@/service/TipoProdutoService";
import ListarPedidos from "./EmbarqueListarPedidos.vue";
import TransportadoraList from "@/components/cadastro/TransportadoraList.vue";
import SolicitacaoEmbarqueService from "@/service/SolicitacaoEmbarqueService";
import PesagemZoom from "@/components/zoom/PesagemZoom.vue";
import GrupoProdutoService from "@/service/GrupoProdutoService";
import FaturamentoService from "@/service/pedido/FaturamentoService";
import ClassificacaoForm from "@/components/cadastro/embarque/ClassificacaoForm.vue";
import PedidosPendentesForm from "@/components/cadastro/embarque/PedidosPendentesForm.vue";

export default {
    name: "ModalFaturamentoEmbarque",
    emits: ["fechar"],
    components: {
      TransportadoraList, ListarPedidos,
      ClassificacaoForm, PedidosPendentesForm,
      TransportadoraZoom, EmpresaCompraVendaFiltro,
      FaturamentoForm, PedidoForm, ContratoZoom,
      EmbarqueTabelaOrigem, PesagemZoom },
    props: {
      visivel: { type: Boolean, required: true },
      faturamento: { type: Object, required: true }
    },
    watch: {
      faturamento:function(value){
        if(!!value.id) {
          this.limpar();
          this.carregarDados();
        }else{
          this.tipoPesagemSelecionada = { name: "AUTOMATICA", descricao: "Automática" };
        }
      }
    },
    data() {
        return {
            mensagem: { ativo: false, gravidade: 'info', conteudo: '' },
            tipoPesagem: [
                { name: "AUTOMATICA", descricao: "Automática" },
                { name: "MANUAL", descricao: "Manual" },
            ],
            tipoPesagemSelecionada: { name: "AUTOMATICA", descricao: "Automática" },
            nroDocPesSelecionado: null,
            dataNroDocPesSelecionada: null,
            quantidadeSelecionada: null,
            tipoPesagemFiltro: [],
            clienteConsulta: {},
            estabelecimentosFiltro: [],
            destinosFiltrados: [],
            produtosFiltrados: [],
            transportadoraConsulta: {},
            terminaisFiltrados: [],
            gruposFiltrados: [],
            transportadorasFiltradas: [],

            campoInvalido: {},
            tabViewActiveIndex: 0,

            bloquearClienteTerminal: false,
            pedidoBloqueado: false,
            loading: false
        }
    },

  updated(){
  },

  methods: {
    init(){
    },

    fechar(sv){
      this.limpar();
      this.$emit("fechar", sv);
    },


    carregarDados(){
      this.faturamento.estabelecimento["codigoNome"] = this.faturamento.estabelecimento.codigo + " - " + this.faturamento.estabelecimento.nome;
      this.faturamento.destino["label"] = this.faturamento.destino.nome + " / " + this.faturamento.destino.uf;
      if(this.faturamento.terminal != undefined && this.faturamento.terminal != null) {
        this.faturamento.terminal["label"] = this.faturamento.terminal.nomeFantasia + " / " + this.faturamento.terminal.cnpj;
      }
      this.faturamento.cliente["codigoNome"] = this.faturamento.cliente.codigo + " - " + this.faturamento.cliente.nome;
      this.faturamento.grupoProduto["codigoNome"] = this.faturamento.grupoProduto.codigo + " - " + this.faturamento.grupoProduto.nome;
      this.buscarTipoPesagem();
      this.nroDocPesSelecionado = this.faturamento.nrDocPes;
      this.dataNroDocPesSelecionada = Formatacao.formatDateCustom(this.faturamento.dataNrDocPes,"DD/MM/YYYY");
      this.quantidadeSelecionada = this.faturamento.quantidade;
      this.notaSelecionada = !!this.faturamento.nrNotaFiscal ? this.faturamento.nrNotaFiscal + "/" + this.faturamento.serieNotaFiscal : "-";
      this.faturamento.transportadora["nomeCnpj"] = this.faturamento.transportadora.nomeFantasia + " - " + this.faturamento.transportadora.cnpj;
      this.faturamento.transportadora["transportadoraId"] = this.faturamento.transportadora.id;
    },

      buscarTipoPesagem(event) {
        if (event==undefined || !event.query.trim().length) {
          this.tipoPesagemFiltro = [...this.tipoPesagem];
          for(let c of this.tipoPesagemFiltro){
            if(!!this.faturamento.tipoPesagem){
              if(c.name == this.faturamento.tipoPesagem){
                this.tipoPesagemSelecionada = c;
              }
            }

          }
        } else {
          this.tipoPesagemFiltro = this.tipoPesagem.filter((item) => {
            return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      },

      buscarGrupoProduto(event) {
        if(!this.faturamento.tipoProduto) {
          this.gruposFiltrados = [];
          return;
        }
        const busca = !event.query.trim().length ? undefined : event.query;
        GrupoProdutoService.buscarPorCodigoOuDescricaoComParametroEntradaReDiferenteNaoPermite(busca)
            .then(({ data }) => {
              if (!!data) {
                let lista = [];
                this.gruposFiltrados = data.map(item => {
                  if(item.descricao.includes(this.faturamento.tipoProduto.nome)) {
                    item["codigoNome"] = item.fmCodigo + " - " + item.descricao;
                    item["codigo"] = item.fmCodigo;
                    item["nome"] = item.descricao;
                    lista.push(item);
                  }
                });
                this.gruposFiltrados = lista;
              }
            })
            .catch(error => {
              this.gruposFiltrados = [];
              const toast = {
                severity: 'error',
                summary: 'Falha ao carregar os tipos de produto.',
                detail: error,
                life: 7500
              }

              if (!!error && !!error.response && !!error.response.data) {
                const { message } = error.response.data;
                toast["detail"] = message;
              }

              this.$toast.add(toast);
              this.grupoProdutoFiltro = [];
            });
      },

      salvar(){
        if(!this.validarCampos()) return;

        this.loading = true;

        const dados = this.montaJson();
          FaturamentoService.salvar(dados)
          .then(({data})=> {

            const notasFiscais = data.faturamentos.map(nota => {
                return nota.nrNotaFiscal;
              });

            this.$toast.add({
              severity:'success',
              summary: 'Sucesso.',
              detail:`Nota fiscal ` + notasFiscais + ` emitida com sucesso. Favor aguardar a autorização da nota pela receita federal.`,
              life: 7500

            });
            this.loading = false;
            this.fechar(true);

          })
          .catch(error => {
            let msg = "Não foi possível cadastrar o faturamento.";

            if(!!error && !!error.response && !!error.response.data) {
              const { message, exception } = error.response.data;
              msg = error.response.data.exception;
            }

            this.loading = false;
            this.mostraMensagem(msg);
          });
      },

      validarCampos() {
        let campos = [];

        if(this.faturamento.estabelecimento == null) {
          campos.push("Origem");
          this.campoInvalido.estabelecimento = true;
        }
        else {
          this.campoInvalido.estabelecimento = false;
        }

        if(this.faturamento.destino == null) {
          campos.push("Destino");
          this.campoInvalido.destino = true;
        }
        else {
          this.campoInvalido.destino = false;
        }

        if(this.faturamento.cliente == null) {
          campos.push("Cliente");
          this.campoInvalido.cliente = true;
        }
        else {
          this.campoInvalido.cliente = false;
        }

        if(this.faturamento.tipoProduto == null) {
          campos.push("Produto");
          this.campoInvalido.produto = true;
        }
        else {
          this.campoInvalido.produto = false;
        }

        if(this.faturamento.grupoProduto == null) {
          campos.push("Grupo Produto");
          this.campoInvalido.grupoProduto = true;
        }
        else {
          this.campoInvalido.grupoProduto = false;
        }

        if(this.tipoPesagemSelecionada == null) {
          campos.push("Tipo Pesagem");
          this.campoInvalido.tipoPesagem = true;
        }
        else {
          this.campoInvalido.tipoPesagem = false;
        }

        if(this.faturamento.transportadora == null) {
          campos.push("Transportadora");
          this.campoInvalido.transportadora = true;
        }
        else {
          this.campoInvalido.transportadora = false;
        }

        if(this.faturamento.nrDocPes == null) {
          campos.push("Ticket Pesagem");
          this.campoInvalido.ticket = true;
        }
        else {
          this.campoInvalido.ticket = false;
        }

        if(this.faturamento.placa == null || this.faturamento.ufPlaca == null) {
          campos.push("Placa/UF");
          this.campoInvalido.placa = true;
        }
        else {
          this.campoInvalido.placa = false;
        }

        if(this.quantidadeSelecionada == null || this.quantidadeSelecionada==0) {
          campos.push("Peso Balança");
          this.campoInvalido.quantidade = true;
        }
        else {
          this.campoInvalido.quantidade = false;
        }

        if(this.faturamento.motorista == null) {
          campos.push("Motorista");
          this.campoInvalido.motorista = true;
        }
        else {
          this.campoInvalido.motorista = false;
        }

        if(campos.length > 0) {
          let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

          if(campos.length > 1) {
            mensagem = "Por favor, preencher os campos: ";
            mensagem += campos.join(", ");

            const indice = mensagem.lastIndexOf(", ");
            mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
          }

          this.$toast.add({
            severity: 'warn',
            summary: 'Não foi possível realizar a alteração.',
            detail: mensagem,
            life: 15000
          });
        }

        return campos.length === 0;
      },

      selecionarPesagem(pesagem){
          this.faturamento.placa = pesagem.placa;
          this.faturamento.placaUf = pesagem.ufCaminhao;
          this.faturamento.motorista = pesagem.motorista;
          this.quantidadeSelecionada = pesagem.pesoLiquido;
          this.faturamento.nrDocPes = pesagem.nroDocPesagem;
          this.faturamento.dataNrDocPes = pesagem.data;
          if(this.faturamento.tipoPesagem=='AUTOMATICA') {
            this.faturamento.percUmidade = pesagem.clUmidade;
            this.faturamento.percImpureza = pesagem.clImpureza;
            this.faturamento.percGraoAvariado = pesagem.clChuAvar;
          }
          this.carregarPedidos(false);
      },

    atualizaPeso(event) {
      let quantidade = !!this.faturamento.quantidade ? this.faturamento.quantidade : 0;

      if(!!event) {
        const { value, id } = event.target;

        if(!!value) {
          let valor = value.replace(/\./g, "");
          valor = valor.replace(/\,/, ".");
          valor = parseFloat(valor);

          if(id == "quantidade") {
            quantidade = valor;
          }
        }
      }
      this.faturamento.quantidade = quantidade;
      this.carregarPedidos()
    },

    limparPesagemManual(){
      this.faturamento.nrDocPes=null;
      this.faturamento.dataNrDocPes = new Date();
      this.faturamento.placa=null;
      this.faturamento.ufPlaca=null;
      this.quantidadeSelecionada=null;
      this.faturamento.motorista=null;
    },

    carregarPedidos(dados){
      if(!this.quantidadeSelecionada || this.faturamento.id > 0){return;}
      if (!dados.estabelecimento) {
        if(this.faturamento.dataNrDocPes) {this.dataNroDocPesSelecionada = this.faturamento.dataNrDocPes;}
        dados = {
          estabelecimento: !!this.faturamento.estabelecimento ? this.faturamento.estabelecimento : null,
          destino: !!this.faturamento.destino ? this.faturamento.destino : null,
          terminal: !!this.faturamento.terminal ? this.faturamento.terminal : null,
          cliente: !!this.faturamento.cliente ? this.faturamento.cliente : null,
          tipoProduto: !!this.faturamento.tipoProduto ? this.faturamento.tipoProduto : null,
          tipoPesagem: !!this.tipoPesagemSelecionada ? this.tipoPesagemSelecionada.name : null,
          grupoProduto: !!this.faturamento.grupoProduto ? this.faturamento.grupoProduto : null,
          nrDocPes: !!this.faturamento.nrDocPes ? this.faturamento.nrDocPes : null,
          dataNrDocPes: Formatacao.formatDateCustom(this.dataNroDocPesSelecionada,"YYYY-MM-DD"),
          quantidade: this.quantidadeSelecionada,
          placa: this.faturamento.placa,
          motorista: this.faturamento.motorista,
          transportadoraId: !!this.faturamento.transportadora ? this.faturamento.transportadora.id : null,
          solicitacaoEmbarqueId: this.faturamento.solicitacaoEmbarqueId,
          pedidos: []
        };
      }
      FaturamentoService.listarPedidosPendentes(dados)
          .then(({data})=> {
            if(data.dto.value.pedidos.length>0) {
              this.mensagem.ativo = false;
              this.faturamento.pedidos = data.dto.value.pedidos;
              if(data.dto.value.pedidos[0].pedidoBloqueado){
                this.mostraMensagem("Pedido Bloqueado!");
                this.pedidoBloqueado = true;
              } else {
                this.mostraMensagem();
                this.pedidoBloqueado = false;
              }
            }else{
              this.mostraMensagem();
              this.faturamento.pedidos = [];
            }
          })
          .catch(error => {
            if(this.faturamento.estabelecimento && this.faturamento.destino && this.faturamento.cliente
                  && this.faturamento.tipoProduto && this.faturamento.grupoProduto && this.tipoPesagemSelecionada && !!this.quantidadeSelecionada) {

                let msg = error.response.data.exception;
                this.mostraMensagem(msg);
                this.faturamento.pedidos = [];
            }
          });
    },

    mostraMensagem(msg){
      if(msg==null || msg==""){
        this.mensagem.ativo = false;
      } else {
        this.mensagem.ativo = true;
      }
      this.mensagem.conteudo = msg;
      this.mensagem.gravidade = 'warn';
    },

    carregarTransportadoras(dados){
        if(this.faturamento.dataNrDocPes) {this.dataNroDocPesSelecionada = this.faturamento.dataNrDocPes;}
        dados = {
          estabelecimento: !!this.faturamento.estabelecimento ? this.faturamento.estabelecimento : null,
          destino: !!this.faturamento.destino ? this.faturamento.destino : null,
          terminal: !!this.faturamento.terminal ? this.faturamento.terminal : null,
          cliente: !!this.faturamento.cliente ? this.faturamento.cliente : null,
          tipoProduto: !!this.faturamento.tipoProduto ? this.faturamento.tipoProduto : null,
          tipoPesagem: !!this.tipoPesagemSelecionada ? this.tipoPesagemSelecionada.name : null,
          grupoProduto: !!this.faturamento.grupoProduto ? this.faturamento.grupoProduto : null,
          nrDocPes: !!this.faturamento.nrDocPes ? this.faturamento.nrDocPes : null,
          dataNrDocPes: Formatacao.formatDateCustom(this.dataNroDocPesSelecionada,"YYYY-MM-DD"),
          quantidade: this.faturamento.quantidade,
          placa: this.faturamento.placa,
          motorista: this.faturamento.motorista,
          transportadoraId: !!this.faturamento.transportadora ? this.faturamento.transportadora.id : null,
          pedidos: []
      }
      FaturamentoService.listarTransportadoras(dados)
          .then(({data})=> {
            if(data.dto.value.transportadorasAutorizadas.length>0) {
              const objetoFiltro = {};
              const lista = data.dto.value.transportadorasAutorizadas.filter((valor) => {
                return objetoFiltro.hasOwnProperty(valor.transportadoraId) ? false : (objetoFiltro[valor.transportadoraId] = true);
              });
              this.transportadorasFiltradas = lista.map(item => {
                item["nomeCnpj"] = item["nomeFantasia"] + " - " + item["cnpj"];
                return item;
              });
            }else{
              this.transportadorasFiltradas = [];
            }
          })
          .catch(error => {
              this.transportadorasFiltradas = [];
          });
    },

    montaJson(){
      const obj = Object.assign({}, this.faturamento);
      obj.tipoPesagem = this.tipoPesagemSelecionada.name;
      obj.quantidade = this.quantidadeSelecionada;
      if(this.tipoPesagemSelecionada.name=="MANUAL"){
        this.dataNroDocPesSelecionada = this.faturamento.dataNrDocPes;
      }
      if(!this.faturamento.id) {
        obj.dataNrDocPes = Formatacao.formatDateCustom(this.dataNroDocPesSelecionada, "YYYY-MM-DD");
      } else {
        obj.dataNrDocPes = this.faturamento.dataNrDocPes;
      }
      obj.nomeGrupoProduto = this.faturamento.grupoProduto.nome;
      obj.transportadora.id = obj.transportadora.transportadoraId;
      return obj;
    },

    carregarEstabelecimentos(event) {
      const codigoOuNomeFantasia = !event.query.trim().length ? undefined : event.query;
      this.faturamento.destino = null;
      this.faturamento.terminal = null;
      this.faturamento.cliente = null;
      EstabelecimentoService.buscarPorUsuarioAutenticadoComHierarquiaEstabelecimentoQueSejaSilo(codigoOuNomeFantasia)
          .then(({ data }) => {
            if(!!data) {
              this.estabelecimentosFiltro = data.map(item => {
                item["codigoNome"] = item.codigo + " - " + item.nomeFantasia;
                item["nome"] = item.nomeFantasia;
                return item;
              });
            }
          })
          .catch(error => {
            this.estabelecimentosFiltro = [];
          });
    },

    pesquisarCliente(event) {
      const terminalId = !!this.faturamento.terminal ? this.faturamento.terminal.id : null;
      SolicitacaoEmbarqueService.buscarComEmbarquePendente(this.faturamento.estabelecimento.codigo,
                                                           this.faturamento.destino.id,
                                                           terminalId)
          .then(({ data }) => {
            if(!!data) {
              this.transportadorasFiltradas = [];
              this.faturamento.transportadora = null;
              this.faturamento.pedidos = null;

              this.clienteConsulta = data.map(item => {
                item["codigoNome"] = item.codigo + " - " + item.nome;
                item["nome"] = item.nome;
                return item;
              });
              if(this.clienteConsulta.length==1){
                this.faturamento.cliente = this.clienteConsulta[0];
              }
            }
          })
          .catch(error => {
            this.clienteConsulta = {}

            const toast = {
              severity: 'error',
              summary: 'Falha ao carregar os clientes.',
              detail: error,
              life: 7500
            }

            if(!!error && !!error.response && !!error.response.data) {
              const { message } = error.response.data;
              toast["detail"] = message;
            }

            this.$toast.add(toast);
          });
    },

    buscarDestinos(event){
      if(!this.faturamento.estabelecimento){this.destinosFiltrados=[];return;}
      MunicipioService.buscarComEmbarquePendente(this.faturamento.estabelecimento.codigo)
          .then(({ data }) => {
            if(!!data && data.length > 0) {
              this.destinosFiltrados = data.map(municipio => {
                municipio["label"] = `${ municipio.nome } - ${ municipio.uf }`;
                return municipio;
              });
              if(this.destinosFiltrados.length==1){
                this.faturamento.destino = this.destinosFiltrados[0];
                this.buscarTerminais();
              }
            } else{
              this.destinosFiltrados =[];
            }
          })
          .catch(error => {
            this.destinosFiltrados = [];
          });
    },

    buscarProdutos(event){
      const dados = event==undefined || !event.query.trim().length ? null : event.query;
      TipoProdutoService.getAutoComplete(dados)
          .then(({ data }) => {
            this.produtosFiltrados = data;
          })
          .catch(error => {
            this.produtosFiltrados = [];
          });
    },

    buscarTerminais(event) {
        if(!this.faturamento.destino){
          this.terminaisFiltrados = [];
          return;
        }
        TerminalDesembarqueService.buscarComEmbarquePendente(this.faturamento.estabelecimento.codigo,this.faturamento.destino.id)
            .then(({ data }) => {
              if(!!data && data.length > 0) {
                this.terminaisFiltrados = data.map(terminal => {
                  terminal["label"] = `${ terminal.nomeFantasia } - ${ terminal.cnpj }`;
                  return terminal;
                });
                if(this.terminaisFiltrados.length==1 && !this.faturamento.id){
                  this.faturamento.terminal = this.terminaisFiltrados[0];
                  this.pesquisarCliente();
                }
              } else{
                this.terminaisFiltrados = [];
              }
            })
            .catch(error => {
              this.terminaisFiltrados = [];
            });

    },

    limpar() {
      this.mensagem = { ativo: false };
      this.quantidadeSelecionada = null;

    },

    escreveObservacao(dado){
      let terminal = !!this.faturamento.terminal ? this.faturamento.terminal.label : "N/A";
      let texto = dado.observacaoNota + ", Terminal de descarga: " + terminal;

      this.faturamento.observacaoNota = texto;
    },

      formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(data, format){
            return Formatacao.formatDateCustom(data, format);
        }
    }

}
</script>

<style scoped>

    .valor-campo-numerico{
        text-align: right; 
        font-weight: bold;
    }
    .sigla-campo-numerico{
        text-align: left; 
    }
</style>