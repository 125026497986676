import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class ClassificadoraService {

    getClassificadoras(pagina, qtdRegistro, sort, filtro, situacao) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/classificadora/buscar-com-paginacao`, {
            params: {
                page: pagina,
                size: qtdRegistro,
                sort: sort,
                filtro: filtro,
                situacao: situacao
            }
        });
    }

}
export default new ClassificadoraService();