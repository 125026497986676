<template>
  <Dialog v-model:visible="visivel" :style="{width: '80%'}" :modal="true" @hide="fechar()" @show="init()" >
    <template #header>
      <div class="flex align-items-center mt-2">
        <i class="pi pi-file mr-2" style="font-size: 1.4rem;"></i>
        <h4 style="margin:0;">{{ !!solicitacao.id ? 'Editar' : 'Nova' }} Solicitação Embarque {{ !!solicitacao.id ? solicitacao.id : '' }} </h4>
      </div>
    </template>

    <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

    <br><div class="grid p-fluid formgrid align-items-center">

      <div class="col-12 lg:col-4">
        <div class="field">
          <label><strong>Estabelecimento<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              :dropdown="true"
              class="w-full"
              field="codigoNomeFantasia"
              placeholder="Selecione..."
              v-model="estabelecimentoSelecionado"
              :forceSelection="true"
              :suggestions="estabelecimentosFiltro"
              @complete="carregarEstabelecimentos($event)"
              @item-select="carregarPedidos(false)"
              :disabled="!!solicitacao.id"
              :class="{ 'w-full p-invalid': campoInvalido.estabelecimento }"
              />
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="field">
          <label><strong>Cliente<span style="color:red;">&nbsp;*</span></strong></label>
          <EmpresaCompraVendaFiltro
              :disabled="!!solicitacao.id"
              :selecionado="solicitacao.cliente"
              :campoObrigatorio="true"
              :paginacao="clienteConsulta"
              @pesquisar="pesquisarCliente"
              @selecionado="selecionarCliente"
              onblur="this.mostraMensagem()"
              :class="{ 'w-full p-invalid': campoInvalido.cliente }"
          />
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>CNPJ</strong></label>
          <InputText disabled class="w-full" v-model="cnpjSelecionado"/>
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Cota Padrão<span style="color:red;">&nbsp;*</span></strong></label>
          <InputNumber :disabled="!!solicitacao.id" class="w-full"
                       v-model="cotaSelecionada" :min="0" :max="999999"
                       :class="{ 'w-full p-invalid': campoInvalido.cota }"
          />
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Inicio Embarque<span style="color:red;">&nbsp;*</span></strong></label>
          <div class="flex align-items-center">
            <div class="overflow-hidden">
              <Calendar
                  class="w-full"
                  v-model="inicioEmbarqueSelecionado"
                  autocomplete="off"
                  dateFormat="dd/mm/y"
                  :minDate="new Date()"
                  :disabled="!!solicitacao.id"
                  :class="{ 'w-full p-invalid': campoInvalido.inicioEmbarque }"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Produto<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              field="nome"
              class="w-full"
              :dropdown="true"
              @complete="buscarProdutos($event)"
              placeholder="Selecione..."
              v-model="produtoSelecionado"
              :suggestions="produtosFiltrados"
              @item-select="carregarPedidos(false)"
              :forceSelection="true"
              :disabled="!!solicitacao.id"
              :class="{ 'w-full p-invalid': campoInvalido.produto }"
          />
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Classificação<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              placeholder="Selecione..."
              dropdown
              v-model="classificacaoSelecionada"
              field="descricao"
              class="w-full"
              id="1"
              :suggestions="classificacaoFiltro"
              :forceSelection="true"
              @complete="buscarClassificacao"
              :disabled="false"
              :class="{ 'w-full p-invalid': campoInvalido.classificacao }"
              @item-select="selecionarClassificacao"
          />
        </div>
      </div>

      <div class="col-12 lg:col-4">
        <div class="field">
          <label><strong>Classificadora</strong></label>
          <AutoComplete
              field="nome"
              class="w-full"
              :dropdown="true"
              @complete="buscarClassificadora($event)"
              placeholder="Selecione..."
              v-model="classificadoraSelecionada"
              :suggestions="classificadorasFiltradas"
              @item-select="selecionarClassificadora"
              :forceSelection="true"
              :disabled="!!classificacaoSelecionada && classificacaoSelecionada.name=='CLIENTE'"
              :class="{ 'w-full p-invalid': campoInvalido.classificadora }"
          />
        </div>
      </div>

      <div class="col-12 lg:col-2">
        <div class="field">
          <label><strong>Valor Frete (R$/TON)<span style="color:red;">&nbsp;*</span></strong></label>
          <InputNumber :disabled="!!solicitacao.id" class="w-full"
                       v-model="valorFreteSelecionado" locale="pt-BR"
                       :minFractionDigits="2" :maxFractionDigits="2"
                       :min="0" :max="999999999"
                       :class="{ 'w-full p-invalid': campoInvalido.valorFrete }"
          />
        </div>
      </div>

      <div class="col-12 lg:col-4">
        <div class="field">
          <label><strong>Destino<span style="color:red;">&nbsp;*</span></strong></label>
          <AutoComplete
              field="label"
              class="w-full"
              :dropdown="true"
              @complete="buscarDestinos($event)"
              placeholder="Selecione..."
              v-model="destinoSelecionado"
              :suggestions="destinosFiltrados"
              @item-select="carregarPedidos(false); terminalSelecionado = null"
              :forceSelection="true"
              :disabled="!!solicitacao.id"
              :class="{ 'w-full p-invalid': campoInvalido.destino }"
          />
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="field">
          <label><strong>Terminal</strong></label>
          <AutoComplete
              field="label"
              class="w-full"
              :dropdown="true"
              @complete="buscarTerminais($event)"
              placeholder="Selecione..."
              v-model="terminalSelecionado"
              :suggestions="terminaisFiltrados"
              :forceSelection="true"
              :disabled="!destinoSelecionado"
          />
        </div>
      </div>

      <ScrollPanel style="width: 100%; height: 250px">
      <div class="col-12" >
        <TabView v-model:activeIndex="tabViewActiveIndex">
          <TabPanel>
            <template #header>
              <i class="pi pi-mobile mr-2" style="font-size: 1rem;"></i>
              <span>Pedidos</span>
            </template>
            <div style="width:100%">
              <ListarPedidos :tela="'embarque'" :gestao="gestao" :modo="!solicitacao.id ? 'Nova' : 'Editar'"
              />
            </div>
          </TabPanel>
          <TabPanel>
            <template #header>
              <i class="pi pi-mobile mr-2" style="font-size: 1rem;"></i>
              <span>Transportadoras</span>
            </template>
            <div style="width:100%">
              <TransportadoraList :tela="'embarque'"
                                  :transportadoras="transportadoras"
                                  :modo="!solicitacao.id ? 'Nova' : 'Editar'"
                                  :solicitacaoEmbarque="solicitacaoEmbarque"
              />
            </div>
          </TabPanel>
        </TabView>
      </div>
      </ScrollPanel>

      <div class="col-12 label">
        <label> <strong>Observações:</strong> </label>
        <Textarea class="w-full" id="observacao" v-model="observacaoSolicitacaoEmbarque" @focus="press()" variant="filled" rows="2"></Textarea><br><br>
      </div>


      <div class="flex justify-content-left">

        <Button id="botaoSalvar"
                icon="pi pi-save"
                label="Salvar"
                class="p-button-info"
                style="width: 65%"
                @click="salvar()"
        ></Button>

        <Button
            icon="pi pi-times"
            label="Cancelar"
            class="p-button-danger ml-2"
            style="width: 80%"
            @click="fechar()"
        ></Button>
      </div>


    </div>
</Dialog>
</template>

<script>
import MunicipioService from '../../../service/MunicipioService.js';
import TerminalDesembarqueService from '../../../service/TerminaisDesembarqueService.js';
import Formatacao from '../../../utilities/Formatacao';
import ContratoZoom from '../../zoom/ContratoZoom.vue';
import EmbarqueTabelaOrigem from './EmbarqueTabelaOrigem.vue';
import EstabelecimentoService from "@/service/EstabelecimentoService";
import PedidoForm from "@/components/cadastro/pedido/PedidoForm.vue";
import FaturamentoForm from "@/components/cadastro/pedido/FaturamentoForm.vue";
import EmpresaCompraVendaFiltro from "@/components/cadastro/EmpresaCompraVendaFiltro.vue";
import TransportadoraZoom from "@/components/zoom/TransportadoraZoom.vue";
import TipoProdutoService from "@/service/TipoProdutoService";
import ListarPedidos from "./EmbarqueListarPedidos.vue";
import TransportadoraList from "@/components/cadastro/TransportadoraList.vue";
import ClassificadoraService from "@/service/ClassificadoraService";
import SolicitacaoEmbarqueService from "@/service/SolicitacaoEmbarqueService";
import TransportadoraService from "@/service/TransportadoraService";
import StorageService from "@/service/StorageService";

export default {
    name: "GestaoEmbarqueFilter",
    emits: ["fechar"],
    components: {
      TransportadoraList, ListarPedidos,
      EmpresaCompraVendaFiltro, FaturamentoForm, PedidoForm,
      ContratoZoom, EmbarqueTabelaOrigem ,
      TransportadoraZoom },
    props: {
      visivel: { type: Boolean, required: true },
      solicitacao: { type: Object, required: true }
    },
    watch: {
      solicitacao:function(value){
        if(!!value. id) {
          this.carregarDados();
        } else {
          this.solicitacao["cliente"] = {};
        }
      }
    },
    data() {
        return {
            classificacaoSelecionada: null,
            classificacaoFiltro:[],
            classificacao: [
                { name: "CLIENTE", descricao: "Cliente" },
                { name: "PROPRIA", descricao: "Própria" },
            ],
            clienteConsulta: {},
            estabelecimentoSelecionado: null,
            estabelecimentosFiltro: [],
            destinosFiltrados: [],
            destinoSelecionado: null,
            produtosFiltrados: [],
            produtoSelecionado: null,
            transportadoraConsulta: {},
            transportadoraSelecionada: 0,
            periodoInicioSelecionado: null,
            periodoFimSelecionado: null,
            notaSelecionada: null,
            contratoInicioSelecionado: null,
            contratoFimSelecionado: null,
            terminalSelecionado: null,
            terminaisFiltrados: [],
            classificadoraSelecionada: null,
            classificadorasFiltradas: [],
            observacaoSolicitacaoEmbarque: null,
            cnpjSelecionado: null,
            cotaSelecionada: null,
            inicioEmbarqueSelecionado: null,
            valorFreteSelecionado: null,
            gestao: {},
            transportadoras: {},
            tabViewActiveIndex: 0,

            solicitacaoEmbarque: {},
            campoInvalido: {},
            mensagem: { ativo: false, gravidade: 'info', conteudo: '' },
        }
    },

  updated(){
    //if(!this.solicitacao.id) {this.carregarTransportadoras()};
  },

  methods: {
    init(){
      if (!this.solicitacao.id) {this.limpar();}
    },

    fechar(sv){
      this.limpar();
      this.$emit("fechar",sv);
    },

    carregarDados(){

        this.cotaSelecionada = this.solicitacao.cota;
        this.inicioEmbarqueSelecionado = Formatacao.formatDateCustom(this.solicitacao.inicioEmbarque,"DD/MM/YY");
        this.valorFreteSelecionado = this.solicitacao.valorFrete;
        this.observacaoSolicitacaoEmbarque = this.solicitacao.observacao;
        this.selecionarCliente(this.solicitacao.cliente.codigo,this.solicitacao.cliente);
        this.carregarEstabelecimentos(undefined);
        this.pesquisarCliente();
        this.buscarClassificacao(undefined);
        this.buscarClassificadora(undefined);
        this.buscarDestinos(undefined);
        this.buscarTerminais(undefined);
        this.buscarProdutos(undefined);

        const dados = {
              codEstabelecimento:this.solicitacao.estabelecimento.codigo,
              idCliente: this.solicitacao.cliente.id,
              idProduto: this.solicitacao.tipoProduto.id,
              idDestino: this.solicitacao.destino.id,
              idSolicitacao: this.solicitacao.id
        };
        if(!!this.solicitacao.id) {
          this.carregarPedidos(dados);
          this.carregarListaTransportadoras();
        }

    },

    salvar(){
        if(!this.validarCampos()) {return;}

        const dados = this.montaJson();
        SolicitacaoEmbarqueService.salvar(dados)
        .then(({data})=> {
          this.solicitacaoEmbarque = data;

          this.$toast.add({
            severity:'success',
            summary: 'Sucesso.',
            detail:`Solicitação ` + data.id + ` salva com sucesso.`,
            life: 7500

          });
          this.fechar(true);

        })
        .catch(error => {
          const toast = {
            severity: 'error',
            summary: 'Não foi possível salvar a solicitação de embarque.',
            detail: error.response.data.exception,
            life: 7500
          }

          if(!!error && !!error.response && !!error.response.data) {
            const { message, exception } = error.response.data;

            if(exception === "Pedido obrigatório") {
              toast["detail"] = exception;
            }
            else {
              toast["detail"] = message + ". " +exception;
            }
          }

          this.$toast.add(toast);
        });
    },

    carregarPedidos(dados){
      this.mostraMensagem("");
      if (!dados) {
        dados = {
          codEstabelecimento: !!this.estabelecimentoSelecionado ? this.estabelecimentoSelecionado.codigo : null,
          idCliente: !!this.solicitacao.cliente ? this.solicitacao.cliente.id : null,
          idProduto: !!this.produtoSelecionado ? this.produtoSelecionado.id : null,
          idDestino: !!this.destinoSelecionado ? this.destinoSelecionado.id : null,
          idSolicitacao: !!this.solicitacao ? this.solicitacao.id : null
        };
      }
      SolicitacaoEmbarqueService.listarPedidosSugeridos(dados)
          .then(({data})=> {
            if(data.length>0) {
              this.$data.gestao = data;
            }else{
              this.$data.gestao = [];
              if(!!this.estabelecimentoSelecionado && !!this.solicitacao.cliente && !!this.produtoSelecionado &&
                  !!this.classificacaoSelecionada && !!this.classificacaoSelecionada && !!this.destinoSelecionado) {
                this.mostraMensagem("Nenhum pedido encontrado.");
              }
            }
          })
          .catch(error => {
            const toast = {
              severity: 'error',
              summary: 'Não foi possível salvar a solicitação de embarque.',
              detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!",
              life: 7500
            }

            if(!!error && !!error.response && !!error.response.data) {
              const { message, exception } = error.response.data;

              if(exception === "Pedido obrigatório") {
                toast["detail"] = exception;
              }
              else {
                toast["detail"] = message;
              }
            }

            this.$toast.add(toast);
          });
    },

    carregarListaTransportadoras(){
      let todasTransportadoras = {};
      TransportadoraService.buscarComPaginacao(undefined)
          .then(({data})=> {
            this.transportadoraConsulta = data.content;

            let transportadoraFinal = [];
            let achou = false;
            let atual = {};

            for(let t of this.transportadoraConsulta) {
              let trans = {};
              for (let tt of this.solicitacao.transportadoras) {
                if (tt.transportadoraId == t.id) {
                  achou = true;
                  atual = tt;
                  break;
                }
              }
              if(achou){
                trans["id"] = atual.id;
                trans["transportadoraId"] = atual.transportadoraId;
                trans["codigoErp"] = atual.codigoErp;
                trans["valorFrete"] = atual.valorFrete;
                trans["solicitacaoEmbarqueId"] = atual.solicitacaoEmbarqueId;
                trans["nomeFantasia"] = atual.nomeFantasia;
                trans["cnpj"] = atual.cnpj;
                trans["justificativaCancelamento"] = atual.justificativaCancelamento;
                trans["telefone"] = t.telefone;
                trans["contato"] = t.contato;
                trans["status"] = atual.status;
                trans["checked"] = true;
                trans["blocked"] = true;
                transportadoraFinal.push(trans);
                achou = false;
              }
            }
            this.$data.transportadoras = transportadoraFinal;
          });

    },

    validarCampos() {
      let campos = [];

      if(this.estabelecimentoSelecionado == null) {
        campos.push("Estabelecimento");
        this.campoInvalido.estabelecimento = true;
      }
      else {
        this.campoInvalido.estabelecimento = false;
      }

      if(this.destinoSelecionado == null) {
        campos.push("Destino");
        this.campoInvalido.destino = true;
      }
      else {
        this.campoInvalido.destino = false;
      }

      if(this.solicitacao.cliente == null || this.solicitacao.cliente == 0) {
        campos.push("Cliente");
        this.campoInvalido.cliente = true;
      }
      else {
        this.campoInvalido.cliente = false;
      }

      if(this.produtoSelecionado == null) {
        campos.push("Produto");
        this.campoInvalido.produto = true;
      }
      else {
        this.campoInvalido.produto = false;
      }

      if(this.classificacaoSelecionada == null) {
        campos.push("Classificação");
        this.campoInvalido.classificacao = true;
      }
      else {
        this.campoInvalido.classificacao = false;
      }

      if(this.classificadoraSelecionada == null && this.classificacaoSelecionada.name=='PROPRIA') {
        campos.push("Classificadora");
        this.campoInvalido.classificadora = true;
      }
      else {
        this.campoInvalido.classificadora = false;
      }

      if(this.transportadoraSelecionada == null) {
        campos.push("Transportadora");
        this.campoInvalido.transportadora = true;
      }
      else {
        this.campoInvalido.transportadora = false;
      }

      if(this.valorFreteSelecionado == null) {
        campos.push("Valor Frete");
        this.campoInvalido.valorFrete = true;
      }
      else {
        this.campoInvalido.valorFrete = false;
      }

      if(this.cotaSelecionada == null) {
        campos.push("Cota Padrão");
        this.campoInvalido.cota = true;
      }
      else {
        this.campoInvalido.cota = false;
      }

      if(this.inicioEmbarqueSelecionado == null) {
        campos.push("Inicio Embarque");
        this.campoInvalido.inicioEmbarque = true;
      }
      else {
        this.campoInvalido.inicioEmbarque = false;
      }

      if(campos.length > 0) {
        let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

        if(campos.length > 1) {
          mensagem = "Por favor, preencher os campos: ";
          mensagem += campos.join(", ");

          const indice = mensagem.lastIndexOf(", ");
          mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
        }

        this.$toast.add({
          severity: 'warn',
          summary: 'Não foi possível realizar a alteração.',
          detail: mensagem,
          life: 15000
        });
      }

      return campos.length === 0;
    },

    carregarTransportadoras() {
      TransportadoraService.buscarComPaginacao(undefined)
          .then(({ data }) => {
            if (data) {
              this.$data.transportadoras = data.content;
            }
          })
          .catch(error => {
            const toast = {
              severity: 'error',
              summary: 'Falha ao consultar Transportadoras',
              detail: 'Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!',
              life: 7500
            };

            if (!!error && !!error.response && !!error.response.data) {
              const { message } = error.response.data;
              toast["detail"] = message;
            }

            this.$toast.add(toast);
          });

      StorageService.setControlePaginaFilter("TRANSPORTADORA", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);
    },

    montaJson(){
      const obj = {};
      obj["id"] = this.solicitacao.id;
      obj["estabelecimento"] = this.estabelecimentoSelecionado;
      obj["cliente"] = this.solicitacao.cliente;
      obj["cota"] = this.cotaSelecionada;
      obj["inicioEmbarque"] = Formatacao.formatDateCustom(Formatacao.convertStringToDate(this.inicioEmbarqueSelecionado,'YYYY-MM-DD'),'YYYY-MM-DD');
      obj["tipoProduto"] = this.produtoSelecionado;
      obj["classificacao"] = this.classificacaoSelecionada.name;
      obj["classificadora"] = this.classificadoraSelecionada;
      obj["nomeClassificadora"] = !!this.classificadoraSelecionada ? this.classificadoraSelecionada.nome : null;
      obj["valorFrete"] = this.valorFreteSelecionado;
      obj["destino"] = this.destinoSelecionado;
      obj["terminal"] = this.terminalSelecionado;
      obj["observacao"] = this.observacaoSolicitacaoEmbarque;

      const relPed = [];
      for(let p of this.gestao){
        if(p.checked||p.blocked){
          p.id = p.solicitacaoPedidoId;
          relPed.push(p);
        }
      }
      obj["pedidos"] = relPed;

      let transporta = [];
      const lista = !!this.transportadoras.retorno ? this.transportadoras.retorno : this.transportadoras;
      for (let t = 0;t < lista.length; t++){
        let transp = {};
        transp["id"] = !!lista[t].transportadoraId ? lista[t].id : null;
        transp["solicitacaoEmbarqueId"] = this.solicitacao.id;
        transp["transportadoraId"] = !!lista[t].transportadoraId ? lista[t].transportadoraId : lista[t].id;
        transp["nomeFantasia"] = lista[t].nomeFantasia;
        transporta.push(transp);
      }
      obj["transportadoras"] = transporta;

      return obj;
    },

    carregarEstabelecimentos(event) {
      const codigoOuNomeFantasia = !event || !event.query.trim().length ? undefined : event.query;
      EstabelecimentoService.buscarPorUsuarioAutenticadoComHierarquiaEstabelecimentoQueSejaSilo(codigoOuNomeFantasia)
          .then(({ data }) => {
            if(data) {
              this.estabelecimentosFiltro = data.map(item => {
                item["codigoNomeFantasia"] = item.codigo + " - " + item.nomeFantasia;
                item["nome"] = item.nomeFantasia;
                if(!!this.solicitacao.estabelecimento){
                  if(item.codigo == this.solicitacao.estabelecimento.codigo){
                    this.estabelecimentoSelecionado = item;
                  }
                }
                return item;
              });
            }
          })
          .catch(error => {
            this.estabelecimentosFiltro = [];
          });
    },

    buscarClassificacao(event) {
      if (event==undefined || !event.query.trim().length) {
        this.classificacaoFiltro = [...this.classificacao];
        for(let c of this.classificacaoFiltro){
          if(!!this.solicitacao.classificacao){
            if(c.name == this.solicitacao.classificacao){
              this.classificacaoSelecionada = c;
            }
          }

        }
      } else {
        this.classificacaoFiltro = this.classificacao.filter((item) => {
          return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    },

    buscarClassificadora(event){
      const dados = event==undefined || !event.query.trim().length ? 'a' : event.query;
      ClassificadoraService.getClassificadoras(dados)
          .then(({ data }) => {
            this.classificadorasFiltradas = data.content;
            for(let c of this.classificadorasFiltradas){
              if(!!this.solicitacao.classificadora){
                if(c.id == this.solicitacao.classificadora.id){
                  this.classificadoraSelecionada = c;
                }
              }
            }
          })
          .catch(error => {
            this.classificadorasFiltradas = [];
          });
    },

    pesquisarCliente(filtros) {
      this.mensagem.ativo = false;
      if(!!filtros && !!filtros.codigo && !filtros.size){
        SolicitacaoEmbarqueService.buscarEmpresas(filtros)
            .then(({ data }) => {
              if(data.content.length>0) {
                this.solicitacao.cliente = data.content[0];
              } else {
                this.solicitacao.cliente = {};
                this.mostraMensagem("Cliente Inexistente.");
              }
            })
            .catch(error => {
              this.solicitacao.cliente = {};

              const toast = {
                severity: 'error',
                summary: `Falha ao buscar o cliente.`,
                detail: error,
                life: 7500
              }

              if(!!error && !!error.response && !!error.response.data) {
                const { message } = error.response.data;

                if(!!message) {
                  toast["detail"] = message.replace("produtor", "cliente");
                }
              }

              this.$toast.add(toast);
            });
      } else {
        SolicitacaoEmbarqueService.buscarEmpresas(filtros)
            .then(({data}) => {
              if (data) {
                this.clienteConsulta = data;
              }
            })
            .catch(error => {
              this.clienteConsulta = {}

              const toast = {
                severity: 'error',
                summary: 'Falha ao carregar os clientes.',
                detail: error,
                life: 7500
              }

              if (!!error && !!error.response && !!error.response.data) {
                const {message} = error.response.data;
                toast["detail"] = message;
              }

              this.$toast.add(toast);
            });
      }
    },

    selecionarCliente(codProdutor,produtor) {
      if(!(produtor == undefined || produtor == null)) {
        this.solicitacao.cliente = produtor;
        this.cnpjSelecionado = produtor.cnpj;
        this.carregarPedidos();
      } else {
        this.pesquisarCliente({codigo:codProdutor, situacao:'ATIVO'});
      }
    },

    selecionarClassificacao(){
      if(this.classificacaoSelecionada.name=='CLIENTE'){
        this.classificadoraSelecionada = null;
      }
    },

    buscarDestinos(event){
      const codigoOuDescricao = event==undefined || !event.query.trim().length ? !!this.solicitacao.id ? this.solicitacao.destino.nome : 'a' : event.query;
      MunicipioService.buscarPorDescricao(codigoOuDescricao)
          .then(({ data }) => {
            if(!!data && data.length > 0) {
              this.destinosFiltrados = data.map(municipio => {
                municipio["label"] = `${ municipio.nome } - ${ municipio.uf }`;
                if(!!this.solicitacao.destino){
                  if(municipio.id == this.solicitacao.destino.id){
                    this.destinoSelecionado = municipio;
                  }
                }

                return municipio;
              });
            } else{
              this.destinosFiltrados =[];
            }
          })
          .catch(error => {
            this.destinosFiltrados = [];
          });
    },

    buscarProdutos(event){
      const dados = event==undefined || !event.query.trim().length ? null : event.query;
      TipoProdutoService.getAutoComplete(dados)
          .then(({ data }) => {
            let predefinidos = [];
            data.find(item => {
                predefinidos.push(item);
              if(!!this.solicitacao.tipoProduto){
                if(item.id == this.solicitacao.tipoProduto.id){
                  this.produtoSelecionado = item;
                }
              }

            });
            this.produtosFiltrados = predefinidos;
          })
          .catch(error => {
            this.produtosFiltrados = [];
          });
    },

    buscarTerminais(event) {
        const params = {};
        if(!!this.destinoSelecionado) params["municipioId"] = this.destinoSelecionado.id;
        if(event != undefined && event != null && !!event.query.trim().length) params["cnpjOuNomeFantasia"] = event.query;

        TerminalDesembarqueService.getPorDescricao(params)
            .then(({ data }) => {
              
              this.terminaisFiltrados = data.map(terminal => {
                terminal["label"] = `${ terminal.nomeFantasia } - ${ terminal.cnpj }`;
                if(!!this.solicitacao.terminal){
                  if(terminal.id == this.solicitacao.terminal.id){
                    this.terminalSelecionado = terminal;
                  }
                }

                return terminal;
              });
              
            })
            .catch(error => {
              this.terminaisFiltrados = [];
            });

    },

    mostraMensagem(msg){
      this.mensagem.conteudo = msg;
      this.mensagem.gravidade = 'warn';
      this.mensagem.ativo = msg==null||msg==""?false:true;
    },

    filtrar(){
      this.selecionarNota();
      this.selecionarContratoInicio();
      this.selecionarContratoFim();
      this.atualizar();
    },

    limpar() {
      this.estabelecimentoSelecionado= null;
      this.destinoSelecionado= null;
      this.produtoSelecionado= null;
      this.cotaSelecionada= null;
      this.classificacaoSelecionada= null;
      this.classificadoraSelecionada=  null;
      this.valorFreteSelecionado= null;
      this.terminalSelecionado= null;
      this.inicioEmbarqueSelecionado= null;
      this.cnpjSelecionado= null;
      this.mensagem.ativo = false;
      this.$data.gestao = {};
      this.transportadoraSelecionada = {};
      this.observacaoSolicitacaoEmbarque = null;
    },

    atualizar(){
      const filtro = {};
      filtro["codigoEstabelecimento"] = !this.filter.codigoEstabelecimento ? null : this.filter.codigoEstabelecimento;
      filtro["clienteId"] = !this.filter.clienteId ? null : this.filter.clienteId;
      filtro["nomeCliente"] = !this.filter.nomeCliente ? null : this.filter.nomeCliente;
      filtro["inicioEmbarque"] = !this.filter.inicioEmbarque ? null : Formatacao.formatDateCustom(this.filter.inicioEmbarque, "YYYY-MM-DD");
      filtro["fimEmbarque"] = !this.filter.fimEmbarque ? null : Formatacao.formatDateCustom(this.filter.fimEmbarque, "YYYY-MM-DD");
      filtro["destinoId"] = !this.filter.destinoId ? null : this.filter.destinoId;
      filtro["tipoProdutoId"] = !this.filter.tipoProdutoId ? null : this.filter.tipoProdutoId;
      filtro["nomeTipoProduto"] = !this.filter.nomeTipoProduto ? null : this.filter.nomeTipoProduto;
      filtro["transportadoraId"] = !this.filter.transportadoraId ? null : this.filter.transportadoraId;
      filtro["numeroNotaFiscal"] = !this.filter.numeroNotaFiscal ? null : this.filter.numeroNotaFiscal;
      filtro["ciInicial"] = !this.filter.ciInicial ? null : this.filter.ciInicial;
      filtro["ciFinal"] = !this.filter.ciFinal ? null : this.filter.ciFinal;

      this.$emit("atualizar", filtro);
    },

    formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(data, format){
            return Formatacao.formatDateCustom(data, format);
        }
    }

}
</script>

<style scoped>

    .valor-campo-numerico{
        text-align: right; 
        font-weight: bold;
    }
    .sigla-campo-numerico{
        text-align: left; 
    }
</style>