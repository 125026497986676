import axios from "axios";
import ApiUrl from "../config/ApiUrl";

class TerminalDesembarqueService {
    salvar(dados) {
        const config = {
            header: {
                'content-type': 'application/json'
            }
        }
        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/terminal-desembarque`, dados, config);
    }

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10, sort: 'id,asc'}){
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/terminal-desembarque/buscar-com-paginacao`, { params });
    }

    buscarPorId(idTerminal) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/terminal-desembarque/${ idTerminal }`);
    }
 
    getPorDescricao(params = {}) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/terminal-desembarque/buscar-por-descricao`, { params });
    }
 
    getPorMunicipio(idMunicipio, codigoOuDescricao) {
        var params = {idMunicipio: idMunicipio, descricao: codigoOuDescricao};
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/terminal-desembarque/por-municipio`, { params });
    }

    buscarComEmbarquePendente(codigoEstabelecimento, idDestino) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/terminal-desembarque/terminais-com-embarque-pendente/${ codigoEstabelecimento }/${ idDestino }`);
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/terminal-desembarque/inativar/${ id }`);
    }
    
    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/terminal-desembarque/ativar/${ id }`);
    }

}

export default new TerminalDesembarqueService();