<template>
    <DataTable
        class="p-invoice-datatable-responsive pb-3"
        responsiveLayout="stack"
        :value="data"
        v-model:selection="pedidoSelecionado"
        selectionMode="single"
        @sort="sort($event)">

        <template #empty>
            Nenhum pedido encontrado.
        </template>

        <Column field="pedidoId" header="Nr. Pedido" frozen style="height:1.5rem" sortable/>

        <Column header="Emissão" sortable class="centralizar-titulo-tabela">
            <template #body="{ data }">
                {{ formatDateCustom(data.emissaoPedido, 'DD/MM/YYYY') }}
            </template>
        </Column>

        <Column field="contratoCompraVendaId" header="CI" sortable class="centralizar-titulo-tabela"/>

        <Column field="destino" header="Destino" >
            <template #body="{ data }">
                {{ !data.destino ? 'N' : data.destino.nome }}/{{ !data.destino ? 'A' : data.destino.uf }}
            </template>
        </Column>

        <Column header="Inicio Embarque" sortable class="centralizar-titulo-tabela">
            <template #body="{ data }">
            {{ formatDateCustom(data.inicioEmbarque, 'DD/MM/YYYY') }}
            </template>
        </Column>

        <Column header="Fim Embarque"  sortable class="centralizar-titulo-tabela">
            <template #body="{ data }">
            {{ formatDateCustom(data.fimEmbarque, 'DD/MM/YYYY') }}
            </template>
        </Column>

        <Column field="valorFreteOrcado" header="Frete Orçado" class="alinhar-direita-titulo-tabela" >
            <template #body="{ data }">
                {{ formatDecimal(data.valorFreteOrcado, 2, 6) }}
            </template>
        </Column>

        <Column field="valorFixado" header="Qt. Fixada" style="width:8rem;" v-if="gestao.modalidadePrecificacao!='FIXO'" class="alinhar-direita-titulo-tabela">
            <template #body="{ data }">
                {{ formatDecimal(data.qtdFixada, 2, 6) }} {{gestao.siglaUnidadeMedida}}
            </template>
        </Column>

        <Column field="valorAFixar" header="Qt. à Fixar" style="width:8rem;" v-if="gestao.modalidadePrecificacao!='FIXO'" class="alinhar-direita-titulo-tabela">
            <template #body="{ data }">
                {{ formatDecimal(data.qtdAFixar, 2, 6) }} {{gestao.siglaUnidadeMedida}}
            </template>
        </Column>

        <Column field="quantidade" header="Total Pedido"  frozen alignFrozen="right" class="alinhar-direita-titulo-tabela">
            <template #body="{ data }">
                {{ formatDecimal(data.quantidade, 2, 2) }} {{gestao.siglaUnidadeMedida}}
            </template>
        </Column>

        <Column field="totalEmbarque" header="Total Embarque"  frozen alignFrozen="right" class="alinhar-direita-titulo-tabela">
            <template #body="{ data }">
            {{ formatDecimal(data.totalEmbarque, 2, 2) }} {{gestao.siglaUnidadeMedida}}
            </template>
        </Column>

        <Column field="saldoEmbarque" header="Saldo Embarque"  frozen alignFrozen="right" class="alinhar-direita-titulo-tabela">
            <template #body="{ data }">
            {{ formatDecimal(data.saldoEmbarque, 2, 2) }} {{gestao.siglaUnidadeMedida}}
            </template>
        </Column>

        <Column v-if="modo==undefined && !isOperacao" header="Ações" style="width:7rem" class="centralizar-titulo-tabela" frozen alignFrozen="right">
            <template #body="{ data }">
                <Button type="button" class="p-button-rounded p-button-info mr-2 mb-2" icon="pi pi-ellipsis-v" @click="pedidoAcoesAction($event, data)" aria-haspopup="true" aria-controls="menu_pedido_acoes" />
                <Menu ref="menuPedido" id="menu_pedido_acoes" :model="pedidoAcoes" :popup="true" />
            </template>
        </Column>
        
        <Column v-if="modo=='Nova'" header="Selecione" style="width:3rem" class="centralizar-titulo-tabela" frozen alignFrozen="right">
            <template #body="{ data, index }">
                <Checkbox @click="clickCheck(data,index)" v-model="data[index]" v-if="!gestao[index].pedidoBloqueado" indeterminate binary/>
                <Label title="Pedido Bloqueado" style="color:red;font-size:12pt" v-if="gestao[index].pedidoBloqueado">B</Label>
            </template>
        </Column>

        <Column v-if="modo=='Editar'" header="Selecione" style="width:3rem" class="centralizar-titulo-tabela" frozen alignFrozen="right">
            <template #body="{ data, index }">
                <Checkbox @click="clickCheck(data,index)" v-model="data[index]" v-if="!gestao[index].blocked && !gestao[index].pedidoBloqueado" indeterminate binary />
                <Label title="Pedido Bloqueado" style="color:red;font-size:12pt" v-if="gestao[index].pedidoBloqueado">B</Label>
            </template>
        </Column>

    </DataTable>

    <ModalPedidoForm
        :pedidoId="pedidoId"
        :contratoId="contratoId"
        :visivel="pedidoDialog"
        :acao="acao"
        :codEstabelecimentosOrigem="gestao.codEstabelecimentosOrigem"
        @fechar="pedidoFechar"
    />
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import ModalPedidoForm from '@/components/cadastro/pedido/ModalPedidoForm.vue';

export default {
    components: { ModalPedidoForm },
    emits: ["atualizarGestao"],
    props: {
        gestao: {
            type: Object,
            required: true
        },

        modo: {
            type: String,
            required: false
        }
    },

    watch: {
        gestao:function(gestao) {
            this.carregarDados();
        }
    },
    
    data() {
        return {
            pedidoDialog: false,
            pedidoSelecionado: {},
            pedido: {},
            data: [],

            pedidoId: null,
            contratoId: null,
            acao: "",

            pedidoAcoes: [
                {
                    label:'Editar Pedido',
                    icon:'pi pi-pencil',
                    command: () => this.editarPedido(this.pedidoId)
                }
            ],
          isAdministracao: false,
          isLogistica: false,
          isOperacao: false
        }
    },

    created(){
        this.isAdministracao = this.$store.state.auth.user.isAdministracao;
        this.isLogistica = this.$store.state.auth.user.isLogistica;
        this.isOperacao = this.$store.state.auth.user.isOperacao;

        if(this.modo==undefined){this.carregarDados();}
    },

  methods: {
        carregarDados(){
            this.$data.data = this.gestao;
            this.pedidoSelecionado = this.gestao[0]==undefined ? {} : this.gestao[0];
            this.contratoId = this.gestao.contratoCompraVendaId;

        },

        editarPedido(pedidoId) {
            this.acao = "Edição";
            this.pedidoId = pedidoId;
            this.pedidoDialog = true;
        },

        clickCheck(linha,index){
          this.gestao[index].checked = !this.gestao[index].checked;
        },

        pedidoFechar() {
            this.pedidoDialog = false;
            this.pedido = {};

            this.$emit("atualizar");
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(data, format){
            return Formatacao.formatDateCustom(data, format);
        },

        pedidoAcoesAction(event, data) {
            this.pedidoId = data.pedidoId;
            this.$refs.menuPedido.toggle(event);
        }
    }
}
</script>

<style scoped>
    .borda-personalizada {
        border: 1px solid #CCC;
        border-radius: 3px;
        padding: 1rem;
    }
</style>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

    .alinhar-direita-titulo-tabela {
        text-align: right !important;
    }

    .alinhar-direita-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>