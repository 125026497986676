<template>
    <Dialog v-model:visible="visivel" :style="{width: '50%', margin: '10%'}" :modal="true" @hide="fechar()" @show="init()">
        <template #header>
            <div class="flex align-items-center">

                <img src="layout/images/truck-timer.png" alt="" style="font-size: 1.4rem" class="mr-2">
                <h3>
                    Ajuste de Frete
                </h3>
            </div> 
        </template>

        <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

        <Fieldset legend="" class="card">
            <div class="grid p-fluid formgrid align-items-center">

                <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                    <div class="field">
                        <label >
                            <strong>SE</strong>
                        </label>
                        <InputText v-model="solicitacaoEmbarque.id" disabled/>
                    </div>
                </div>

                <div class="col-12 sm:col-10 md:col-10 lg:col-10 xl:col-10">
                    <div class="field">
                        <label >
                            <strong>Origem</strong>
                        </label>
                        <div class="p-inputgroup">
                            <InputText class="w-full" v-model="codigoNomeEstabelecimento" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                        </div>
                    </div>
                </div>

                <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                  <div class="field">
                    <label >
                      <strong>Cliente</strong>
                    </label>
                    <InputText v-model="solicitacaoEmbarque.cliente.id" disabled/>
                  </div>
                </div>

                <div class="col-12 sm:col-10 md:col-10 lg:col-10 xl:col-10">
                  <div class="field">
                    <label >
                      <strong></strong>
                    </label>
                    <div class="p-inputgroup">
                      <InputText class="w-full" v-model="solicitacaoEmbarque.cliente.nome" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                    </div>
                  </div>
                </div>

                <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                  <div class="field">
                    <label >
                      <strong>Destino</strong>
                    </label>
                    <InputText v-model="this.destino" disabled/>
                  </div>
                </div>

                <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                  <div class="field">
                    <label >
                      <strong>Produto</strong>
                    </label>
                    <div class="p-inputgroup">
                      <InputText class="w-full" v-model="solicitacaoEmbarque.tipoProduto.nome" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999"/>
                    </div>
                  </div>
                </div>

                <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                    <div class="field">
                        <label >
                            <strong>Valor Anterior ( R$ / TON)</strong>
                        </label>
                        <InputNumber class="w-full" v-model="solicitacaoEmbarque.valorFrete" disabled mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999" />
                    </div>
                </div>

                <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                    <div class="field">
                        <label >
                            <strong>Novo Valor ( R$ / TON) <span style="color:red;">&nbsp;*</span></strong>
                        </label>
                        <InputNumber
                            :class="{ 'w-full, p-invalid': campoInvalido.valorFrete }"
                            v-model="valorFrete" mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999" />
                    </div>
                </div>

                <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                    <div class="field">
                        <label >
                            <strong>Justificativa</strong>
                        </label>
                        <AutoComplete
                            :dropdown="true"
                            class="w-full"
                            field="descricao"
                            placeholder="Selecione..."
                            v-model="justificativaSelecionada"
                            :forceSelection="true"
                            :suggestions="justificativasFiltradas"
                            :class="{ 'w-full, p-invalid': campoInvalido.justificativa }"
                            @complete="buscarJustificativas($event)"
                        />

                    </div>
                </div>

            </div>

        </Fieldset>

        <template #footer>
            <div class="flex justify-content-center">

                <Button
                    icon="pi pi-save"
                    label="Salvar"
                    class="p-button-info"
                    @click="salvar()"
                ></Button>

                <Button
                    icon="pi pi-times"
                    label="Cancelar"
                    class="p-button-danger ml-2"
                    @click="fechar()"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
    import Formatacao from '../../../utilities/Formatacao';
    import SolicitacaoEmbarqueService from "@/service/SolicitacaoEmbarqueService";
    import AjusteFreteService from "@/service/AjusteFreteService";

    export default {
        name: "ModalManutencaoValorFrete",
        components: {  },
        emits: ["fechar"],
        props: {
            visivel: {
                type: Boolean,
                required: true
            },
            solicitacaoEmbarque: {
                type: Object,
                required: true
            },
            solicitacaoEmbarqueTransportadora: {
                type: Object,
                required: true
            },
            siglaUnidadeMedida: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                mensagem: { ativo: false, gravidade: 'info', conteudo: '' },
                codigoNomeEstabelecimento: null,
                destino: null,
                valorFreteAnterior: null,
                justificativasFiltradas: [],
                justificativaSelecionada: null,

                classificacao: null,
                campoInvalido: {},
                valorFrete: null,
                observacao: null
            }
        },

      computed: {
			currentUser() {
				return this.$store.state.auth.user;
			},
		},

        methods: {

            init(){
                this.codigoNomeEstabelecimento = this.solicitacaoEmbarque.estabelecimento.codigo +" - "+ this.solicitacaoEmbarque.estabelecimento.nome;
                this.destino = this.solicitacaoEmbarque.destino.nome + "/" + this.solicitacaoEmbarque.destino.uf;

                this.campoInvalido.valorFrete = false;
                this.campoInvalido.justificativa = false;

            },

            buscarJustificativas(event){
              AjusteFreteService.buscarJustificativas()
                  .then(({ data }) => {
                    this.justificativasFiltradas = data;
                  })
                  .catch(error => {
                    this.justificativasFiltradas = [];
                  });
            },

            limpar() {
                this.mensagem = { ativo: false };
                this.valorFrete = null;
                this.justificativaSelecionada = null;
            },

            salvar() {  
                if(!this.validarCampos()) return;

                this.solicitacaoEmbarque.valorFrete = this.valorFrete;

                const dados = {
                  solicitacaoEmbarqueId: this.solicitacaoEmbarque.id,
                  valorFrete: this.valorFrete,
                  justificativa: this.justificativaSelecionada.codigo,
                  usuario: this.currentUser.username
                }

                AjusteFreteService.salvar(dados)
                    .then(({data})=> {

                      this.$toast.add({
                        severity:'success',
                        summary: 'Sucesso.',
                        detail:`Ajuste de Frete salvo com sucesso.`,
                        life: 7500

                      });
                    })
                    .catch(error => {
                      const toast = {
                        severity: 'error',
                        summary: 'Não foi possível salvar o ajuste de frete.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!",
                        life: 7500
                      }

                      if(!!error && !!error.response && !!error.response.data) {
                        const { message, exception } = error.response.data;

                        if(exception === "Pedido obrigatório") {
                          toast["detail"] = exception;
                        }
                        else {
                          toast["detail"] = message;
                        }
                      }

                      this.$toast.add(toast);
                    });


                this.$emit("fechar",true);
                this.limpar();
            },

            validarCampos() {
                let campos = [];

                if(this.valorFrete == null || this.valorFrete == 0) {
                    campos.push("Novo Valor");
                    this.campoInvalido.valorFrete = true;
                }
                else {
                    this.campoInvalido.valorFrete = false;
                }

                if(this.justificativaSelecionada == null) {
                  campos.push("Justificativa");
                  this.campoInvalido.justificativa = true;
                }
                else {
                  this.campoInvalido.justificativa = false;
                }

                if(campos.length > 0) {
                    let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

                    if(campos.length > 1) {
                        mensagem = "Por favor, preencher os campos: ";
                        mensagem += campos.join(", ");

                        const indice = mensagem.lastIndexOf(", ");
                        mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                    }

                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Não foi possível realizar a alteração.',
                        detail: mensagem, 
                        life: 15000
                    });
                }

                return campos.length === 0;
            },

            fechar() {
                this.$emit("fechar");
                this.limpar();
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },

            formatDateCustom(data, format){
                return Formatacao.formatDateCustom(data, format);
            }
        }
    }
</script>
