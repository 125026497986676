<template>
  <DataTable editMode="row" responsiveLayout="stack" :value="dados"
             selectionMode="single" showGridlines >

    <template #empty>
      Nenhum registro encontrado.
    </template>

    <template #loading>
      Carregando... Por favor, aguarde.
    </template>

    <Column field="pedidoId" header="Pedido" style="width: 4rem;"/>
    <Column field="contratoInternoId" header="CI" style="width: 4rem;"/>
    <Column field="solicitacaoEmbarquePedidoId" header="SE" style="width: 4rem;"/>
    <Column field="codNaturezaOperacao" header="Natureza" style="width: 4rem;">
      <template #body="{ data }">
        <span :title="data.descNaturezaOperacao"> {{ data.codNaturezaOperacao }} </span>
      </template>
    </Column>
    <Column field="codigoItemNota" header="Item" style="width: 4rem;">
      <template #body="{ data }">
        {{ data.codigoItemNota }}
      </template>
    </Column>
    <Column field="quantidade" header="Quantidade" style="width: 3rem;">
      <template #body="{ data, field }">
        {{ formatDecimal(data[field],2,2) }}
      </template>
    </Column>
    <Column field="valorUnitario" header="Valor Unitário" style="width: 8rem;">
      <template #body="{ data, field }">
        {{ formatDecimal(data[field],2,6) }}
      </template>
    </Column>
    <Column field="valorTotal" header="Valor Total" style="width: 7rem;">
      <template #body="{ data, field }">
        {{ formatDecimal(data[field],2,2) }}
      </template>
    </Column>
    <Column field="observacaoNota" header="Observação NF"/>
  </DataTable>

</template>
<script>
import Formatacao from "@/utilities/Formatacao";

export default {
  props: {
    dados: { type: Object, required: true },
    campoInvalido: { type: Object, required: true }
  },

  data() {
    return {
      itemSelecionado: {}

    }
  },

  created() {
  },

  updated() {
  },

  methods: {

    formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
      if (value!=null) return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
    },

    formatDateCustom(date, format) {
      return Formatacao.formatDateCustom(date, format);
    },

    formatarTerminal(obs){
      return obs.replace("*****",this.faturamento.terminal.nomeFantasia + " - " + this.faturamento.terminal.cnpj);
    }
  }
}

</script>
